import {
  Toolbar,
  IconButton,
  InputBase,
  Tooltip,
  useTheme,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import React, {
  useCallback,
  useEffect,
  useContext,
  useState,
  useMemo,
} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { connect, useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SortIcon from "@mui/icons-material/Sort";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Neo4jContext, Neo4jContextState } from "use-neo4j/dist/neo4j.context";
import {
  addPageWithDetailsThunk,
  changeActiveGenieListThunk,
  deleteGenieListThunk,
  getAllGenieListsToUserName,
  loadDatabaseListFromNeo4jThunk,
  removePageThunk,
  searchGeneByTextThunk,
} from "../DashboardThunks";
import { updateGlobalParametersThunk } from "../../settings/SettingsThunks";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { updateDatabaseThunk } from "../../application/ApplicationThunks";
import GeneListComponent from "../../component/genelistcomponent";
import Switch from "@mui/material/Switch";
import Avatar from "@mui/material/Avatar";
import { updateStandAlone } from "../../application/ApplicationActions";
import { addReportThunk, createNotificationThunk } from "../../page/PageThunks";
import { getExperimentData } from "../../api/heatmap";
import { CARD_INITIAL_STATE } from "../../card/CardReducer";
import { GENE_ID_SEARCH_PARAM } from "../../constants";

export const NeoDashboardHeaderTitleBar = ({
  dashboardTitle,
  open,
  setDashboardTitle,
  editable,
  standalone,
  handleDrawerOpen,
  relationshipHelpModalOpen,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [databases, setDatabases] = useState([]);
  const [searchedGenies, setSearchedGenies] = useState([]);
  const openMenuBar = Boolean(anchorEl);
  const { driver } = useContext<Neo4jContextState>(Neo4jContext);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const dashboard = useSelector((state: any) => state?.dashboard);
  const applicationState = useSelector((state: any) => state?.application);
  const connectionState = useSelector(
    (state: any) => state?.application?.connection
  );
  const [activeDb, setActiveDb] = useState("");

  const urlParams = new URLSearchParams(document.location.search);
  const availableGene = urlParams.get(GENE_ID_SEARCH_PARAM);

  const activeGenieList = dashboard?.addedGenieLists.find(
    (item) => item?.active
  );

  useEffect(() => {
    dispatch(
      loadDatabaseListFromNeo4jThunk((data) => {
        setDatabases(data);
        if (data.includes(connectionState?.database)) {
          setActiveDb(connectionState?.database);
          dispatch(
            getAllGenieListsToUserName(
              connectionState?.username,
              connectionState?.database
            )
          );
        } else {
          setActiveDb(data[0]);
          dispatch(
            getAllGenieListsToUserName(connectionState?.username, data[0])
          );
        }
        // const availableIndex = data.findIndex(
        //   (item) => item === connectionState?.database
        // );
        // if (availableIndex === -1) {
        //   // No db found, show an error
        //   dispatch(createNotificationThunk("Database not found", "error"));
        // }
      })
    );
  }, []);

  const handleDBChange = (event) => {
    setActiveDb(event?.target?.value);
    if (event?.target?.value) {
      dispatch(updateDatabaseThunk(event.target.value));
      dispatch(
        getAllGenieListsToUserName(
          connectionState?.username,
          event.target.value
        )
      );
    }
  };

  const [dashboardTitleText, setDashboardTitleText] =
    React.useState(dashboardTitle);
  const debouncedDashboardTitleUpdate = useCallback(
    debounce(setDashboardTitle, 250),
    []
  );

  const handleSearchSelectGenie = (values) => {
    const geneList = values.map((item) => item.name);
    if (geneList.length > 0) {
      const newGeneList = activeGenieList?.genelist
        ?.split(",")
        .concat(geneList);

      dispatch(
        updateGlobalParametersThunk({
          neodash_genelist_name: newGeneList,
        })
      );
    } else {
      const newGeneList = activeGenieList?.genelist?.split(",");
      dispatch(
        updateGlobalParametersThunk({
          neodash_genelist_name: newGeneList,
        })
      );
    }
  };

  useEffect(() => {
    // Reset text to the dashboard state when the page gets reorganized.
    if (dashboardTitle !== dashboardTitleText) {
      setDashboardTitleText(dashboardTitle);
    }
  }, [dashboardTitle]);

  const searchGenieByText = (text) => {
    if (text.length > 0) {
      dispatch(
        searchGeneByTextThunk(
          driver,
          applicationState?.connection?.database,
          text,
          (values) => {
            setSearchedGenies(values);
          }
        )
      );
    } else {
      setSearchedGenies([]);
    }
  };

  const addGenieDetailsPageAndReport = (gene) => {
    // Need to check whether existing page is available or not
    const existingPage = dashboard?.pages?.find(
      (item) => item?.title === "Gene Details"
    );
    if (existingPage) {
      dispatch(removePageThunk(dashboard?.pages?.indexOf(existingPage)));
    }
    dispatch(addPageWithDetailsThunk({ title: "Gene Details", reports: [] }));
    dispatch(
      addReportThunk(0, 0, 8, 5, {
        ...CARD_INITIAL_STATE,
        title: gene?.name,
        query: "MATCH(n:Gene) WHERE n.name = '" + gene?.name + "' RETURN n",
        type: "geneDetails",
        settings: {
          ...CARD_INITIAL_STATE.settings,
          selectedGene: gene?.name,
          basicInfoQuery:
            "MATCH(n:Gene) WHERE n.name = '" + gene?.name + "' RETURN n",
          sequenceQueryGenomic:
            "match (n:Sequence) where n.gene_id='" +
            gene?.name +
            "' return n.genomic_sequence",
          sequenceQueryCDs:
            "match (n:Sequence) where n.gene_id='" +
            gene?.name +
            "' return n.cds_sequence",
          sequenceQueryProtein:
            "match (n:Sequence) where n.gene_id='" +
            gene?.name +
            "' return n.protein_sequence",
          sequenceQueryTranscript:
            "match (n:Sequence) where n.gene_id='" +
            gene?.name +
            "' return n.transcript_sequence",

          functionalOntologyQuery:
            "MATCH p=(n:Gene)-[e]->(m:BiologicalProcess) WHERE n.name='" +
            gene?.name +
            "'  RETURN m.name as Gene,m.description as description, COUNT(p) as `No of connections` ORDER BY `No of connections` DESC",
          functionalPfamQuery:
            "match (n:Gene)-[]->(m:PFAM)  WHERE n.name='" +
            gene?.name +
            "' return m.name as PFAM ,m.description  as Description",
          familyQuery:
            "MATCH (n:GeneFamily) WITH n WHERE n.gf_genes CONTAINS '" +
            gene?.name +
            "' or n.gf_genes  ENDS WITH '" +
            gene?.name +
            "' MATCH (m:GeneFamily) where m.gf_id=n.gf_id return m",
        },
      })
    );
    dispatch(
      updateGlobalParametersThunk({
        plantgenie_selected_gene: gene?.name,
      })
    );
  };

  useEffect(() => {
    if (availableGene && availableGene !== undefined) {
      addGenieDetailsPageAndReport({ name: availableGene });
    }
  }, [availableGene]);

  const updateGeneIdUrl = (gene) => {
    urlParams.append(GENE_ID_SEARCH_PARAM, gene?.name);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParams}`
    );
    addGenieDetailsPageAndReport(gene);
  };

  const content = (
    <Toolbar
      key={1}
      style={{
        paddingRight: 24,
        minHeight: "50px",
        zIndex: 1201,
        backgroundColor: theme.palette.primary.main,
      }}
    >
      {!standalone ? (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          style={
            open
              ? {
                  display: "none",
                }
              : {
                  marginRight: 0,
                  marginLeft: -29,
                  paddingRight: 28,
                }
          }
        >
          <MenuIcon />
        </IconButton>
      ) : (
        <></>
      )}
      <img
        src="plogo.png"
        style={{
          color: "#ffffffdd",
          width: "28px",
          zIndex: 5,
          marginLeft: -24,
        }}
      ></img>
      <InputBase
        id="left-aligned"
        style={{
          textAlign: "left",
          fontSize: "20px",
          flexGrow: 1,
          color: "white",
          marginLeft: 2,
        }}
        placeholder="Dashboard Name..."
        fullWidth
        maxRows={4}
        value={dashboardTitleText}
        onChange={(event) => {
          if (editable) {
            setDashboardTitleText(event.target.value);
            debouncedDashboardTitleUpdate(event.target.value);
          }
        }}
      />

      <Autocomplete
        disablePortal
        id="autocomplete-search"
        options={searchedGenies}
        onChange={(event, value, reason) => {
          // Open new page and show the selected gene details
          if (value) {
            updateGeneIdUrl(value);
          }
        }}
        sx={{
          minWidth: 600,
          maxWidth: 1000,
          mr: 2,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              color: "#fff",
              "&.Mui-focused": {
                color: "#fff",
              },
            }}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            label="Type in gene ids,description or synonynms"
            onChange={(event) => {
              searchGenieByText(event.target.value);
            }}
          />
        )}
        size="small"
      />

      <Avatar sx={{ mr: 1, bgcolor: "green" }} alt="0">
        {activeGenieList?.genes ? activeGenieList?.genes?.length : 0}
      </Avatar>

      <Tooltip title="" placement="left" aria-label="host">
        <IconButton
          style={{
            background: "#ffffff22",
            padding: "1px",
            marginRight: "1px",
          }}
          onClick={() => {
            // if (!standalone) {

            relationshipHelpModalOpen();
            //}
          }}
        >
          <InfoOutlinedIcon
            style={{
              padding: 6,
              color: "#ffffffdd",
              width: "36px",
              height: "36px",
              fontSize: "1.3rem",
              zIndex: 5,
            }}
            fontSize="small"
          ></InfoOutlinedIcon>
        </IconButton>
      </Tooltip>
      <Box sx={{ minWidth: 120, ml: 2 }}>
        <FormControl fullWidth>
          <InputLabel
            id="demo-simple-select-label"
            size="small"
            sx={{
              color: "#fff",
              "&.Mui-focused": {
                color: "#fff",
              },
            }}
          >
            Database
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={activeDb}
            size="small"
            label="Database"
            sx={{
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              ".MuiSvgIcon-root ": {
                fill: "white !important",
              },
            }}
            onChange={handleDBChange}
          >
            {databases.map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Toolbar>
  );
  return content;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NeoDashboardHeaderTitleBar);
