import Gradient from "javascript-color-gradient";

type Expression = {
    color: string;
    value: number;
}

interface DifferentialColorProps {
    currentValue: number;
    minExpressionObject: Expression;
    midExpressionObject: Expression;
    maxExpressionObject: Expression;
    defaultColor?: string;
}

export const getDifferentialColor = ({
    currentValue,
    minExpressionObject,
    midExpressionObject,
    maxExpressionObject,
    defaultColor = '#a0a0a0'
}: DifferentialColorProps) => {
    const numberRounded = Math.round(Math.abs(currentValue));

    if (numberRounded === midExpressionObject.value) {
        return midExpressionObject.color;
    }

    if (currentValue < midExpressionObject.value) {
        return new Gradient()
            .setColorGradient(midExpressionObject.color, minExpressionObject.color)
            .setMidpoint(minExpressionObject.value)
            .getColor(numberRounded);
    }

    if (currentValue > midExpressionObject.value) {
        return new Gradient()
            .setColorGradient(midExpressionObject.color, maxExpressionObject.color)
            .setMidpoint(maxExpressionObject.value)
            .getColor(numberRounded);
    }

    return defaultColor;
};