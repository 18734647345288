import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import PlayArrow from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, withStyles } from "@mui/styles";
import { connect } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
/**
 * A modal to save a dashboard as a JSON text string.
 * The button to open the modal is intended to use in a drawer at the side of the page.
 */

const styles = {};

export const NeoLoadSharedDashboardModal = ({
  shareDetails,
  onResetShareDetails,
  onConfirmLoadSharedDashboard,
}) => {
  const handleClose = () => {
    onResetShareDetails();
  };

  return (
    <div>
      <Dialog
        maxWidth={"lg"}
        open={shareDetails !== undefined}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <DashboardIcon
            style={{
              height: "30px",
              paddingTop: "4px",
              marginBottom: "-8px",
              marginRight: "5px",
              paddingBottom: "5px",
            }}
          />{" "}
          Loading Dashboard
          <IconButton
            onClick={handleClose}
            style={{ padding: "3px", float: "right" }}
          >
            <Badge badgeContent={""}>
              <CloseIcon />
            </Badge>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {shareDetails !== undefined ? (
              <>
                You are loading a Neo4j dashboard.
                <br />
                {shareDetails && shareDetails.url ? (
                  <>
                    You will be connected to{" "}
                    <b>{shareDetails && shareDetails.url}</b>.
                  </>
                ) : (
                  <>You will still need to specify a connection manually.</>
                )}
                <br /> <br />
                This will override your current dashboard (if any). Continue?
              </>
            ) : (
              <>
                <br />
                <br />
                <br />
              </>
            )}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button
              component="label"
              onClick={(e) => {
                onConfirmLoadSharedDashboard();
              }}
              style={{
                marginTop: "20px",
                float: "right",
              }}
              variant="contained"
              endIcon={<PlayArrow />}
              size="medium"
            >
              Continue
            </Button>
            <Button
              component="label"
              onClick={handleClose}
              style={{
                float: "right",
                marginTop: "20px",
                marginRight: "10px",
              }}
              variant="contained"
              size="medium"
            >
              Cancel
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NeoLoadSharedDashboardModal)
);
