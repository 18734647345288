import React, { useCallback, useEffect } from "react";
import { ChartProps } from "./Chart";
import {
  CircularProgress,
  debounce,
  Button,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import NeoField from "../component/field/Field";

/**
 * Renders Neo4j records as their JSON representation.
 */
const NeoMultipleParameterSelectionChart = (props: ChartProps) => {
  useEffect(() => {
    debouncedQueryCallback &&
      debouncedQueryCallback(query, { input: value }, setExtraRecords);
  }, [value, query]);

  const records = props.records;
  const query = records[0]["input"] ? records[0]["input"] : undefined;

  const parameter =
    props.settings && props.settings["parameterName"]
      ? props.settings["parameterName"]
      : undefined;

  const type =
    props.settings && props.settings["type"]
      ? props.settings["type"]
      : undefined;
  const suggestionsUpdateTimeout =
    props.settings && props.settings["suggestionsUpdateTimeout"]
      ? props.settings["suggestionsUpdateTimeout"]
      : 250;
  const setParameterTimeout =
    props.settings && props.settings["setParameterTimeout"]
      ? props.settings["setParameterTimeout"]
      : 1000;
  const isMultiple =
    props.settings && props.settings["setParameterMultiple"]
      ? props.settings["setParameterMultiple"]
      : false;

  const debouncedQueryCallback = useCallback(
    debounce(props.queryCallback, suggestionsUpdateTimeout),
    []
  );

  const debouncedSetGlobalParameter = useCallback(
    debounce(props.setGlobalParameter, setParameterTimeout),
    []
  );

  const currentValue =
    props.getGlobalParameter && props.getGlobalParameter(parameter)
      ? props.getGlobalParameter(parameter)
      : "";
  const [extraRecords, setExtraRecords] = React.useState([]);
  const [inputText, setInputText] = React.useState(currentValue);
  const [value, setValue] = React.useState(currentValue);

  // In case the components gets (re)loaded with a different/non-existing selected parameter, set the text to the current global parameter value.
  if (query && value != currentValue && currentValue != inputText) {
    setValue(currentValue);
    setInputText(currentValue);
    setExtraRecords([]);
  }
  if (!query || query.trim().length == 0) {
    return (
      <p style={{ margin: "15px" }}>
        No selection specified. Open up the report settings and choose a node
        label and property.
      </p>
    );
  }

  const label =
    props.settings && props.settings["entityType"]
      ? props.settings["entityType"]
      : "";
  const property =
    props.settings && props.settings["propertyType"]
      ? props.settings["propertyType"]
      : "";
  const settings = props.settings ? props.settings : {};
  const helperText = settings.helperText;
  const clearParameterOnFieldClear = settings.clearParameterOnFieldClear;
  const updateQuery = () => {
    debouncedSetGlobalParameter(
      parameter,
      value
        .toString()
        .replace(/\n/g, ",")
        .replace(/\s+/, "")
        .replace(/,{2,}/g, ",")
        .split(",")
    );
  };

  return (
    <div>
      <>
        <p>You can input a comma separated list here</p>
        <NeoField
          key={"freetext"}
          className="multiple-select-parameter"
          label={helperText ? helperText : label + " " + property}
          defaultValue={""}
          value={value}
          multi={true}
          variant="outlined"
          placeholder={"Enter text here..."}
          style={{
            maxWidth: "calc(100% - 30px)",
            marginLeft: "15px",
            marginTop: "5px",
          }}
          onChange={(newValue) => {
            // TODO: i want this to be a proper wait instead of triggering on the first character.
            if (newValue == null && clearParameterOnFieldClear) {
              setValue("");
              debouncedSetGlobalParameter(parameter, undefined);
            } else {
              debouncedSetGlobalParameter(
                parameter,
                newValue.trim().split(",")
              );
              setValue(newValue.trim().replace(/\n/g, ",").split(","));
            }
          }}
          clearValueFunc={() => {
            setValue("");
            debouncedSetGlobalParameter(parameter, undefined);
          }}
        />
        <Button
          variant="contained"
          style={{ display: "block", margin: "20px" }}
          onClick={() => updateQuery()}
        >
          Update
        </Button>
      </>
    </div>
  );
};
/**
 * POTRA2N1c1,POTRA2N1c10,POTRA2N1c11,
 *
 */

export default NeoMultipleParameterSelectionChart;

// Potra2n10c20318,Potra2n10c21792
