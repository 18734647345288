import React from "react";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { FullscreenExit } from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FlipIcon from "@mui/icons-material/Flip";
import { Tooltip } from "@mui/material";

const NeoCardSettingsHeader = ({
  onRemovePressed,
  onToggleCardSettings,
  onToggleCardExpand,
  expanded,
  fullscreenEnabled,
  onReportHelpButtonPressed,
  onClonePressed,
  onToggleCardSettingsWithoutSaving,
}) => {
  const maximizeButton = (
    <IconButton aria-label="maximize" onClick={onToggleCardExpand}>
      <FullscreenIcon />
    </IconButton>
  );

  const unMaximizeButton = (
    <IconButton aria-label="un-maximize" onClick={onToggleCardExpand}>
      <FullscreenExit />
    </IconButton>
  );

  return (
    <CardHeader
      avatar={
        <div style={{ marginTop: "-8px" }}>
          <DragIndicatorIcon
            className="drag-handle"
            style={{
              color: "grey",
              cursor: "pointer",
              marginTop: "8px",
              marginLeft: "-7px",
              marginRight: "10px",
            }}
          ></DragIndicatorIcon>
          <Tooltip title="Help" aria-label="help">
            <IconButton
              size="medium"
              style={{ marginTop: "-16px", padding: "8px" }}
              aria-label="help"
              onClick={onReportHelpButtonPressed}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton
              size="medium"
              style={{ marginTop: "-16px", padding: "8px", color: "red" }}
              aria-label="remove"
              onClick={onRemovePressed}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Clone" aria-label="clone">
            <IconButton
              size="medium"
              style={{ marginTop: "-16px", padding: "8px", color: "green" }}
              aria-label="clone"
              onClick={onClonePressed}
            >
              <FileCopyOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      action={
        <>
          {fullscreenEnabled ? (
            expanded ? (
              unMaximizeButton
            ) : (
              maximizeButton
            )
          ) : (
            <></>
          )}
          <Tooltip title="Close" aria-label="close">
            <IconButton
              size="medium"
              aria-label="close"
              onClick={onToggleCardSettingsWithoutSaving}
            >
              <FlipIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save" aria-label="save">
            <IconButton
              aria-label="save"
              onClick={(e) => {
                e.preventDefault();
                onToggleCardSettings();
              }}
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </>
      }
      title=""
      subheader=""
    />
  );
};

export default NeoCardSettingsHeader;
