import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import PlayArrow from "@mui/icons-material/PlayArrow";
import {
  FormControlLabel,
  MenuItem,
  Switch,
  Stack,
  Typography,
} from "@mui/material";
import { SSOLoginButton } from "../component/sso/SSOLoginButton";

/**
 * Configures setting the current Neo4j database connection for the dashboard.
 */
export default function NeoConnectionModal({
  open,
  standalone,
  standaloneSettings,
  ssoSettings,
  connection,
  dismissable = false,
  createConnection,
  onConnectionModalClose,
  onSSOAttempt,
}) {
  const protocols = [
    "neo4j",
    "neo4j+s",
    "neo4j+ssc",
    "bolt",
    "bolt+s",
    "bolt+ssc",
  ];
  const [ssoVisible, setSsoVisible] = React.useState(ssoSettings["ssoEnabled"]);
  const [protocol, setProtocol] = React.useState(connection.protocol);
  const [url, setUrl] = React.useState(connection.url);
  const [port, setPort] = React.useState(connection.port);
  const [username, setUsername] = React.useState(connection.username);
  const [password, setPassword] = React.useState(connection.password);
  const [database, setDatabase] = React.useState(connection.database);

  // Make sure local vars are updated on external connection updates.
  useEffect(() => {
    setProtocol(connection.protocol);
    setUrl(connection.url);
    setUsername(connection.username);
    setPassword(connection.password);
    setPort(connection.port);
    setDatabase(connection.database);
  }, [JSON.stringify(connection)]);

  useEffect(() => {
    setSsoVisible(ssoSettings["ssoEnabled"]);
  }, [JSON.stringify(ssoSettings)]);

  const discoveryAPIUrl = ssoSettings && ssoSettings.ssoDiscoveryUrl;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          dismissable ? onConnectionModalClose() : null;
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" sx={{ pt: 5 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" fontWeight={800}>
              {standalone ? "Connect to Dashboard" : "Connect to Neo4j"}
            </Typography>
            <IconButton style={{ padding: "3px", float: "right" }}>
              <Badge badgeContent={""}>
                <img
                  style={{ width: "36px", height: "36px" }}
                  src="neo4j-icon-color.png"
                />
              </Badge>
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="database"
            value={database}
            disabled={standalone}
            style={{ marginTop: 20 }}
            onChange={(e) => setDatabase(e.target.value)}
            label="Database (optional)"
            placeholder="neo4j"
            type="text"
            fullWidth
            size="small"
          />

          <TextField
            autoFocus
            margin="dense"
            id="dbusername"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            label="Username"
            style={{ marginTop: 20 }}
            placeholder="neo4j"
            type="text"
            fullWidth
            size="small"
          />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              onConnectionModalClose();
              createConnection(
                protocol,
                url,
                port,
                database,
                username,
                password
              );
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="dbpassword"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginTop: 20 }}
              label="Password"
              type="password"
              fullWidth
              size="small"
            />

            <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
              <Button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  onConnectionModalClose();
                  createConnection(
                    protocol,
                    url,
                    port,
                    database,
                    username,
                    password
                  );
                }}
                variant="contained"
                size="large"
                sx={{ color: "#fff" }}
                endIcon={<PlayArrow />}
              >
                Connect
              </Button>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions style={{ background: "#555" }}>
          <DialogContent>
            {standalone ? (
              <DialogContentText style={{ color: "lightgrey" }}>
                {standaloneSettings["standaloneDashboardURL"] === "" ? (
                  <>
                    Sign in to continue. You will be connected to Neo4j, and
                    load a dashboard called{" "}
                    <b>{standaloneSettings["standaloneDashboardName"]}</b>.
                  </>
                ) : (
                  <>
                    Sign in to continue. You will be connected to Neo4j, and
                    load a dashboard.
                  </>
                )}
              </DialogContentText>
            ) : (
              <DialogContentText style={{ color: "lightgrey" }}>
                Enter your Neo4j database credentials to start. Don't have a
                Neo4j database yet? Create your own in{" "}
                <a
                  style={{ color: "white" }}
                  href="https://neo4j.com/download/"
                >
                  Neo4j Desktop
                </a>
                , or try the{" "}
                <a style={{ color: "white" }} href="https://console.neo4j.io/">
                  Neo4j Aura
                </a>{" "}
                free tier.
              </DialogContentText>
            )}
          </DialogContent>
        </DialogActions>
      </Dialog>
    </div>
  );
}
