import axios from "axios";

const baseURL = "https://api.plantgenie.org/plugins";

const axiosClient = axios.create({ baseURL: baseURL });

export async function getConfig() {
  return axiosClient.get("/config.json");
}

export async function getSequence(data) {
  return axiosClient.post(
    "/gene/services/sequence.php",
    `id=${data.geneId}&species=potra&genie_select_species=beta_plantgenie_potra_v22`
  );
}

export async function getBlast(data) {
  return axiosClient.post(
    "/blast/services/blast.php",
    "seqid=" +
      data.geneId +
      "&=Genomic Sequence&=Transcript Sequence&=CDS Sequence&=Protein Sequence&database_type=" +
      data.database +
      "&query_sequence_text=>" +
      data.geneId +
      ".1\n" +
      data.sequence +
      "&advanced_parameters_scoring_matrix=" +
      data.scoringMatrix +
      "&advanced_parameters_low_complexity_regions=" +
      data.lowComplexity +
      "&advanced_parameters_filtering_lower_case_letters=" +
      data.lowerUpperCase +
      "&advanced_parameters_e_value_cutoff=" +
      data.eValue +
      "&advanced_parameters_e_value_cutoff_BLAST_options_ungapped=" +
      data.ungapped +
      "&advanced_parameters_options_megablast=" +
      data.megaBlast +
      "&advanced_parameters_genetic_code=" +
      data.queryGeneticCode +
      "&advanced_parameters_DB_genetic_code=" +
      data.dbGeneticCode +
      "&advanced_parameters_number_of_results=" +
      data.noOfResults +
      "&=Run search&selected_datasets=" +
      data.databaseIndex
  );
}

export async function validateBlast(data) {
  return axiosClient.post(
    "/blast/services/validate_blast.php",
    "uuid=" + data.uuid + "&program=" + data.program
  );
}

export async function getBlastDatabases(species) {
  return axiosClient.get("/blast/services/getdb.php?species=" + species);
}
