import client from "./client";
import { Record, Integer } from "neo4j-driver";

export const queryNeo4j = async (
  database: string,
  query: string,
  parameters: any
) => {
  try {
    const response = await client.post("/api/query", {
      database,
      query,
      parameters,
    });
    const records: Record[] = [];
    response.data.forEach((element) => {
      const record = new Record(
        element.keys,
        element._fields,
        element._fieldLookup
      );
      records.push(record);
    });
    return Promise.resolve(records);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const loginUser = async (username: string, password: string) => {
  try {
    const { data } = await client.post("/api/user/login", {
      username: username,
      password: password,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
