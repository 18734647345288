import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export default function Tooltip(props) {
  const { positionX, positionY, data } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        p: 2,
        backgroundColor: "#fff",
        top: positionY,
        left: positionX,
        zIndex: 10,
        minWidth: 250,
        border: "2px solid #000",
        borderRadius: 2,
      }}
    >
      <Typography variant="body1" fontSize={16}>
        {data?.mode.charAt(0).toUpperCase() + data?.mode.slice(1) + " Mode"}
      </Typography>
      <Typography variant="body1" fontSize={16}>
        {"Value: " + data.value}
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="body1" fontSize={16}>
          Legend Value
        </Typography>
        <Box
          sx={{ width: 20, height: 20, backgroundColor: data.legendValue }}
        />
      </Stack>
      <Typography variant="body1" fontSize={16}>
        {"Sample: " + data.sample}
      </Typography>
    </Box>
  );
}
