import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { getExImageData } from "../api/exImage";
import EXAtlas from "../assets/exatlas.svg";
import AspWood from "../assets/aspwood.svg";
import AspLeaf from "../assets/leaf_development.svg";
import Sex from "../assets/sex.svg";
import Xylem from "../assets/xylem_leaf.svg";
import Error from "../assets/error.svg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as d3 from "d3";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { EX_IMAGE_SAMPLE_MAP } from "../constants";
import Tooltip from "../component/exImage/Tooltip";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const EXPERIMENTS_WITH_SVG = [
  {
    name: "aspwood",
    svg: AspWood,
  },
  {
    name: "exatlas",
    svg: EXAtlas,
  },
  {
    name: "leaf_development",
    svg: AspLeaf,
  },
  {
    name: "sex",
    svg: Sex,
  },
  {
    name: "xylem_leaf",
    svg: Xylem,
  },
];

function clamp(input, minn, maxn) {
  if (input > maxn) {
    return parseFloat(maxn);
  }
  if (input < minn) {
    return parseFloat(minn);
  }
  return parseFloat(input);
}

function rgbToHex(red, green, blue) {
  const rgb = (red << 16) | (green << 8) | (blue << 0);
  return "#" + (0x1000000 + rgb).toString(16).slice(1);
}

function roundNumber(num, noPlaces) {
  return Math.round(num * Math.pow(10, noPlaces)) / Math.pow(10, noPlaces);
}

const VIEW_MODE = {
  RELATIVE: "relative",
  ABSOLUTE: "absolute",
};

export default function ExImage(props) {
  const width = props.dimensions?.width;
  const experimentState = useSelector(
    (state: any) => state?.dashboard?.settings?.parameters
  );
  const experiment = experimentState?.neodash_experiment_value;
  const dashboardState = useSelector((state: any) => state?.dashboard);
  const geneLists = dashboardState?.addedGenieLists;
  const activeGeneList = geneLists?.find((item) => item?.active);

  const [dataJson, setDataJson] = useState();
  const [viewMode, setViewMode] = useState(VIEW_MODE.RELATIVE);
  const [maxValue, setMaxValue] = useState(0);
  const [activeGene, setActiveGene] = useState("");
  const [showTooltip, setShowTooptip] = useState(false);
  const [tooltipPostion, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipData, setTooltipData] = useState({
    mode: "",
    value: 0,
    legendValue: 0,
    sample: "",
  });
  const [elements, setElements] = useState<any>([]);
  const [expressionTable, setExpressionTable] = useState<any>([]);
  const [showExpressionTable, setShowExpressionTable] = useState(false);

  useEffect(() => {
    if (activeGeneList && activeGeneList.genes.length > 0) {
      setActiveGene(activeGeneList.genes[0]);
      drawExImage(activeGeneList.genes[0]);
    }
  }, [activeGeneList, experiment]);

  const [containerKey, setContainerKey] = useState<any>(false);
  let private_view = experimentState?.neodash_experiment_value;

  const drawExImage = (activeGene) => {
    setContainerKey(!containerKey);
    if (experiment) {
      const image = EXPERIMENTS_WITH_SVG.find((e) => e.name === experiment);
      if (image) {
        d3.xml(image.svg).then((data) => {
          d3.select("#svg-container").selectAll("*").remove();
          d3.select("#svg-container").node().append(data.documentElement);
          setTimeout(() => {
            getData(experiment, activeGene);
          }, 1000);
        });
      } else {
        d3.xml(Error).then((data) => {
          d3.select("#svg-container").node().append(data.documentElement);
        });
      }
    }
  };

  const handleChangeActiveGene = (newGene) => {
    setActiveGene(newGene);
    drawExImage(newGene);
  };

  const expressionTableColumns = [
    {
      field: "sample",
      headerName: "Sample",
      width: 200,
    },
    {
      field: "value",
      headerName: viewMode === "absolute" ? "LOG2" : "LOG2FC",
      width: 200,
    },
  ];

  function tabulate(data, viewMode) {
    const tableData: any = [];
    for (let index = 0; index < data.popdata.length; index++) {
      const element = data.popdata[index];

      var sample_name =
        element.sample.charAt(0).toUpperCase() + element.sample.substr(1);
      switch (element.sample) {
        case "InfBR":
          sample_name = "P. cinnamomi: S inoculated";
          break;
        case "ConBR":
          sample_name = "P. cinnamomi: S mock inoculated";
          break;
        case "l_invasa_s_infested":
          sample_name = "L. invasa: S infested";
          break;
        case "l_invasa_r_infested":
          sample_name = "L. invasa: R infested";
          break;
        case "l_invasa_s_uninfested":
          sample_name = "L. invasa: S uninfested";
          break;
        case "l_invasa_r_uninfested":
          sample_name = "L. invasa: R uninfested";
          break;
        case "TAG5ControlBR":
          sample_name = "C. austroafricana: R mock inoculated";
          break;
        case "ZG14ControlBR":
          sample_name = "C. austroafricana: S mock inoculated";
          break;
        case "ZG14InfectedBR":
          sample_name = "C. austroafricana: S inoculated";
          break;
        case "TAG5InfectedBR":
          sample_name = "C. austroafricana: R inoculated";
          break;
        case "shoottips":
          sample_name = "Shoot tips";
          break;
        case "immaturexylem":
          sample_name = "Immature xylem";
          break;
        case "matureleaf":
          sample_name = "Mature leaf";
          break;
        case "youngleaf":
          sample_name = "Young leaf";
          break;
        case "rep":
          sample_name = "Replicates";
          break;
        case "stage1":
          sample_name = "Flowers stage1 late (biorep 3)";
          break;
        case "stage2":
          sample_name = "Flowers stage2 early (biorep 1)";
          break;
        case "stage3":
          sample_name = "Flowers stage2 late (biorep 1)";
          break;
        case "rep1":
          sample_name = "Tensionwood (biorep 1)";
          break;
        case "rep2":
          sample_name = "Tensionwood (biorep 2)";
          break;
        case "rep3":
          sample_name = "Tensionwood (biorep 3)";
          break;
        case "GCTT":
          sample_name = "Unbent control (biorep 1)";
          break;
        case "CACT":
          sample_name = "Unbent control (biorep 2)";
          break;
        case "TTGT":
          sample_name = "Unbent control (biorep 3)";
          break;
        case "matureleaf_TTGT":
          sample_name = "Mature leaves (biorep 1)";
          break;
        case "matureleaf_GCTT":
          sample_name = "Mature leaves (biorep 2)";
          break;
        case "matureleaf_CACT":
          sample_name = "Mature leaves (biorep 3)";
          break;
        case "phloem_TTGT":
          sample_name = "Phloem (biorep 1)";
          break;
        case "phloem_GCTT":
          sample_name = "Phloem (biorep 2)";
          break;
        case "phloem_CACT":
          sample_name = "Phloem (biorep 3)";
          break;
        case "immature_xylem_4":
          sample_name = "immature_xylem (biorep 3)";
          break;
        case "immature_xylem_2":
          sample_name = "immature_xylem (biorep 2)";
          break;
        case "Leaves-Beetle-Damaged":
          sample_name = "Beetle Damaged";
          break;
        case "Leaves-Mechanical-Damage":
          sample_name = "Mechanical";
          break;
        case "Leaves-Control":
          sample_name = "Control";
          break;
        case "Buds-Dormant":
          sample_name = "Buds Dormant";
          break;
        case "Buds-Pre-chilling":
          sample_name = "Buds Pre chilling";
          break;
        case "Leaves-Young-Expanding":
          sample_name = "Leaves Young Expanding";
          break;
        case "Leaves-Freshly-Expanded":
          sample_name = "Leaves Freshly Expanded";
          break;
        case "Leaves-Mature":
          sample_name = "Leaves Mature";
          break;
        case "Flowers-Dormant":
          sample_name = "Flowers Dormant";
          break;
        case "Flowers-Expanding":
          sample_name = "Flowers Expanding";
          break;
        case "Flowers-Expanded":
          sample_name = "Flowers Expanded";
          break;

        case "Z4006TR01":
          sample_name = "Immature male cone (Z4006TR01)";
          break;
        case "Z4006TR02":
          sample_name = "Vegetative shoots (Z4006TR02)";
          break;
        case "Z4006TR03":
          sample_name = "Needles (2009)(Z4006TR03)";
          break;
        case "Z4006TR04":
          sample_name = "Needles (2008)(Z4006TR04)";
          break;
        case "Z4006TR05":
          sample_name = "Infected needles (Z4006TR05)";
          break;
        case "Z4006TR07":
          sample_name = "Vegetative shoots (Z4006TR07)";
          break;
        case "Z4006TR08":
          sample_name = "Pineapple galls (Z4006TR08)";
          break;
        case "Z4006TR09":
          sample_name = "Buds early season developing (Z4006TR09)";
          break;
        case "Z3001TR10":
          sample_name = "Immature female cone (Z4006TR10)";
          break;
        case "Z4006TR11":
          sample_name = "Needles from vegetative shoots (Z4006TR11)";
          break;
        case "Z4006TR12":
          sample_name = "Stem from vegetative shoots (Z4006TR12)";
          break;
        case "Z4006TR13":
          sample_name = "Needles from vegetative shoots (Z4006TR13)";
          break;
        case "Z4006TR15":
          sample_name = "Buds late season developed (Z4006TR15)";
          break;
        case "Z4006TR16":
          sample_name = "Needles from dried twig (Z4006TR16)";
          break;
        case "Z4006TR18":
          sample_name = "Needles from girdled twig (Z4006TR18)";
          break;
        case "Z4006TR19":
          sample_name = "Stem from girdled twig (Z4006TR19)";
          break;
        case "Z4006TR20":
          sample_name = "Early morning needles (Z4006TR20)";
          break;
        case "Z4006TR21":
          sample_name = "Mid-day needles (Z4006TR21)";
          break;
        case "Z4006TR22":
          sample_name = "Late afternoon needles (Z4006TR022)";
          break;
        case "Z4006TR23":
          sample_name = "Night needles (Z4006TR23)";
          break;
        case "Z4006TR24":
          sample_name = "Wood (phloem+cambium+xylem, early)(Z4006TR24)";
          break;
        case "Z4006TR25":
          sample_name = "Wood (phloem+cambium+xylem, late)(Z4006TR25)";
          break;
      }
      sample_name = sample_name.replace("Exatlas_", "exAtlas ");
      sample_name = sample_name.replace("Exatlas--", "");
      sample_name = sample_name.replace("Aspwood--wood--", "");
      sample_name = sample_name.replace("Xylem_leaf--", "");

      sample_name = sample_name.replace("--NA", "");
      sample_name = sample_name.replace("Leaf_development--leaf--", "");
      sample_name = sample_name.replace("--", " ");
      sample_name = sample_name.replace("Terminal_", "Leaf ");
      sample_name = sample_name.replace("Preformed_", "Stage ");
      sample_name = sample_name.replace("TApical_region", "Apical region ");
      tableData.push({
        id: index,
        sample: sample_name,
        value: viewMode === VIEW_MODE.ABSOLUTE ? element.log2 : element.log2fc,
      });
    }
    setExpressionTable(tableData);
  }

  function createlegendholder(w, h, z, viewMode) {
    const rootsvg = d3.selectAll("svg");
    rootsvg.selectAll("g#legend").remove();
    const legend_g = rootsvg
      .append("g")
      .attr("width", w)
      .attr("height", h)
      .attr("id", "legend");
    let logscale;
    if (viewMode === VIEW_MODE.RELATIVE) {
      logscale = "Relative mean difference";
    } else {
      logscale = "log2(TPM+1)";
    }

    const legend = d3
      .selectAll("svg")
      .selectAll("g#legend")
      .attr("class", "legend")
      .attr("transform", "translate(" + w + "," + h + ") scale(" + z + ")");

    legend
      .append("text")
      .attr("x", 180)
      .attr("fill", "black")
      .attr("font-size", "12px")
      .attr("font-style", "normal")
      .attr("font-variant", "normal")
      .attr("font-weight", "normal")
      .attr("text-rendering", "optimizeLegibility")
      .attr("shape-rendering", "default")
      .attr("font-family", "sans-serif")
      .text(logscale);
  }

  function createlegends(color, name, k) {
    const legend = d3.selectAll("svg").selectAll("g#legend");
    legend
      .append("rect")
      .attr("x", 180)
      .attr("y", 10 + k * 20)
      .attr("width", 16)
      .attr("height", 16)
      .style("fill", color);
    legend
      .append("text")
      .attr("x", 210)
      .attr("fill", "black")
      .attr("font-family", "sans-serif")
      .attr("y", 24 + k * 20)
      .attr("font-family", "Georgia")
      .attr("font-size", "15px")
      .attr("font-style", "normal")
      .attr("font-variant", "normal")
      .attr("font-weight", "normal")
      .attr("text-rendering", "optimizeLegibility")
      .attr("shape-rendering", "default")
      .text(name);
  }

  function populateLegend(maxvalue, viewMode, dataJson) {
    const legenditems = 11;
    const experiment = experimentState?.neodash_experiment_value;

    switch (true) {
      case experiment == "norwood":
        createlegendholder(-140, 340, 0.4, viewMode);
        break;
      case experiment == "aspwood":
        createlegendholder(-140, 40, 0.5, viewMode);
        break;
      case experiment == "leaf_development":
        createlegendholder(-30, 240, 0.4, viewMode);
        break;
      case experiment == "exatlas":
        createlegendholder(-30, 440, 0.6, viewMode);
        break;
      default:
        createlegendholder(-120, 640, 1, viewMode);
    }
    let s = "";
    let intensity;
    let redVal;
    let paintColour;
    const maxRatio = calculateMaxRatio(dataJson, maxvalue);
    if (viewMode === VIEW_MODE.RELATIVE) {
      const signalDelta = -2 * maxRatio;
      const signalGrad = -Math.abs(signalDelta) / (legenditems - 1);
      let signal = maxRatio;
      for (let k = 0; k < legenditems; k++) {
        if (k == legenditems - 1) {
          signal = -1 * maxRatio;
        }
        intensity = (signal * 256.0) / maxRatio;
        intensity = clamp(intensity, -255, 255);
        if (k == 0) {
          let fold = Math.pow(2, signal);
          s = ""; // + fold.toString().substr(0,4) +  "-fold"
        } else {
          s = "";
        }
        if (signal > 0) {
          paintColour = rgbToHex(255, 255 - intensity, 255 - intensity);
        } else {
          paintColour = rgbToHex(255 + intensity, 255 + intensity, 255);
        }
        createlegends(paintColour, roundNumber(signal, 2) + s, k);
        signal += signalGrad;
      }
    } else {
      const perItem = roundNumber(maxvalue / legenditems, 2);
      let count = maxvalue;
      for (let i = 0; i < legenditems; i++) {
        intensity = Math.floor((count * 256.0) / maxvalue);
        if (count == maxvalue) {
          redVal = 0;
        } else {
          redVal = 256 - intensity;
        }
        if (count >= 0) {
          paintColour = rgbToHex(255, redVal, redVal);
        } else {
          paintColour = rgbToHex(
            Math.min(255, 255 + intensity),
            Math.min(255, 255 + intensity),
            -intensity
          );
        }
        createlegends(paintColour, roundNumber(count, 2), i);
        count -= perItem;
      }
    }
  }

  function preProcessData(data) {
    let tmpSampleObject: any = data;
    let tempValue;
    let tempArray = new Array();

    for (let name in tmpSampleObject.popdata) {
      tempValue = parseFloat(tmpSampleObject.popdata[name]["log2fc"]);
      tempArray.push(tempValue);
    }
    let tmpArray: any = [];
    let tmpObject = {};
    let sum = tempArray.reduce(function (a, b) {
      return a + b;
    });

    let avg = sum / tempArray.length;
    for (let mm = 0; mm < tmpSampleObject.popdata.length; mm++) {
      tmpObject = new Object();
      tmpObject["log2"] = tmpSampleObject.popdata[mm]["log2"] - avg;
      tmpObject["sample"] = tmpSampleObject.popdata[mm]["sample"];
      tmpObject["log2fc"] = tmpSampleObject.popdata[mm]["log2fc"] - avg;
      tmpArray.push(tmpObject);
    }
    return {
      popdata: tmpArray,
    };
  }

  function colourobjects(sample, colorvalue, value) {
    EX_IMAGE_SAMPLE_MAP.forEach((element) => {
      sample = sample.replace(element.key, element.replace);
    });
    let sampleName = "";
    switch (sample) {
      case "Z4006TR01":
        sampleName = "Immature male cone (Z4006TR01)";
        break;
      case "Z4006TR02":
        sampleName = "Vegetative shoots (Z4006TR02)";
        break;
      case "Z4006TR03":
        sampleName = "Needles (2009)(Z4006TR03)";
        break;
      case "Z4006TR04":
        sampleName = "Needles (2008)(Z4006TR04)";
        break;
      case "Z4006TR05":
        sampleName = "Infected needles (Z4006TR05)";
        break;
      case "Z4006TR07":
        sampleName = "Vegetative shoots (Z4006TR07)";
        break;
      case "Z4006TR08":
        sampleName = "Pineapple galls (Z4006TR08)";
        break;
      case "Z4006TR09":
        sampleName = "Buds early season developing (Z4006TR09)";
        break;
      case "Z3001TR10":
        sampleName = "Immature female cone (Z4006TR10)";
        break;
      case "Z4006TR11":
        sampleName = "Needles from vegetative shoots (Z4006TR11)";
        break;
      case "Z4006TR12":
        sampleName = "Stem from vegetative shoots (Z4006TR12)";
        break;
      case "Z4006TR13":
        sampleName = "Needles from vegetative shoots (Z4006TR13)";
        break;
      case "Z4006TR15":
        sampleName = "Buds late season developed (Z4006TR15)";
        break;
      case "Z4006TR16":
        sampleName = "Needles from dried twig (Z4006TR16)";
        break;
      case "Z4006TR18":
        sampleName = "Needles from girdled twig (Z4006TR18)";
        break;
      case "Z4006TR19":
        sampleName = "Stem from girdled twig (Z4006TR19)";
        break;
      case "Z4006TR20":
        sampleName = "Early morning needles (Z4006TR20)";
        break;
      case "Z4006TR21":
        sampleName = "Mid-day needles (Z4006TR21)";
        break;
      case "Z4006TR22":
        sampleName = "Late afternoon needles (Z4006TR022)";
        break;
      case "Z4006TR23":
        sampleName = "Night needles (Z4006TR23)";
        break;
      case "Z4006TR24":
        sampleName = "Wood (phloem+cambium+xylem, early)(Z4006TR24)";
        break;
      case "Z4006TR25":
        sampleName = "Wood (phloem+cambium+xylem, late)(Z4006TR25)";
        break;
    }
    console.log("Sample is: ", sample);

    let svg_element_id;
    try {
      svg_element_id = d3.selectAll("g#" + sample).selectAll("path");
    } catch (error) {
      console.log("Error is: ", error);
      return;
    }
    let tipValue = roundNumber(value, 2);

    if (sample == "male_cone_z4006tr01") {
      svg_element_id = d3
        .selectAll("svg")
        .selectAll("path#male_cone_z4006tr01");
    }

    if (sample == "wood_z4006tr24") {
      svg_element_id = d3.selectAll("svg").selectAll("#wood_z4006tr24");
    }

    if (sample == "wood_z4006tr25") {
      svg_element_id = d3.selectAll("svg").selectAll("#wood_z4006tr25");
    }

    if (private_view == "norwood") {
      svg_element_id = d3.selectAll("svg");
      let tmp_class = sample.toLowerCase();
      svg_element_id
        .selectAll(".cls-" + tmp_class)
        .attr("style", "fill:" + colorvalue);
      show_tooltips(svg_element_id, tipValue, colorvalue, sampleName);
    } else {
      svg_element_id.attr("fill", colorvalue);
    }

    if (private_view == "exatlas") {
      show_tooltips(svg_element_id, tipValue, colorvalue, sample);
    } else {
      if (private_view != "norwood") {
        show_tooltips(svg_element_id, tipValue, colorvalue, sample);
      }
    }

    if (sample == "mature-leaves" || sample == "GSM146281") {
      let mature_leavesl = d3
        .selectAll("svg")
        .selectAll("g#mature_leavesl")
        .selectAll("path");
      let mature_leavesr = d3
        .selectAll("svg")
        .selectAll("g#mature_leavesr")
        .selectAll("path");
      mature_leavesl.attr("fill", colorvalue);
      mature_leavesr.attr("fill", colorvalue);
    }
    if (sample == "nodes" || sample == "GSM146286") {
      const node = d3.selectAll("svg").selectAll("path#node");
      node.attr("fill", colorvalue);
    }
    if (sample == "internodes" || sample == "GSM146141") {
      const internode = d3.selectAll("svg").selectAll("line#internode");
      internode.attr("stroke", colorvalue);
      internode.attr("fill", colorvalue);
    }
    if (sample == "young-leaves" || sample == "GSM146289") {
      const young_leavesr1 = d3
        .selectAll("svg")
        .selectAll("g#young_leavesr1")
        .selectAll("path");
      const young_leavesr2 = d3
        .selectAll("svg")
        .selectAll("g#young_leavesr2")
        .selectAll("path");
      const young_leavesl1 = d3
        .selectAll("svg")
        .selectAll("g#young_leavesl1")
        .selectAll("path");
      young_leavesr1.attr("fill", colorvalue);
      young_leavesr2.attr("fill", colorvalue);
      young_leavesl1.attr("fill", colorvalue);
    }
    if (sample == "roots" || sample == "GSM146283") {
      const roots = d3
        .selectAll("svg")
        .selectAll("g#roots")
        .selectAll("g")
        .selectAll("path");
      roots.attr("fill", colorvalue);
    }
  }

  function calculateMaxRatio(data, maxvalue) {
    let ratio = 0;
    let tempMaxRatio = 0;
    for (let name in data.popdata) {
      ratio = parseFloat(data.popdata[name]["log2fc"]) / maxvalue;
      if (ratio > tempMaxRatio) {
        tempMaxRatio = ratio;
      }
    }
    return tempMaxRatio;
  }

  function calculateDataSignal(data, maxvalue, viewMode) {
    if (viewMode === VIEW_MODE.RELATIVE) {
      let ratio = 0;
      let tempMaxRatio = 0;
      for (let name in data.popdata) {
        let paintColourR;
        ratio = parseFloat(data.popdata[name]["log2fc"]) / maxvalue;
        if (ratio > tempMaxRatio) {
          tempMaxRatio = ratio;
        }
        let intensityR = clamp(Math.floor(256.0 * ratio), -255, 255);
        if (intensityR > 0) {
          paintColourR = rgbToHex(255, 255 - intensityR, 255 - intensityR);
        } else {
          paintColourR = rgbToHex(255 + intensityR, 255 + intensityR, 255);
        }
        colourobjects(
          data.popdata[name]["sample"],
          paintColourR,
          data.popdata[name]["log2fc"]
        );
      }
    } else {
      for (let name in data.popdata) {
        let intensityA = Math.floor(
          (data.popdata[name]["log2"] * 256.0) / maxvalue
        );
        let redVal;
        let paintColourA;
        if (data.popdata[name]["log2"] == maxvalue) {
          redVal = 0;
        } else {
          redVal = 255 - intensityA;
        }
        if (data.popdata[name]["log2"] > 0) {
          paintColourA = rgbToHex(255, redVal, redVal);
        } else if (data.popdata[name]["log2"] == 0) {
          paintColourA = "#fff";
        } else {
          paintColourA = rgbToHex(255 + intensityA, redVal, -intensityA);
        }
        colourobjects(
          data.popdata[name]["sample"],
          paintColourA,
          data.popdata[name]["log2"]
        );
      }
    }
  }

  function getTheMaxValue(data) {
    let maxvalue = 0;
    data?.popdata.forEach((element) => {
      let tst;
      if (viewMode === VIEW_MODE.RELATIVE) {
        tst = parseFloat(element["log2fc"]);
        if (tst < 0) {
          tst = -1 * parseFloat(element["log2fc"]);
        }
      } else {
        tst = parseFloat(element["log2"]);
        if (tst < 0) {
          tst = parseFloat(element["log2"]) * -1;
        }
      }
      if (tst > maxvalue) {
        maxvalue = tst;
      }
    });
    return maxvalue;
  }

  const getData = useCallback(
    async (experiment, activeGene) => {
      if (!activeGene || !experiment) {
        return;
      }
      try {
        const response = await getExImageData(experiment, activeGene);
        const json = response.data.substring(9);
        tabulate(JSON.parse(json), viewMode);
        setDataJson(JSON.parse(json));
        if (viewMode === VIEW_MODE.RELATIVE) {
          const data = preProcessData(JSON.parse(json));
          const maxvalue = getTheMaxValue(data);
          setMaxValue(maxvalue);
          setElements([]);
          calculateDataSignal(data, maxvalue, viewMode);
          populateLegend(maxvalue, viewMode, JSON.parse(json));
        } else {
          const data = JSON.parse(json);
          const maxvalue = getTheMaxValue(data);
          setMaxValue(maxvalue);
          setElements([]);
          calculateDataSignal(data, maxvalue, viewMode);
          populateLegend(maxvalue, viewMode, JSON.parse(json));
        }
      } catch (error) {
        console.log("Error occured while getting the data:", error);
        // Error occured while getting the data
      }
    },
    [activeGene]
  );

  const handleChangeViewMode = (event, newViewMode) => {
    if (newViewMode === null) {
      return;
    }
    setViewMode(newViewMode);
    tabulate(dataJson, viewMode);
    if (newViewMode === VIEW_MODE.RELATIVE) {
      calculateDataSignal(preProcessData(dataJson), maxValue, newViewMode);
    } else {
      calculateDataSignal(dataJson, maxValue, newViewMode);
    }
    populateLegend(maxValue, newViewMode, dataJson);
  };

  const exportAsSvg = () => {
    const fileName = prompt("Please enter file name", "image");
    if (!fileName) {
      alert("Invalid filename");
      return;
    }
    const element = document.getElementById("svg-container");
    let serializer = new XMLSerializer();
    let source = serializer.serializeToString(
      element!.getElementsByTagName("svg")[0]
    );
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
    let url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);
    let link = document.createElement("a");

    link.download = fileName + ".svg";
    link.href = url;
    link.click();
  };

  function show_tooltips(element_id, values, colorvalue, sample) {
    sample = sample.replace("--NA", "");
    const tempElements = elements;
    tempElements.push({
      id: element_id,
      sample: sample,
      values: values,
      colorvalue: colorvalue,
    });
    setElements(tempElements);
    element_id.on("mouseover", function (event) {
      const position = d3.pointer(event, element_id);
      setTooltipPosition({ x: position[0], y: position[1] });
      setTooltipData({
        mode: viewMode,
        value: values,
        legendValue: 0,
        sample: sample,
      });
      setShowTooptip(true);
    });
    element_id.on("mousemove", function (event) {
      const position = d3.pointer(event, element_id);
      setTooltipPosition({ x: position[0], y: position[1] });
      setTooltipData({
        mode: viewMode,
        value: values,
        legendValue: colorvalue,
        sample: sample,
      });
      setShowTooptip(true);
    });
    element_id.on("mouseout", function (event) {
      setShowTooptip(false);
    });
  }

  return (
    <div>
      {showTooltip && (
        <Tooltip
          positionX={tooltipPostion.x}
          positionY={tooltipPostion.y}
          data={tooltipData}
        />
      )}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleChangeViewMode}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton value={VIEW_MODE.RELATIVE} aria-label="left aligned">
            Relative
          </ToggleButton>
          <ToggleButton value={VIEW_MODE.ABSOLUTE} aria-label="centered">
            Absolute
          </ToggleButton>
        </ToggleButtonGroup>
        <Button variant="contained" size="small" onClick={exportAsSvg}>
          Export as SVG
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => setShowExpressionTable(!showExpressionTable)}
        >
          {showExpressionTable ? "Hide Expression Table" : "Expression Table"}
        </Button>
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 2 }}>
        {showExpressionTable && (
          <div
            style={{
              height: 500,
              width: "50%",
              paddingLeft: 30,
            }}
          >
            <DataGrid
              rows={expressionTable}
              columns={expressionTableColumns}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        )}
      </Stack>
      <Stack direction="row">
        <div
          id="svg-container"
          key={containerKey}
          style={{
            width: width - 250,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <Box sx={{ width: 250 }}>
          <Typography variant="h6">Active Genelist</Typography>
          <Box sx={{ maxHeight: 450, overflow: "auto" }}>
            <List component="nav">
              {activeGeneList?.genes?.map((item) => {
                return (
                  <ListItemButton
                    selected={activeGene === item}
                    onClick={() => {
                      handleChangeActiveGene(item);
                    }}
                  >
                    <ListItemText primary={item} />
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
        </Box>
      </Stack>
    </div>
  );
}
