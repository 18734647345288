import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  DialogActions,
  Button,
  Stack,
  Typography,
  Checkbox,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setTheme } from "../../application/ApplicationActions";
import { GENIEBOARD_THEMES } from "../../constants";

export default function NoeThemeSwitch(props) {
  const { open, setOpen } = props;

  const applicationState = useSelector((state) => state?.application);
  const dispatch = useDispatch();
  const [availableThemes, setAvailableThemes] = React.useState(
    GENIEBOARD_THEMES.map((item) => {
      return {
        ...item,
        checked: applicationState?.themeIndex === item.index,
      };
    })
  );
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeTheme = (index) => {
    setTimeout(() => {
      dispatch(setTheme(index));
    }, 1000);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Select your preffered theme</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <Stack direction="column" spacing={2}>
            {GENIEBOARD_THEMES.map((item, index) => (
              <Box width={400}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Checkbox
                      defaultChecked={
                        applicationState?.themeIndex === item.index
                      }
                      checked={availableThemes[index].checked}
                      onChange={(event, checked) => {
                        setAvailableThemes((prev) =>
                          prev.map((item) => {
                            return {
                              ...item,
                              checked: item.index === index ? checked : false,
                            };
                          })
                        );
                        if (checked) {
                          handleChangeTheme(item.index);
                        }
                      }}
                    />
                    <Typography variant="body1">{item.name}</Typography>
                  </Stack>
                  <Stack direction="row">
                    {item.colors.map((color) => (
                      <Box
                        width={25}
                        height={25}
                        sx={{ backgroundColor: color, ml: 0.5 }}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Box>
              // <Box
              //   sx={{
              //     width: 200,
              //     height: 100,
              //     backgroundColor: item.primary,
              //     borderRadius: 2,
              //     cursor: "pointer",
              //     display: "flex",
              //     justifyContent: "center",
              //     alignItems: "center",
              //   }}
              //   onClick={() => handleChangeTheme(item.index)}
              // >
              //   <Stack
              //     direction="column"
              //     justifyContent="center"
              //     alignItems="center"
              //   >
              //     <Typography variant="body2">{item.name}</Typography>
              //     {applicationState?.themeIndex === item.index && (
              //       <Typography variant="caption" color={"#fff"}>
              //         Active
              //       </Typography>
              //     )}
              //   </Stack>
              // </Box>
            ))}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}
