import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Badge from "@mui/material/Badge";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

const formatProperty = (property) => {
  if (property.startsWith("http://") || property.startsWith("https://")) {
    return <a href={property}>{property}</a>;
  }
  return property;
};

export const NeoGraphItemInspectModal = ({
  open,
  handleClose,
  title,
  object,
  textAlign = "left",
}) => {
  return (
    <div>
      <Dialog
        maxWidth={"lg"}
        open={open == true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {title}
          <IconButton
            onClick={handleClose}
            style={{ padding: "3px", marginLeft: "20px", float: "right" }}
          >
            <Badge badgeContent={""}>
              <CloseIcon />
            </Badge>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {object && (
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(object).length == 0 ? (
                    <i>(No properties)</i>
                  ) : (
                    Object.keys(object)
                      .sort()
                      .map((key) => (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {key}
                          </TableCell>
                          <TableCell align={textAlign}>
                            {formatProperty(object[key].toString())}
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NeoGraphItemInspectModal;
