import {
  Link,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export default function FunctionalInformation(props) {
  const { geneOntologyData, genePfamData } = props;
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Gene Ontology
      </Typography>
      <TableBody>
        {geneOntologyData.map((item) => {
          return (
            <TableRow>
              <TableCell>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(
                      `https://amigo.geneontology.org/amigo/medial_search?q=${item.gene}`
                    );
                  }}
                >
                  {item.gene}
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{item.description}</Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Pfam
      </Typography>
      <TableBody>
        {genePfamData.map((item) => {
          return (
            <TableRow>
              <TableCell>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(
                      `https://www.ebi.ac.uk/interpro/entry/pfam/${item.gene}/`
                    );
                  }}
                >
                  {item.gene}
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{item.description}</Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Stack>
  );
}
