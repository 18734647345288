import React from "react";
import { ChartProps } from "./Chart";
import PieVisualization from "./visualizations/PieVisualization";

/**
 * Embeds a PieChart (from Charts) into NeoDash.
 */
const NeoPieChart = (props: ChartProps) => {
  if (
    props.records == null ||
    props.records.length == 0 ||
    props.records[0].keys == null
  ) {
    return <>No data, re-run the report.</>;
  }
  console.log("Pie chart logs are: ", props.records);
  return (
    <PieVisualization
      records={props.records}
      settings={props.settings}
      selection={props.selection}
      first={props.records ? props.records[0] : undefined}
    />
  );
};

export default NeoPieChart;
