import styled from 'styled-components';

export const ParentContainer = styled.div`
    display: flex;
    gap: 0.3rem;
    align-items: center;
`;

export const StyledConditionText = styled.div`
    font-weight: bold;
    background-color: lightcyan;
    padding: 0 0.3rem;
`;
