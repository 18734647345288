import { IElement } from "../GoslingManhatten";

export default function ManhattenGoslingSpec(
  data: IElement[],
  height: number | undefined,
  width: number | undefined
) {
  const spec = {
    assembly: [
      ["chr1", 53234430],
      ["chr2", 76628579],
      ["chr3", 98782267],
      ["chr4", 119496579],
      ["chr5", 141215915],
      ["chr6", 165082078],
      ["chr7", 178971858],
      ["chr8", 195900634],
      ["chr9", 207869202],
      ["chr10", 227758339],
      ["chr11", 244568642],
      ["chr12", 258206615],
      ["chr13", 271908153],
      ["chr14", 288197579],
      ["chr15", 301058894],
      ["chr16", 314427297],
      ["chr17", 331956289],
      ["chr18", 345421245],
      ["chr19", 361795894],
    ],
    tracks: [
      {
        data: {
          type: "json",
          values: data,
        },
        mark: "point",
        x: { field: "pos", type: "genomic", axis: "bottom" },
        // xe: { field: "end", type: "genomic" },
        y: {
          field: "p_value",
          type: "quantitative",
          axis: "right",
        },
        color: { field: "chr", type: "nominal", legend: true },
        size: { value: 5 },
        width: width ? width - 300 : 500,
        height: height ? height - 300 : 300,
        tooltip: [
          { field: "chr", type: "nominal", alt: "Chromosome" },
          { field: "p_value", type: "quantitative", alt: "P-value" },
          { field: "phenotype_name", type: "nominal", alt: "Phenotype Name" },
        ],
      },
    ],
  };
  return spec;
}
