import { schemeCategory10, schemeAccent, schemeDark2, schemePaired, schemePastel1, schemePastel2, schemeSet1, schemeSet2, schemeSet3 } from 'd3-scale-chromatic';

export const categoricalColorSchemes = {
    neodash: ["#588c7e", "#f2e394", "#f2ae72", "#d96459", "#5b9aa0", "#d6d4e0", "#b8a9c9", "#622569", "#ddd5af", "#d9ad7c", "#a2836e", "#674d3c"],
    nivo: ['#e8c1a0', '#f47560', '#f1e15b', '#e8a838', '#61cdbb', '#97e3d5'],
    category10: schemeCategory10,
    accent: schemeAccent,
    dark2: schemeDark2, 
    paired: schemePaired,
    pastel1: schemePastel1,
    pastel2: schemePastel2,
    set1: schemeSet1,
    set2: schemeSet2,
    set3: schemeSet3,
    Purple: ["#3C1053", "#634075" ,"#8A7098", "#B19FBA", "#D8CFDD"],
    Mulberry: ["#830051" ,"#9A3374" ,"#B36697" ,"#CC99B9","#E6CCDC"],
    Graphite:["#3C4242 " ,"#656969 " ,"#888E8E " ,"#B2B4B4 " ,"#D8DADA "],
    Gold:[ "#F0ABOO " ,"#F3BC33 " ,"#F6CD66 " ,"#F9DD99 " ,"#FCEECC" ],
    LigtBlue:["#68D2DF " ,"#86DCE6 " ,"#A4E3EE " ,"#C3EDF2 " ,"#E1F6F9 "],
    Navy:[ "#003865 " ,"#336084 " ,"#6688A3 " ,"#99AFC1 " ,"#CCD7E0"],
    LimeGreen:["#C4D600 " ,"#D2DE33 " ,"#DCE766 " ,"#E7EF99 " ,"#F3F7CC "] ,
    Platinum:[ "#9FB0AB " ,"#B0C0BD " ,"#C5D2CC " ,"#D8DFDE " ,"#EBEFEE"] , 
    AZColours:["#F3BC33","#C4D600","#68D2DF","#9FB0AB","#003865","#3C1053","#3C4242","#D0006F","#830051"], 
    Magenta:["#D0006F " ,"#DA338C " ,"#E366A9 " ,"#EC99C5 " ,"#F6CCE2"]
};
