import React from "react";
import { ChartProps } from "./Chart";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import BasicDetails from "../component/GeneDetails/BasicInformation";
import SequenceInformation from "../component/GeneDetails/SequenceInformation";
import FunctionalInformation from "../component/GeneDetails/FunctionalInformation";
import GeneFamilyData from "../component/GeneDetails/GeneFamilyData";

const GeneDetails = (props: ChartProps) => {
  const [value, setValue] = React.useState(0);
  const [basicData, setBasicData] = React.useState();
  const [proteinSequence, setProteinSequence] = React.useState("");
  const [genomicSequence, setGenomicSequence] = React.useState("");
  const [cdsSequence, setCdsSequence] = React.useState("");
  const [sequenceColorData, setSequenceColorData] = React.useState([]);
  const [transcriptSequence, setTranscriptSequence] = React.useState("");
  const [geneFamilyData, setGeneFamilyData] = React.useState([]);
  const [geneOntologyData, setGeneOntologyData] = React.useState<any>([]);
  const [genePfamData, setGenePfamData] = React.useState<any>([]);
  const [expressionData, setExpressionData] = React.useState();

  const validateFunctionalData = (data, type) => {
    const genes: any = [];
    if (data.length > 0) {
      data.forEach((element) => {
        const tempData = element._fields;
        const geneData = {
          gene: tempData[0],
          description: tempData[1],
        };
        genes.push(geneData);
      });
    }
    if (type === "ontology") {
      setGeneOntologyData(genes);
    } else if (type === "pfam") {
      setGenePfamData(genes);
    }
  };

  const validateBasicData = (data) => {
    if (data.length > 0) {
      const tempData = data[0]._fields[0].properties;
      setBasicData(tempData);
    }
  };

  const validateSequenceColorData = (data) => {
    const colorData: any = [];
    if (data.length > 0) {
      data.forEach((element) => {
        const tempData = element._fields;
        if (tempData.length > 0) {
          const geneProperties = { ...tempData[0].properties };
          const feature = geneProperties?.sc_feature;
          if (feature === "CDS" || feature === "3UTR" || feature === "5UTR") {
            colorData.push(geneProperties);
          }
        }
      });
      setSequenceColorData(colorData);
    }
  };

  const extractSequenceDataFromRecord = (data) => {
    if (data.length > 0) {
      const tempData = data[0]._fields;
      if (tempData.length > 0) {
        return tempData[0];
      }
    }
    return "";
  };

  const validateSequenceData = (type, data) => {
    if (type === "protein") {
      setProteinSequence(extractSequenceDataFromRecord(data));
    } else if (type === "genomic") {
      setGenomicSequence(extractSequenceDataFromRecord(data));
    } else if (type === "cds") {
      setCdsSequence(extractSequenceDataFromRecord(data));
    } else if (type === "transcript") {
      setTranscriptSequence(extractSequenceDataFromRecord(data));
    }
  };

  const validateGeneFamilyData = (data) => {
    const geneData: any = [];
    if (data.length > 0) {
      data.forEach((element) => {
        const tempData = element._fields;
        if (tempData.length > 0) {
          geneData.push({ ...tempData[0].properties });
        }
      });
    }
    setGeneFamilyData(geneData);
  };

  React.useEffect(() => {
    getGeneDetails();
  }, []);

  const getGeneDetails = () => {
    props?.queryCallback &&
      props.queryCallback(
        props?.settings?.basicInfoQuery ?? "",
        {},
        validateBasicData
      );
  };

  const getSequenceData = () => {
    if (props?.settings?.sequenceQueryColors) {
      props?.queryCallback &&
        props.queryCallback(
          props?.settings?.sequenceQueryColors ?? "",
          {},
          validateSequenceColorData
        );
    }
    props?.queryCallback &&
      props.queryCallback(
        props?.settings?.sequenceQueryProtein ?? "",
        {},
        (data: any) => validateSequenceData("protein", data)
      );
    props?.queryCallback &&
      props.queryCallback(
        props?.settings?.sequenceQueryGenomic ?? "",
        {},
        (data: any) => validateSequenceData("genomic", data)
      );
    props?.queryCallback &&
      props.queryCallback(
        props?.settings?.sequenceQueryCDs ?? "",
        {},
        (data: any) => validateSequenceData("cds", data)
      );
    props?.queryCallback &&
      props.queryCallback(
        props?.settings?.sequenceQueryTranscript ?? "",
        {},
        (data: any) => validateSequenceData("transcript", data)
      );
  };

  const getGeneFamilyData = () => {
    props?.queryCallback &&
      props.queryCallback(
        props?.settings?.familyQuery ?? "",
        {},
        validateGeneFamilyData
      );
  };

  const getFunctionalData = () => {
    // TODO: Need to modify the query to get the sequence data
    props?.queryCallback &&
      props.queryCallback(
        props?.settings?.functionalOntologyQuery ?? "",
        {},
        (data: any) => validateFunctionalData(data, "ontology")
      );
    props?.queryCallback &&
      props.queryCallback(
        props?.settings?.functionalPfamQuery ?? "",
        {},
        (data: any) => validateFunctionalData(data, "pfam")
      );
  };

  const getExpressionData = () => {
    // TODO: Need to modify the query to get the sequence data
    props?.queryCallback &&
      props.queryCallback(
        "MATCH (n:Gene) WHERE n.gene_id = 'AT1G01010' RETURN n",
        {},
        setExpressionData
      );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        getGeneDetails();
        break;
      case 1:
        getSequenceData();
        break;
      case 2:
        getGeneFamilyData();
        break;
      case 3:
        getFunctionalData();
        break;
      case 4:
        getExpressionData();
        break;
    }
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Basic Information" {...a11yProps(0)} />
          <Tab label="Sequence" {...a11yProps(1)} />
          <Tab label="Gene Family" {...a11yProps(2)} />
          <Tab label="Functional" {...a11yProps(3)} />
          <Tab label="Expression" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <BasicDetails geneDetails={basicData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SequenceInformation
          sequenceData={{
            proteinSequence: proteinSequence,
            genomicSequence: genomicSequence,
            cdsSequence: cdsSequence,
            transcriptSequence: transcriptSequence,
          }}
          queryCallback={props?.queryCallback}
          dimentions={props?.dimensions}
          colorData={sequenceColorData}
          selectedGene={props?.settings?.selectedGene}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <GeneFamilyData
          familyData={geneFamilyData}
          containerHeight={props?.dimensions?.height}
          containerWidth={props?.dimensions?.width}
          queryCallback={props?.queryCallback}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <FunctionalInformation
          geneOntologyData={geneOntologyData}
          genePfamData={genePfamData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        Expression
      </CustomTabPanel>
    </Box>
  );
};

export default GeneDetails;
