export const createLinks = (value) => {
    const valueTrimmed =  value.split('|')[0];
    if (valueTrimmed.substring(0, 3) === "PMC") {
        return `https://www.ncbi.nlm.nih.gov/pmc/articles/${valueTrimmed}`
    }
    else if (valueTrimmed.substring(0, 2) === "PM") {
        return `https://pubmed.ncbi.nlm.nih.gov/${valueTrimmed.slice(2)}`
    }
    else if (valueTrimmed.substring(0, 2) === "10") {
        return `https://doi.org/${valueTrimmed}`
    }
    else if (valueTrimmed.substring(0, 1) === "S") {
        return `https://www.sciencedirect.com/science/article/pii/${valueTrimmed}`
    }
    else {
        return `https://www.google.com/search?q=${valueTrimmed}`
    }
}

export const linksArray = ['Publication'];