import React, { useState } from "react";
import { connect } from "react-redux";
import { addReportThunk } from "../page/PageThunks";
import { getReports } from "../page/PageSelectors";
import { Card, CardContent, Typography, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

/**
 * Button to add a new report to the current page.
 */
const NeoAddNewCard = ({ onCreatePressed }) => {
  return (
    <div>
      <Card style={{ background: "#e0e0e0" }}>
        <CardContent style={{ height: "429px" }}>
          <Typography
            variant="h2"
            color="textSecondary"
            style={{ paddingTop: "155px", textAlign: "center" }}
          >
            <Fab
              size="medium"
              className={"blue-grey"}
              aria-label="add"
              onClick={() => {
                onCreatePressed();
              }}
            >
              <AddIcon />
            </Fab>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NeoAddNewCard);
