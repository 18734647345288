import React, { useState, useCallback, useEffect, useContext } from "react";
import useDimensions from "react-cool-dimensions";
import { categoricalColorSchemes } from "../config/ColorConfig";
import { ChartProps } from "./Chart";
import {
  valueIsArray,
  valueIsNode,
  valueIsRelationship,
  valueIsPath,
} from "../report/ReportRecordProcessing";
import ZoomInDisabledIcon from "@mui/icons-material/SearchOff";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { Tooltip } from "@material-ui/core";
import {
  evaluateRulesOnObject,
  evaluateRulesOnNode,
} from "../report/ReportRuleEvaluator";
import { getDifferentialColor } from "../helpers/differentialColor";
import RuleStyleLegend from "../component/RuleStyleLegend/RuleStyleLegend";
import Cytoscape from "cytoscape";
import cola from "cytoscape-cola";
import coseBilkent from "cytoscape-cose-bilkent";
import CytoscapeComponent from "react-cytoscapejs";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Typography, FormControl, Stack, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import cytoscapeContextMenus from "cytoscape-context-menus";
import { useSelector, useDispatch } from "react-redux";
import "cytoscape-context-menus/cytoscape-context-menus.css";
import {
  saveSelectedGenieListThunk,
  updateGenieListThunk,
} from "../dashboard/DashboardThunks";
import { Neo4jContext, Neo4jContextState } from "use-neo4j/dist/neo4j.context";
import svg from "cytoscape-svg";
import saveAs from "file-saver";
import { pnormN, qnorm } from "./util/Calculations";

Cytoscape.use(coseBilkent);
Cytoscape.use(cola);
cytoscapeContextMenus(Cytoscape);
Cytoscape.use(svg);

const EXNET_SLIDER_VALUE = "plantgenie-slider-value";

const GRAPH_LAYOUTS = [
  {
    id: 1,
    key: "cola",
    label: "cola",
  },
  {
    id: 2,
    key: "random",
    label: "random",
  },
  {
    id: 3,
    key: "cose-bilkent",
    label: "cose-bilkent",
  },
];

const STANDARD_LAYOUT = {
  name: "cola",
  animate: true, // whether to show the layout as it's running
  refresh: 1, // number of ticks per frame; higher is faster but more jerky
  maxSimulationTime: 6000, // max length in ms to run the layout
  ungrabifyWhileSimulating: false, // so you can't drag nodes during layout
  fit: true, // on every layout reposition of nodes, fit the viewport
  padding: 10, // padding around the simulation
  boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
  nodeDimensionsIncludeLabels: false, // whether labels should be included in determining the space used by a node
  // layout event callbacks
  ready: function () {},
  stop: function () {}, // on layoutstop

  // positioning options
  randomize: true, // use random node positions at beginning of layout
  avoidOverlap: true, // if true, prevents overlap of node bounding boxes
  handleDisconnected: true, // if true, avoids disconnected components from overlapping
  convergenceThreshold: 0.01, // when the alpha value (system energy) falls below this value, the layout stops
  nodeSpacing: function (node) {
    return 10;
  }, // extra spacing around nodes
  flow: undefined, // use DAG/tree flow layout if specified, e.g. { axis: 'y', minSeparation: 30 }
  alignment: undefined, // relative alignment constraints on nodes, e.g. {vertical: [[{node: node1, offset: 0}, {node: node2, offset: 5}]], horizontal: [[{node: node3}, {node: node4}], [{node: node5}, {node: node6}]]}
  gapInequalities: undefined, // list of inequality constraints for the gap between the nodes, e.g. [{"axis":"y", "left":node1, "right":node2, "gap":25}]
  centerGraph: true, // adjusts the node positions initially to center the graph (pass false if you want to start the layout from the current position)

  // different methods of specifying edge length
  // each can be a constant numerical value or a function like `function( edge ){ return 2; }`
  edgeLength: undefined, // sets edge length directly in simulation
  edgeSymDiffLength: undefined, // symmetric diff edge length in simulation
  edgeJaccardLength: undefined, // jaccard edge length in simulation

  // iterations of cola algorithm; uses default values on undefined
  unconstrIter: undefined, // unconstrained initial layout iterations
  userConstIter: undefined, // initial layout iterations with user-specified constraints
  allConstIter: undefined,
};

const update = (state, mutations) => Object.assign({}, state, mutations);

const NeoGraphChartCytoscapeJS = (props: ChartProps) => {
  const backgroundColor =
    props.settings && props.settings.backgroundColor
      ? props.settings.backgroundColor
      : "#fafafa";
  const nodeSizeProp =
    props.settings && props.settings.nodeSizeProp
      ? props.settings.nodeSizeProp
      : "size";
  const nodeColorProp =
    props.settings && props.settings.nodeColorProp
      ? props.settings.nodeColorProp
      : "color";
  const defaultNodeSize =
    props.settings && props.settings.defaultNodeSize
      ? props.settings.defaultNodeSize
      : 2;
  const relWidthProp =
    props.settings && props.settings.relWidthProp
      ? props.settings.relWidthProp
      : "width";
  const relColorProp =
    props.settings && props.settings.relColorProp
      ? props.settings.relColorProp
      : "color";
  const defaultRelWidth =
    props.settings && props.settings.defaultRelWidth
      ? props.settings.defaultRelWidth
      : 1;
  const defaultRelColor =
    props.settings && props.settings.defaultRelColor
      ? props.settings.defaultRelColor
      : "#a0a0a0";
  const nodeLabelColor =
    props.settings && props.settings.nodeLabelColor
      ? props.settings.nodeLabelColor
      : "black";
  const styleRules =
    props.settings && props.settings.styleRules
      ? props.settings.styleRules
      : [];
  const nodeColorScheme =
    props.settings && props.settings.nodeColorScheme
      ? props.settings.nodeColorScheme
      : "neodash";
  const showPropertiesOnHover =
    props.settings && props.settings.showPropertiesOnHover !== undefined
      ? props.settings.showPropertiesOnHover
      : true;
  const showPropertiesOnClick =
    props.settings && props.settings.showPropertiesOnClick !== undefined
      ? props.settings.showPropertiesOnClick
      : true;
  const defaultNodeColor = "lightgrey"; // Color of nodes without labels

  // Create the dictionary used for storing the memory of dragged node positions.
  if (props?.settings && props.settings.nodePositions == undefined) {
    props.settings.nodePositions = {};
  }
  const nodePositions = props.settings && props.settings.nodePositions;

  // Dictionaries to populate based on query results.
  let nodes = {};
  let nodeLabels = {};
  let links = {};

  const globalParameter =
    props.settings?.globalParameter ?? "neodash_slider_value_1";
  const [open, setOpen] = React.useState(false);
  const [inspectItem, setInspectItem] = useState({});
  const [selectedValue, setSelectedValue] = useState<any>(0);
  const [graphLayout, setGraphLayout] = useState("cola");
  const [layout, setLayout] = useState<any>(STANDARD_LAYOUT);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [sliderValue, setSliderValue] = useState(0);
  const [testCount, setTestCount] = useState(0);
  const [zoomFactor, setZoomFactor] = useState(2);
  const [showNodeLabels, setShowNodeLabels] = useState(true);
  const [disableZoomIn, setDisableZoomIn] = useState(true);
  const [extraRecords, setExtraRecords] = useState([]);
  const [activeExperimentMin, setActiveExperimentMin] = useState<any>(0);
  const [activeExperimentMax, setActiveExperimentMax] = useState<any>(0);

  const [frozen, setFrozen] = useState(
    props.settings && props.settings.frozen !== undefined
      ? props.settings.frozen
      : false
  );
  const [widthCJS, setWith] = useState("100%");
  const [heightCJS, setHeight] = useState("100%");
  const [graphData2, setGraphData] = useState<any>({
    nodesTmp: [],
    edgesTmp: [],
  });

  const dispatch = useDispatch();
  const applicationState = useSelector((state) => state?.application);
  const dashboardState = useSelector((state) => state?.dashboard);
  const { driver } = useContext<Neo4jContextState>(Neo4jContext);
  const addedGenieList = useSelector(
    (state) => state?.dashboard?.addedGenieLists
  );
  const activeGeneList = addedGenieList.find((item) => item?.active_genelist);
  const activeExperiment =
    dashboardState?.settings?.parameters?.neodash_experiment_value;

  useEffect(() => {
    const localSliderValue = localStorage.getItem(EXNET_SLIDER_VALUE);
    if (localSliderValue && activeExperimentMin && activeExperimentMax) {
      setSliderValue(parseInt(localSliderValue));
      const sliderLength = activeExperimentMax - activeExperimentMin;
      const mappedValue =
        sliderLength * (parseInt(localSliderValue) / 100) + activeExperimentMin;
      setSelectedValue(mappedValue.toFixed(2));
    } else if (activeExperimentMin && activeExperimentMax) {
      setSliderValue(50);
      const sliderLength = activeExperimentMax - activeExperimentMin;
      const mappedValue = sliderLength * 0.5 + activeExperimentMin;
      setSelectedValue(mappedValue.toFixed(2));
    }
  }, [applicationState, activeExperimentMin, activeExperimentMax]);

  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });

  let myCyRef;

  const prepapreGraphData = (data) => {
    const nodesTmp: any = [];
    for (let i = 0; i < data.nodes.length; i++) {
      const minnodessTmp: any = {};
      const minPosition: any = {};
      minnodessTmp.id = data.nodes[i].id;
      minnodessTmp.color = data.nodes[i].color;
      minnodessTmp.width = data.nodes[i].size * 16;

      const selectedProp =
        props.selection && props.selection[data.nodes[i].lastLabel];

      minnodessTmp.label = data.nodes[i].properties[selectedProp]
        ? data.nodes[i].properties[selectedProp]
        : "";
      minnodessTmp.type = data.nodes[i].labels[0];
      minPosition.x = data.nodes[i].x;
      minPosition.y = data.nodes[i].y;
      nodesTmp.push({ data: minnodessTmp, position: minPosition });
    }

    const edgesTmp: any = [];
    for (let j = 0; j < data.links.length; j++) {
      const minedgesTmp: any = {};
      minedgesTmp.label = "\u2060\n\n" + data.links[j].type;
      minedgesTmp.color = data.links[j].color;
      minedgesTmp.width = data.links[j].width;
      minedgesTmp.source = data.links[j].source;
      minedgesTmp.target = data.links[j].target;
      minedgesTmp.id = data.links[j].id;
      edgesTmp.push({ data: minedgesTmp });
    }
    const filteredEdges = edgesTmp.filter((item) => {
      const source = item.data.source;
      const target = item.data.target;
      const sourceNode = nodesTmp.find((node) => node.data.id == source);
      const targetNode = nodesTmp.find((node) => node.data.id == target);
      if (sourceNode && targetNode) {
        return true;
      }
      return false;
    });
    const sumObj = { nodes: nodesTmp, edges: filteredEdges };
    setGraphData(sumObj);
  };

  useEffect(() => {
    if (graphData2) {
      // myCyRef.layout(layout).run();
    }
  }, [graphData2]);

  useEffect(() => {
    if (
      props.records == null ||
      props.records.length == 0 ||
      props.records[0].keys == null
    ) {
      return;
    }
    prepapreGraphData(buildVisualizationDictionaryFromRecords(props.records));
  }, [props]);

  useEffect(() => {
    if (
      props.records == null ||
      props.records.length == 0 ||
      props.records[0].keys == null
    ) {
      return;
    }
    if (myCyRef) {
      myCyRef.on("cxtap", "node", (event) => {
        handleExpand(event);
      });
      myCyRef.on("tap", "node", (event) => {
        const node = event.target;
        showPopup(node.data());
        setInspectItem(node.data());
      });
      myCyRef.on("mouseover", "node, edge", (event) => {
        if (showPropertiesOnHover) {
          const element = event.target;
          setHoveredElement(element);
        }
      });

      myCyRef.on("mouseout", "node, edge", () => {
        setHoveredElement(null);
      });
    }

    if (extraRecords) {
      const allRecords = [...props.records, ...extraRecords];
      prepapreGraphData(buildVisualizationDictionaryFromRecords(allRecords));
    } else {
      prepapreGraphData(buildVisualizationDictionaryFromRecords(props.records));
    }
    setTimeout(() => {
      myCyRef.layout(layout).run();
    }, 1000);
  }, [extraRecords, myCyRef]);

  const handleExpand = useCallback((node) => {
    console.log("This expand method called");
    props.queryCallback &&
      props.queryCallback(
        "MATCH (n)-[e]-(m) WHERE id(n) =" + node.id + " RETURN e,m",
        {},
        setExtraRecords
      );
  }, []);

  const showPopup = useCallback((item) => {
    if (showPropertiesOnClick) {
      setInspectItem(item);
      handleOpen();
    }
  }, []);

  const updateTheGlobalParameter = () => {
    if (!globalParameter) {
      alert("Set the global parameter first");
      return;
    }
    const sliderLength = activeExperimentMax - activeExperimentMin;
    const mappedValue =
      sliderLength * (sliderValue / 100) + activeExperimentMin;
    const calculatedValue = Math.abs(
      qnorm(1 / Math.pow(10, Math.abs(mappedValue)))
    );
    props?.setGlobalParameter(globalParameter, calculatedValue);
  };

  const getMinMaxValues = (experiment) => {
    props?.queryCallback &&
      props.queryCallback(
        "match path=(n:Gene)-[e:" +
          activeExperiment.toUpperCase() +
          "]->(m:Gene) return max(e.corr)",
        {},
        (records) => {
          if (records[0]._fields[0]) {
            setActiveExperimentMin(
              parseInt(Math.log10(pnormN(records[0]._fields[0])).toFixed(0))
            );
          } else {
            // Null value received
          }
        }
      );

    props?.queryCallback &&
      props.queryCallback(
        "match path=(n:Gene)-[e:" +
          activeExperiment.toUpperCase() +
          "]->(m:Gene) return min(e.corr)",
        {},
        (records) => {
          if (records[0]._fields[0]) {
            setActiveExperimentMax(
              parseInt(Math.log10(pnormN(records[0]._fields[0])).toFixed(0))
            );
          }
        }
      );
  };

  useEffect(() => {
    getMinMaxValues(activeExperiment);
  }, [activeExperiment]);

  const handleChangeSliderValue = (event, value) => {
    localStorage.setItem(EXNET_SLIDER_VALUE, value);
    setSliderValue(value);
    const sliderLength = activeExperimentMax - activeExperimentMin;
    const mappedValue = sliderLength * (value / 100) + activeExperimentMin;
    setSelectedValue(mappedValue.toFixed(2));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangeLayout = (event) => {
    setGraphLayout(event.target.value);
    setLayout({
      ...STANDARD_LAYOUT,
      name: event.target.value,
    });
    myCyRef
      .layout({
        ...STANDARD_LAYOUT,
        name: event.target.value,
      })
      .run();
  };

  const handleSelecteConnectedNodes = (node) => {
    if (myCyRef) {
      const nodes = myCyRef.elements("node:selected");
      const neighbour = nodes.neighborhood();
      neighbour.select();
    }
  };

  const addGeneToList = (gene) => {
    const activeGenieList = addedGenieList.find(
      (item) => item?.active_genelist
    );

    const newList = activeGenieList.genelist + "," + gene.label;
    dispatch(
      updateGenieListThunk(
        driver,
        applicationState?.connection?.database,
        activeGenieList?.id,
        newList,
        newList.split(",").length
      )
    );
  };

  const createNewGenieList = (gene) => {
    const listName = prompt("Please enter list name", "New List");
    dispatch(
      saveSelectedGenieListThunk(
        applicationState?.connection?.username,
        gene.label,
        name,
        applicationState?.connection?.database
      )
    );
  };

  const contextMenuOptions = {
    menuItems: [
      {
        id: "remove",
        content: "remove",
        selector: "node, edge",
        show: false,
        onClickFunction: function (event) {
          console.log("remove", event);
          // var target = event.target || event.cyTarget;
          // removed = target.remove();
          // contextMenu.showMenuItem("undo-last-remove");
        },
        hasTrailingDivider: true,
      },
      {
        id: "undo-last-remove",
        content: "undo last remove",
        selector: "node, edge",
        show: false,
        coreAsWell: true,
        onClickFunction: function (event) {
          // if (removed) {
          //   removed.restore();
          // }
          // contextMenu.hideMenuItem("undo-last-remove");
        },
        hasTrailingDivider: true,
      },
      {
        id: "hide",
        content: "hide",
        selector: "*",
        show: false,
        onClickFunction: function (event) {
          // var target = event.target || event.cyTarget;
          // target.hide();
        },
        disabled: false,
      },
      {
        id: "expand-genes-nodes",
        content: "Expand genes",
        selector: "node",
        show: true,
        onClickFunction: function (event) {
          handleExpand(event.target._private.data);
        },
      },
      {
        id: "select-connected-nodes",
        content: "Select connected nodes",
        selector: "node",
        show: true,
        onClickFunction: function (event) {
          handleSelecteConnectedNodes(event.target._private.data);
          // select_one_connected_nodes();
          //contextMenu.showMenuItem('select-all-nodes');
          //contextMenu.hideMenuItem('unselect-all-nodes');
        },
      },

      {
        id: "add-gene-to-list",
        content: "Add gene to active list",
        selector: "node",
        show: true,
        onClickFunction: function (event) {
          addGeneToList(event.target._private.data);
        },
      },
      {
        id: "create-new-list",
        content: "Create a new list",
        selector: "node",
        show: true,
        onClickFunction: function (event) {
          createNewGenieList(event.target._private.data);
        },
      },
      {
        id: "select-all-nodes-1",
        content: "Select All",
        coreAsWell: false,
        onClickFunction: function (event) {
          // exNet.nodes().select();
        },
      },
    ],
  };

  const getRelationshipColor = (value) => {
    const ruleColor = evaluateRulesOnObject(
      value,
      "relationship arrow color",
      styleRules
    );
    if (ruleColor) {
      return ruleColor;
    }

    return value.properties[relColorProp]
      ? getDifferentialColor({
          currentValue: value.properties[relColorProp],
          minExpressionObject: { color: "#0000ff", value: 10 },
          midExpressionObject: { color: "#ffffff", value: 0 },
          maxExpressionObject: { color: "#ff0000", value: 10 },
        })
      : defaultRelColor;
  };

  // Gets all graphy objects (nodes/relationships) from the complete set of return values.
  function extractGraphEntitiesFromField(value) {
    if (value == undefined) {
      return;
    }
    if (valueIsArray(value)) {
      value.forEach((v, i) => extractGraphEntitiesFromField(v));
    } else if (valueIsNode(value)) {
      value.labels.forEach((l) => (nodeLabels[l] = true));
      nodes[value.identity.low] = {
        id: value.identity.low,
        labels: value.labels,
        size: value.properties[nodeSizeProp]
          ? value.properties[nodeSizeProp]
          : defaultNodeSize,
        properties: value.properties,
        lastLabel: value.labels[value.labels.length - 1],
      };
      if (frozen && nodePositions && nodePositions[value.identity.low]) {
        nodes[value.identity.low]["fx"] = nodePositions[value.identity.low][0];
        nodes[value.identity.low]["fy"] = nodePositions[value.identity.low][1];
      }
    } else if (valueIsRelationship(value)) {
      if (links[value.start.low + "," + value.end.low] == undefined) {
        links[value.start.low + "," + value.end.low] = [];
      }
      const addItem = (arr, item) =>
        arr.find((x) => x.id === item.id) || arr.push(item);
      addItem(links[value.start.low + "," + value.end.low], {
        id: value.identity.low,
        source: value.start.low,
        target: value.end.low,
        type: value.type,
        width: value.properties[relWidthProp]
          ? value.properties[relWidthProp]
          : defaultRelWidth,
        color: getRelationshipColor(value),
        properties: value.properties,
      });
    } else if (valueIsPath(value)) {
      value.segments.map((segment, i) => {
        extractGraphEntitiesFromField(segment.start);
        extractGraphEntitiesFromField(segment.relationship);
        extractGraphEntitiesFromField(segment.end);
      });
    }
  }

  // Function to manually compute curvatures for dense node pairs.
  function getCurvature(index, total) {
    if (total <= 6) {
      // Precomputed edge curvatures for nodes with multiple edges in between.
      const curvatures = {
        0: 0,
        1: 0,
        2: [-0.5, 0.5], // 2 = Math.floor(1/2) + 1
        3: [-0.5, 0, 0.5], // 2 = Math.floor(3/2) + 1
        4: [-0.66666, -0.33333, 0.33333, 0.66666], // 3 = Math.floor(4/2) + 1
        5: [-0.66666, -0.33333, 0, 0.33333, 0.66666], // 3 = Math.floor(5/2) + 1
        6: [-0.75, -0.5, -0.25, 0.25, 0.5, 0.75], // 4 = Math.floor(6/2) + 1
        7: [-0.75, -0.5, -0.25, 0, 0.25, 0.5, 0.75], // 4 = Math.floor(7/2) + 1
      };
      return curvatures[total][index];
    }
    const arr1 = [...Array(Math.floor(total / 2)).keys()].map((i) => {
      return (i + 1) / (Math.floor(total / 2) + 1);
    });
    const arr2 = total % 2 == 1 ? [0] : [];
    const arr3 = [...Array(Math.floor(total / 2)).keys()].map((i) => {
      return (i + 1) / -(Math.floor(total / 2) + 1);
    });
    return arr1.concat(arr2).concat(arr3)[index];
  }

  function buildVisualizationDictionaryFromRecords(records) {
    // Extract graph objects from result set.
    records.forEach((record, rownumber) => {
      record._fields.forEach((field, i) => {
        extractGraphEntitiesFromField(field);
      });
    });
    // Assign proper curvatures to relationships.
    // This is needed for pairs of nodes that have multiple relationships between them, or self-loops.
    const linksList = Object.values(links).map((nodePair: any) => {
      return nodePair.map((link, i) => {
        if (link.source == link.target) {
          // Self-loop
          return update(link, { curvature: 0.4 + i / 8 });
        } else {
          // If we also have edges from the target to the source, adjust curvatures accordingly.
          const mirroredNodePair = links[link.target + "," + link.source];
          if (!mirroredNodePair) {
            return update(link, {
              curvature: getCurvature(i, nodePair.length),
            });
          } else {
            return update(link, {
              curvature:
                (link.source > link.target ? 1 : -1) *
                getCurvature(
                  link.source > link.target ? i : i + mirroredNodePair.length,
                  nodePair.length + mirroredNodePair.length
                ),
            });
          }
        }
      });
    });

    // Assign proper colors to nodes.
    const totalColors = categoricalColorSchemes[nodeColorScheme]
      ? categoricalColorSchemes[nodeColorScheme].length
      : 0;
    const nodeLabelsList = Object.keys(nodeLabels);
    const nodesList = Object.values(nodes).map((node: any) => {
      // First try to assign a node a color if it has a property specifying the color.
      var assignedColor = node.properties[nodeColorProp]
        ? node.properties[nodeColorProp]
        : totalColors > 0
        ? categoricalColorSchemes[nodeColorScheme][
            nodeLabelsList.indexOf(node.lastLabel) % totalColors
          ]
        : "grey";
      // Next, evaluate the custom styling rules to see if there's a rule-based override
      assignedColor = evaluateRulesOnNode(
        node,
        "node color",
        assignedColor,
        styleRules
      );
      return update(node, {
        color: assignedColor ? assignedColor : defaultNodeColor,
      });
    });
    const finalData = {
      nodes: nodesList,
      links: linksList.flat(),
    };
    return finalData;
  }

  const styleSheet = [
    {
      selector: "node",
      style: {
        backgroundColor: "data(color)",
        width: "data(width)",
        height: "data(width)",
        label: showNodeLabels ? "data(label)" : "",
        "text-valign": "center",
        "text-halign": "center",
        "z-index": "20",
        "text-outline-color": "#ffffff",
        "text-outline-width": "0.1px",
        color: nodeLabelColor,
        fontSize: 6,
      },
    },
    {
      selector: "node:selected",
      style: {
        "border-width": "1px",
        "border-color": "#ff0000",
      },
    },
    {
      selector: "node[type='device']",
      style: {
        shape: "rectangle",
      },
    },
    {
      selector: "edge",
      style: {
        width: "data(width)",
        label: "data(label)",
        color: "data(color)",
        "font-size": 6,
        "line-color": "data(color)",
        "target-arrow-color": "data(color)",
        "target-arrow-shape": "triangle",
        "curve-style": "bezier",
        "text-rotation": "autorotate",
        "text-background-opacity": 0,
      },
    },
    {
      selector: "edge:selected",
      style: {
        width: "1px",
        "line-color": "#ff0000",
        "target-arrow-color": "#ff0000",
      },
    },
  ];

  const downloadAsSvg = () => {
    const svgContent = myCyRef.svg({ scale: 1, full: true, bg: "transparent" });
    const blob = new Blob([svgContent], {
      type: "image/svg+xml;charset=utf-8",
    });
    saveAs(blob, "graph.svg");
  };

  const hideLabelsOfNetwork = () => {
    setShowNodeLabels(!showNodeLabels);
  };

  const handleDisableZoom = () => {
    setDisableZoomIn(!disableZoomIn);
  };

  const colorNodesByGeneList = () => {
    const nodes = myCyRef.elements("node");
    const genes = activeGeneList?.genelist.split(",");
    nodes.forEach((node) => {
      const index = genes.findIndex((item) => item === node.data("label"));
      if (index >= 0) {
        node.data("color", "#ff0000");
      }
    });
  };

  if (
    props.records == null ||
    props.records.length == 0 ||
    props.records[0].keys == null
  ) {
    return (
      <>
        <div
          style={{
            paddingLeft: "10px",
            position: "relative",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              zIndex: 5,
            }}
          ></div>

          <Box
            sx={{
              height: props?.dimensions ? props.dimensions?.height - 300 : 500,
            }}
          >
            <Box
              sx={{
                height: 500,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>No data found</Typography>
            </Box>
          </Box>
          <Box sx={{ ml: 5, mb: 2, mt: 5, height: 300 }}>
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              spacing={2}
            >
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="graph-layout-label">Graph Layout</InputLabel>
                  <Select
                    labelId="graph-layout-label"
                    id="demo-simple-select"
                    value={graphLayout}
                    label="Graph Layout"
                    disabled
                    size="small"
                  >
                    {GRAPH_LAYOUTS.map((layout) => (
                      <MenuItem value={layout.key}>{layout.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Button variant="contained" disabled>
                Export as SVG
              </Button>
              <Box sx={{ width: 150 }}>
                <Typography variant="body2">
                  {" P value: 10"}
                  <sup>{selectedValue}</sup>
                </Typography>
              </Box>
              <Box sx={{ width: 300 }}>
                <Slider
                  size="medium"
                  value={sliderValue}
                  sx={{ ml: 5 }}
                  marks={[
                    {
                      value: 0,
                      label: (
                        <Typography>
                          Min 10<sup>{activeExperimentMin}</sup>
                        </Typography>
                      ),
                    },
                    {
                      value: 100,
                      label: (
                        <Typography>
                          Max 10<sup>{activeExperimentMax}</sup>
                        </Typography>
                      ),
                    },
                  ]}
                  onChange={handleChangeSliderValue}
                  onChangeCommitted={updateTheGlobalParameter}
                />
              </Box>
            </Stack>
          </Box>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        ref={observe}
        style={{
          paddingLeft: "10px",
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            zIndex: 5,
          }}
        >
          <Tooltip
            title={
              disableZoomIn ? "Toggle enable zoom." : "Toggle disable zoom."
            }
          >
            {disableZoomIn ? (
              <ZoomInIcon
                onClick={() => setDisableZoomIn(false)}
                style={{ fontSize: "1.3rem", opacity: 0.6 }}
                color="disabled"
                fontSize="small"
              />
            ) : (
              <ZoomInDisabledIcon
                onClick={() => setDisableZoomIn(true)}
                style={{ fontSize: "1.3rem", opacity: 0.6 }}
                color="disabled"
                fontSize="small"
              />
            )}
          </Tooltip>
        </div>
        {styleRules?.length > 0 && (
          <RuleStyleLegend
            rules={styleRules}
            backgroundheadercolor={backgroundColor}
          />
        )}
        <Box
          sx={{
            height: props?.dimensions ? props.dimensions?.height - 300 : 500,
          }}
        >
          <Box>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                size="small"
                disabled={!disableZoomIn}
                onClick={() => {
                  setZoomFactor(zoomFactor + 0.5);
                }}
              >
                Zoom In
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={!disableZoomIn}
                onClick={() => {
                  setZoomFactor(zoomFactor - 0.5);
                }}
              >
                Zoom Out
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={hideLabelsOfNetwork}
              >
                {showNodeLabels ? "Hide Labels" : "Show Labels"}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleDisableZoom}
              >
                {disableZoomIn ? "Zoom Disable" : "Zoom Enable"}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={colorNodesByGeneList}
              >
                Show color by GeneList
              </Button>
            </Stack>
          </Box>

          <CytoscapeComponent
            cy={(cy: any) => {
              // Register the context menu
              if (cy && !cy.contextMenusRegistered) {
                cy.contextMenusRegistered = true; // Ensure we only register it once
                cy.contextMenus(contextMenuOptions);
              }
              if (testCount < 1) {
                setTestCount(testCount + 1);
                setTimeout(() => {
                  cy.layout(STANDARD_LAYOUT).run();
                }, 1000);
              }

              myCyRef = cy;
            }}
            elements={CytoscapeComponent.normalizeElements(graphData2)}
            style={{ width: widthCJS, height: heightCJS }}
            zoomingEnabled={disableZoomIn}
            maxZoom={3}
            minZoom={0.1}
            zoom={zoomFactor}
            autounselectify={false}
            boxSelectionEnabled={true}
            //contextMenus={ contextMenus(options)}
            stylesheet={styleSheet}
            panningEnabled={disableZoomIn}
          />
        </Box>
        <Box sx={{ ml: 5, mb: 2, mt: 5, height: 300 }}>
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={2}
          >
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="graph-layout-label">Graph Layout</InputLabel>
                <Select
                  labelId="graph-layout-label"
                  id="demo-simple-select"
                  value={graphLayout}
                  label="Graph Layout"
                  onChange={handleChangeLayout}
                  size="small"
                >
                  {GRAPH_LAYOUTS.map((layout) => (
                    <MenuItem value={layout.key}>{layout.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button variant="contained" onClick={downloadAsSvg}>
              Export as SVG
            </Button>
            <Box sx={{ width: 150 }}>
              <Typography variant="body2">
                {" P value: 10"}
                <sup>{selectedValue}</sup>
              </Typography>
            </Box>
            <Box sx={{ width: 300 }}>
              <Slider
                size="medium"
                value={sliderValue}
                sx={{ ml: 5 }}
                marks={[
                  {
                    value: 0,
                    label: (
                      <Typography>
                        Min 10<sup>{activeExperimentMin}</sup>
                      </Typography>
                    ),
                  },
                  {
                    value: 100,
                    label: (
                      <Typography>
                        Max 10<sup>{activeExperimentMax}</sup>
                      </Typography>
                    ),
                  },
                ]}
                onChange={handleChangeSliderValue}
                onChangeCommitted={updateTheGlobalParameter}
              />
            </Box>
          </Stack>
        </Box>
      </div>
    </>
  );
};

export default NeoGraphChartCytoscapeJS;
