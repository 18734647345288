import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Badge, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Configures setting the current Neo4j database connection for the dashboard.
 */
export const NeoReportHelpModal = ({ open, handleClose }) => {
  return (
    <Dialog
      maxWidth={"lg"}
      open={open == true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        About Reports
        <IconButton
          onClick={handleClose}
          style={{ padding: "3px", float: "right" }}
        >
          <Badge badgeContent={""}>
            <CloseIcon />
          </Badge>
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: "1000px" }}>
        <DialogContentText>
          {" "}
          A report is the smallest building block of your dashboard. Each report
          runs a single Cypher query that loads data from your database. By
          changing the report type, different visualizations can be created for
          the data. See the{" "}
          <a href="https://github.com/neo4j-labs/neodash/wiki/Reports">
            Documentation
          </a>{" "}
          for more on reports.
          <br></br>
          <br></br>
          <table>
            <tr>
              <td>
                <b>Moving Reports</b>
                <img src="movereport.gif" style={{ width: "100%" }}></img>
              </td>
              <td>
                <b>Resizing Reports</b>
                <img src="resizereport.gif" style={{ width: "100%" }}></img>
              </td>
            </tr>
          </table>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default NeoReportHelpModal;
