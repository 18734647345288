import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import GeneFamilyRowComponent from "../GeneFamilySubComponent";
import saveAs from "file-saver";

export default function GeneFamilyData(props) {
  const { familyData, containerHeight, containerWidth, queryCallback } = props;
  const [showAllgenes, setShowAllGenes] = useState(false);
  const [selectedGenes, setSelectedGenes] = useState<any>([]);
  const [downloadData, setDownloadData] = useState<any>([]);
  const [enableDownloadData, setEnableDownloadData] = useState(false);

  const downloadDataFile = () => {
    const blobData = downloadData.map((item) => {
      return ">" + item.gene_id + "\n" + item.sequence;
    });
    const blobString = blobData.join("\n");
    const file = new Blob([blobString], { type: "fasta/plain" });
    saveAs(file, "family_data.fasta");
  };

  const getSequenceData = async (genes: any) => {
    let selectedGenes: any = [];
    genes.forEach((element) => {
      const temp = element.split(";");
      temp.forEach((tempElement) => {
        selectedGenes.push("'" + tempElement + "'");
      });
    });
    const query = `match (n:GeneFamilySequence) where n.gene_id in [${selectedGenes}] return n.sequence, n.gene_id`;
    return new Promise((resolve, reject) => {
      queryCallback(query, "", (data) => {
        resolve(data);
      });
    });
  };

  const preprocessGenes = (genes) => {
    const data: any = [];
    genes.forEach((element) => {
      const gene = {
        gene_id: element._fields[1],
        sequence: element._fields[0],
      };
      data.push(gene);
    });
    return data;
  };

  const onClickGene = (gene: any, checked: boolean) => {
    if (checked) {
      if (selectedGenes.includes(gene)) {
        return;
      }
      setSelectedGenes([...selectedGenes, gene]);
    } else {
      setSelectedGenes(selectedGenes.filter((item) => item !== gene));
    }
  };

  const getGeneDataAndPreprocess = async (selectedGenes) => {
    const data = await getSequenceData(selectedGenes);
    const processedData = preprocessGenes(data);
    setDownloadData(processedData);
  };

  useEffect(() => {
    if (selectedGenes.length === 0) {
      setEnableDownloadData(false);
      return;
    }
    getGeneDataAndPreprocess(selectedGenes);
    setEnableDownloadData(true);
  }, [selectedGenes, showAllgenes]);

  return (
    <Box sx={{ maxHeight: containerHeight - 200, overflowY: "scroll" }}>
      <Stack
        direction="row"
        justifyContent={enableDownloadData ? "space-between" : "start"}
        sx={{ mb: 2 }}
      >
        {familyData && familyData?.length > 0 && (
          <Button
            sx={{ mb: 2 }}
            onClick={() => {
              if (showAllgenes) {
                setSelectedGenes([]);
              } else {
                const temp = familyData.map((item) => item.gf_genes);
                setSelectedGenes([...temp]);
              }
              setShowAllGenes(!showAllgenes);
            }}
            size="small"
          >
            <Typography variant="body2">
              {showAllgenes ? "Hide all Genes" : "Show all Genes"}
            </Typography>
          </Button>
        )}

        {enableDownloadData && (
          <Button onClick={downloadDataFile} variant="contained" size="small">
            <Typography variant="body2">Download Fasta File</Typography>
          </Button>
        )}
      </Stack>

      <Stack spacing={2}>
        {familyData.map((item, index) => {
          return (
            <GeneFamilyRowComponent
              geneData={item}
              key={index}
              containerWidth={containerWidth}
              showAllgenes={showAllgenes}
              onClickGene={onClickGene}
            />
          );
        })}
      </Stack>
    </Box>
  );
}
