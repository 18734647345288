import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

export const DataGridStyled = styled(DataGrid)`
    .MuiDataGrid-cell{
        white-space: normal !important;
        word-wrap: break-word !important;
        padding: 0.7rem !important;
    }
`;
