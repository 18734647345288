import React, { useState, useEffect } from "react";
import {
  createViewState,
  JBrowseLinearGenomeView,
  loadPlugins,
} from "@jbrowse/react-linear-genome-view";
import { assembly, tracks } from "../config/JbrowseConfig";
import { BLAST_CHROMOSOME_NAME, BLAST_JBROWSE_UUID } from "../constants";

const session = {
  name: "my session",
  view: {
    id: "linearGenomeView",
    type: "LinearGenomeView",
    tracks: [
      {
        type: "FeatureTrack",
        configuration: "potra_genes",
        displays: [
          {
            type: "LinearBasicDisplay",
            configuration: "potra_genes-LinearBasicDisplay",
          },
        ],
      },
      {
        type: "FeatureTrack",
        configuration: "BLASThits",
        displays: [
          {
            type: "LinearBasicDisplay",
            configuration: "potra_genes-LinearBasicDisplay",
          },
        ],
      },
    ],
  },
};

export default function Jbrowse() {
  const [viewState, setViewState] = useState<any>();

  useEffect(() => {
    loadPlugins([
      {
        name: "GWAS",
        url: "https://unpkg.com/jbrowse-plugin-gwas/dist/jbrowse-plugin-gwas.umd.production.min.js",
      },
    ]).then((loadedPlugins) => {
      const state = createViewState({
        assembly: assembly,
        tracks: tracks,
        defaultSession: session,
        plugins: loadedPlugins.map((p) => p.plugin),
        location: localStorage.getItem(BLAST_CHROMOSOME_NAME) ?? "chr1",
      });
      setViewState(state);
    });
  }, []);

  if (!viewState) {
    return <div />;
  }
  return <JBrowseLinearGenomeView viewState={viewState} />;
}
