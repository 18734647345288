export const RESET_DASHBOARD_STATE = "DASHBOARD/RESET_DASHBOARD_STATE";
export const resetDashboardState = () => ({
  type: RESET_DASHBOARD_STATE,
  payload: {},
});

export const SET_DASHBOARD = "DASHBOARD/SET_DASHBOARD";
export const setDashboard = (dashboard: any) => ({
  type: SET_DASHBOARD,
  payload: { dashboard },
});

export const SET_DASHBOARD_TITLE = "DASHBOARD/SET_DASHBOARD_TITLE";
export const setDashboardTitle = (title: any) => ({
  type: SET_DASHBOARD_TITLE,
  payload: { title },
});

export const CREATE_PAGE = "DASHBOARD/CREATE_PAGE";
export const addPage = () => ({
  type: CREATE_PAGE,
  payload: {},
});

export const CREATE_PAGE_WITH_DETAILS = "DASHBOARD/CREATE_PAGE_WITH_DETAILS";
export const addPageWithDetails = (page: any) => ({
  type: CREATE_PAGE_WITH_DETAILS,
  payload: { page },
});

export const REMOVE_PAGE = "DASHBOARD/REMOVE_PAGE";
export const removePage = (number: any) => ({
  type: REMOVE_PAGE,
  payload: { number },
});

export const MOVE_PAGE = "DASHBOARD/MOVE_PAGE";
export const movePage = (oldIndex: any, newIndex: any) => ({
  type: MOVE_PAGE,
  payload: { oldIndex, newIndex },
});

export const SELECT_GENIES = "SELECT_GENIES";
export const selectGenies = (data: any) => ({
  type: SELECT_GENIES,
  payload: data,
});

export const ADD_GENIE_LIST = "ADD_GENIE_LIST";
export const addGenieList = (data: any) => ({
  type: ADD_GENIE_LIST,
  payload: data,
});

export const SET_GENIE_LISTS = "SET_GENIE_LISTS";
export const setGenieLists = (data: any) => ({
  type: SET_GENIE_LISTS,
  payload: data,
});

export const UPDATE_GENIE_LIST_STATUS = "UPDATE_GENIE_LIST_UPDATE";
export const updateGenieListStatus = (data: any) => ({
  type: UPDATE_GENIE_LIST_STATUS,
  payload: data,
});

export const UPDATE_GENIE_LIST = "UPDATE_GENIE_LIST_UPDATE";
export const updateGenieList = (data: any) => ({
  type: UPDATE_GENIE_LIST_STATUS,
  payload: data,
});

export const DELETE_GENIE_LIST = "DELETE_GENIE_LIST";
export const deleteGenieList = (id: string) => ({
  type: DELETE_GENIE_LIST,
  payload: id,
});

export const UPDATE_GENIE_LISTS = "UPDATE_GENIE_LISTS";
export const updateGenieLists = (data: any) => ({
  type: UPDATE_GENIE_LISTS,
  payload: data,
});
