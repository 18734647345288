import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";

/**
 * Configures setting the current Neo4j database connection for the dashboard.
 */
export const NeoDeletePageModal = ({ modalOpen, onRemove, handleClose }) => {
  return (
    <Dialog
      maxWidth={"lg"}
      open={modalOpen == true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Delete page?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {" "}
          Are you sure you want to remove this page? This cannot be undone.
        </DialogContentText>
        <Button
          onClick={() => {
            onRemove();
            handleClose();
          }}
          style={{
            float: "right",
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "red",
          }}
          color="secondary"
          variant="contained"
          size="large"
          endIcon={<DeleteIcon color={"white"} />}
        >
          Remove
        </Button>
        <Button
          onClick={() => {
            handleClose();
          }}
          style={{
            float: "right",
            marginTop: "20px",
            marginRight: "6px",
            marginBottom: "20px",
          }}
          variant="contained"
          size="large"
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default NeoDeletePageModal;
