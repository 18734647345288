import domtoimage from "dom-to-image";
import JsPDF from "jspdf";

/**
 * Basic function to convert a table row output to a CSV file, and download it.
 * TODO: Make this more robust. Probably the commas should be escaped to ensure the CSV is always valid.
 */
export const downloadCSV = async (rows) => {
  const element = document.createElement("a");
  let csv = "";
  const headers = Object.keys(rows[0]).slice(1);
  csv += headers.join(", ") + "\n";
  rows.forEach((row) => {
    headers.forEach((header) => {
      // Parse value
      var value = row[header];
      if (value && value["low"]) {
        value = value["low"];
      }
      csv += JSON.stringify(value).replaceAll(",", ";");
      csv += headers.indexOf(header) < headers.length - 1 ? ", " : "";
    });
    csv += "\n";
  });

  // Function to save as files
  const file = new Blob([csv], { type: "text/plain" });
  if (window.showSaveFilePicker) {
    const handle = await showSaveFilePicker({
      suggestedName: "Table.tsv",
      types: [
        {
          description: "Text file",
          accept: {
            "text/plain": [".txt"],
          },
        },
      ],
    });
    const writable = await handle.createWritable();
    await writable.write(file);
    writable.close();
  } else {
    const saveFile = document.createElement("a");
    saveFile.style.display = "none";
    saveFile.href = URL.createObjectURL(file);
    saveFile.download = "table.tsv";
    document.body.appendChild(saveFile); // Required for this to work in FireFox
    saveFile.click();
    setTimeout(() => URL.revokeObjectURL(saveFile.href), 60000);
  }
};

/**
 * Replaces all global dashboard parameters inside a string with their values.
 * @param str The string to replace the parameters in.
 * @param parameters The parameters to replace.
 */
export function replaceDashboardParameters(str, parameters) {
  if (!str) return "";

  Object.keys(parameters).forEach((key) => {
    const rgx = new RegExp("\\$" + key + "\\b", "ig");
    str = str.replaceAll(rgx, parameters[key]);
  });

  return str;
}

/**
 * Downloads a screenshot of the element reference passed to it.
 * @param ref The reference to the element to download as an image.
 */
export const downloadComponentAsImage = async (ref, type, name) => {
  const element = ref.current;

  domtoimage.toPng(element, { bgcolor: "white" }).then(function (dataUrl) {
    var link = document.createElement("a");
    if (type === "pdf") {
      const pdf = new JsPDF();
      pdf.addImage(dataUrl, "JPEG", 0, 0);
      pdf.save(name + ".pdf");
    } else {
      link.download = name + "." + type;
      link.href = dataUrl;
      link.click();
    }
  });
};
