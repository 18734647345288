import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function BasicDetails(props) {
  const { geneDetails } = props;
  return (
    <TableBody>
      <TableRow>
        <TableCell>Name :</TableCell>
        <TableCell>{geneDetails?.name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Chromosome name :</TableCell>
        <TableCell>{geneDetails?.chr}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Start position :</TableCell>
        <TableCell>22295</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Stop position :</TableCell>
        <TableCell>24697</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Strand :</TableCell>
        <TableCell>{geneDetails?.chr}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Description :</TableCell>
        <TableCell>{geneDetails?.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>All transcripts in this locus :</TableCell>
        <TableCell>Not found in DB</TableCell>
      </TableRow>
      <TableRow>
        {/* TODO: Replace these values with actual ones */}
        <TableCell>Best Diamond:</TableCell>
        <TableCell>Not found in DB</TableCell>
      </TableRow>
      <TableRow>
        {/* TODO: Replace these values with actual ones */}
        <TableCell>Best Diamond :</TableCell>
        <TableCell>Not found in DB</TableCell>
      </TableRow>
      <TableRow>
        {/* TODO: Replace these values with actual ones */}
        <TableCell>Best Diamond :</TableCell>
        <TableCell>Not found in DB</TableCell>
      </TableRow>
    </TableBody>
  );
}
