import {
  Drawer,
  ListItem,
  IconButton,
  Divider,
  ListItemIcon,
  ListItemText,
  List,
  Button,
} from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NeoSaveModal from "../../modal/SaveModal";
import NeoLoadModal from "../../modal/LoadModal";
import NeoShareModal from "../../modal/ShareModal";
import { NeoAboutModal } from "../../modal/AboutModal";
import { NeoReportExamplesModal } from "../../modal/ReportExamplesModal";
import {
  applicationGetConnection,
  applicationHasAboutModalOpen,
  applicationIsStandalone,
} from "../../application/ApplicationSelectors";
import { connect } from "react-redux";
import {
  setAboutModalOpen,
  setConnected,
  setWelcomeScreenOpen,
} from "../../application/ApplicationActions";
import NeoSettingsModal from "../../settings/SettingsModal";
import { getDashboardSettings } from "../DashboardSelectors";
import { updateDashboardSetting } from "../../settings/SettingsActions";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

// The sidebar that appears on the left side of the dashboard.
export const NeoDrawer = ({
  open,
  hidden,
  connection,
  dashboardSettings,
  updateDashboardSetting,
  handleDrawerClose,
  onAboutModalOpen,
  resetApplication,
}) => {
  // Override to hide the drawer when the application is in standalone mode.
  if (hidden) {
    return <></>;
  }

  const content = (
    <Drawer
      variant="permanent"
      style={
        open
          ? {
              position: "relative",
              overflowX: "hidden",
              width: "240px",
              transition: "width 125ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
              boxShadow: "2px 1px 10px 0px rgb(0 0 0 / 12%)",
            }
          : {
              position: "relative",
              overflowX: "hidden",
              boxShadow: " 2px 1px 10px 0px rgb(0 0 0 / 12%)",

              transition: "width 125ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
              width: "56px",
            }
      }
      open={open == true}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          overflowX: "hidden",
          justifyContent: "flex-end",
          padding: "0 8px",
          minHeight: "44px",
        }}
      >
        <ListItem>
          <Button
            component="label"
            onClick={resetApplication}
            style={{ color: "white", marginLeft: "-8px" }}
            variant="outlined"
            size="small"
            startIcon={<ExitToAppIcon />}
          >
            Menu
          </Button>
        </ListItem>

        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon color="primary" />
        </IconButton>
      </div>
      <div>
        <ListItem style={{ height: "47px" }}>
          <ListItemIcon />
          <ListItemText primary="" />
        </ListItem>
      </div>
      <Divider />
      <List>
        <div>
          <NeoSettingsModal
            dashboardSettings={dashboardSettings}
            updateDashboardSetting={updateDashboardSetting}
          />
          <NeoSaveModal />
          <NeoLoadModal />
          <NeoShareModal />
        </div>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={(e) =>
            window.open(
              "https://github.com/neo4j-labs/neodash/wiki/User-Guide",
              "_blank"
            )
          }
        >
          <ListItemIcon>
            <LibraryBooksIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Documentation" />
        </ListItem>
        <NeoReportExamplesModal database={connection.database} />
        <ListItem button onClick={onAboutModalOpen}>
          <ListItemIcon>
            <InfoOutlinedIcon style={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>
      </List>
      <Divider />
    </Drawer>
  );
  return content;
};

const mapStateToProps = (state) => ({
  dashboardSettings: getDashboardSettings(state),
  hidden: applicationIsStandalone(state),
  aboutModalOpen: applicationHasAboutModalOpen(state),
  connection: applicationGetConnection(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAboutModalOpen: (_) => dispatch(setAboutModalOpen(true)),
  updateDashboardSetting: (setting, value) => {
    dispatch(updateDashboardSetting(setting, value));
  },
  resetApplication: (_) => {
    dispatch(setWelcomeScreenOpen(true));
    dispatch(setConnected(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NeoDrawer);
