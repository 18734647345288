import React from 'react';
import { ParentContainer, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './RuleStyleLegend.style';
import RuleStyleLegendItem from './RuleStyleLegendItem/RuleStyleLegendItem';

export type Rule = {
  condition: string,
  customization: string,
  customizationValue: string,
  field: string,
  value: string,
}

interface Props {
  rules: Array<Rule>,
  backgroundheadercolor?: string,
}

const RuleStyleLegend = ({ rules, backgroundheadercolor = 'lightgrey' }: Props) => (
  <ParentContainer>
    <StyledAccordion>
      <StyledAccordionSummary
        backgroundheadercolor={backgroundheadercolor}
      >
        <div>Legend</div>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {rules.map(rule => <RuleStyleLegendItem key={rule.value} rule={rule} />)}
      </StyledAccordionDetails>
    </StyledAccordion>
  </ParentContainer>
);

export default RuleStyleLegend;
