export function qnorm(p) {
  // ALGORITHM AS 111, APPL.STATIST., VOL.26, 118-121, 1977.
  // Computes z = invNorm(p)
  p = parseFloat(p);
  const split = 0.42;
  const a0 = 2.50662823884;
  const a1 = -18.61500062529;
  const a2 = 41.39119773534;
  const a3 = -25.44106049637;
  const b1 = -8.4735109309;
  const b2 = 23.08336743743;
  const b3 = -21.06224101826;
  const b4 = 3.13082909833;
  const c0 = -2.78718931138;
  const c1 = -2.29796479134;
  const c2 = 4.85014127135;
  const c3 = 2.32121276858;
  const d1 = 3.54388924762;
  const d2 = 1.63706781897;
  const q = p - 0.5;
  let r, ppnd;
  if (Math.abs(q) <= split) {
    r = q * q;
    ppnd =
      (q * (((a3 * r + a2) * r + a1) * r + a0)) /
      ((((b4 * r + b3) * r + b2) * r + b1) * r + 1);
  } else {
    r = p;
    if (q > 0) r = 1 - p;
    if (r > 0) {
      r = Math.sqrt(-Math.log(r));
      ppnd = (((c3 * r + c2) * r + c1) * r + c0) / ((d2 * r + d1) * r + 1);
      if (q < 0) ppnd = -ppnd;
    } else {
      ppnd = 0;
    }
  }
  return ppnd;
}

export function pnorm(x) {
  const a = [
    2.2352520354606839287, 161.02823106855587881, 1067.6894854603709582,
    18154.981253343561249, 0.065682337918207449113,
  ];

  const b = [
    47.20258190468824187, 976.09855173777669322, 10260.932208618978205,
    45507.789335026729956,
  ];
  const c = [
    0.39894151208813466764, 8.8831497943883759412, 93.506656132177855979,
    597.27027639480026226, 2494.5375852903726711, 6848.1904505362823326,
    11602.651437647350124, 9842.7148383839780218, 1.0765576773720192317e-8,
  ];
  const d = [
    22.266688044328115691, 235.38790178262499861, 1519.377599407554805,
    6485.558298266760755, 18615.571640885098091, 34900.952721145977266,
    38912.003286093271411, 19685.429676859990727,
  ];
  const p = [
    0.21589853405795699, 0.1274011611602473639, 0.022235277870649807,
    0.001421619193227893466, 2.9112874951168792e-5, 0.02307344176494017303,
  ];
  const q = [
    1.28426009614491121, 0.468238212480865118, 0.0659881378689285515,
    0.00378239633202758244, 7.29751555083966205e-5,
  ];

  const M_SQRT_32 = 5.656854249492380195206754896838;
  const M_1_SQRT_2PI = 0.398942280401432677939946059934;
  const R_D__1 = 1.0;
  const R_D__0 = 0.0;
  const SIXTEN = 16;

  let xden,
    xnum,
    temp,
    del,
    xsq = 0;
  let eps = Number.EPSILON * 0.5;
  let y = Math.abs(x);
  let lower = 0;
  let upper = 1;
  let cum;
  let ccum;

  if (y <= 0.67448975) {
    /* qnorm(3/4) = .6744.... -- earlier had 0.66291 */
    if (y > eps) {
      xsq = x * x;
      xnum = a[4] * xsq;
      xden = xsq;
      for (let i = 0; i < 3; ++i) {
        xnum = (xnum + a[i]) * xsq;
        xden = (xden + b[i]) * xsq;
      }
    } else xnum = xden = 0.0;

    temp = (x * (xnum + a[3])) / (xden + b[3]);
    if (lower) cum = 0.5 + temp;
    if (upper) ccum = 0.5 - temp;
  } else if (y <= M_SQRT_32) {
    /* Evaluate pnorm for 0.674.. = qnorm(3/4) < |x| <= sqrt(32) ~= 5.657 */

    xnum = c[8] * y;
    xden = y;
    for (let i = 0; i < 7; ++i) {
      xnum = (xnum + c[i]) * y;
      xden = (xden + d[i]) * y;
    }
    temp = (xnum + c[7]) / (xden + d[7]);

    /* do_del() MACRO */
    xsq = Math.trunc(y * SIXTEN) / SIXTEN;
    del = (y - xsq) * (y + xsq);

    cum = Math.exp(-xsq * xsq * 0.5) * Math.exp(-del * 0.5) * temp;
    ccum = 1.0 - cum;
    /* END do_del() MACRO */

    if (x > 0.0) {
      temp = cum;
      if (lower) cum = ccum;
      ccum = temp;
    }
  } else if (
    /* else	  |x| > sqrt(32) = 5.657 :
     * the next two case differentiations were really for lower=T, log=F
     * Particularly	 *not*	for  log_p !
     * Cody had (-37.5193 < x  &&  x < 8.2924) ; R originally had y < 50
     *
     * Note that we do want symmetry(0), lower/upper -> hence use y
     */
    (lower && -37.5193 < x && x < 8.2924) ||
    (upper && -8.2924 < x && x < 37.5193)
  ) {
    /* Evaluate pnorm for x in (-37.5, -5.657) union (5.657, 37.5) */
    xsq = 1.0 / (x * x); /* (1./x)*(1./x) might be better */
    xnum = p[5] * xsq;
    xden = xsq;
    for (let i = 0; i < 4; ++i) {
      xnum = (xnum + p[i]) * xsq;
      xden = (xden + q[i]) * xsq;
    }
    temp = (xsq * (xnum + p[4])) / (xden + q[4]);
    temp = (M_1_SQRT_2PI - temp) / y;

    xsq = Math.trunc(x * SIXTEN) / SIXTEN;
    del = (x - xsq) * (x + xsq);

    cum = Math.exp(-xsq * xsq * 0.5) * Math.exp(-del * 0.5) * temp;
    ccum = 1.0 - cum;

    if (x > 0.0) {
      temp = cum;
      if (lower) cum = ccum;
      ccum = temp;
    }
  } else {
    /* large x such that probs are 0 or 1 */
    if (x > 0) {
      cum = R_D__1;
      ccum = R_D__0;
    } else {
      cum = R_D__0;
      ccum = R_D__1;
    }
  }
  return cum;
}

export function pnormN(x) {
  const a = [
    2.2352520354606839287, 161.02823106855587881, 1067.6894854603709582,
    18154.981253343561249, 0.065682337918207449113,
  ];

  const b = [
    47.20258190468824187, 976.09855173777669322, 10260.932208618978205,
    45507.789335026729956,
  ];
  const c = [
    0.39894151208813466764, 8.8831497943883759412, 93.506656132177855979,
    597.27027639480026226, 2494.5375852903726711, 6848.1904505362823326,
    11602.651437647350124, 9842.7148383839780218, 1.0765576773720192317e-8,
  ];
  const d = [
    22.266688044328115691, 235.38790178262499861, 1519.377599407554805,
    6485.558298266760755, 18615.571640885098091, 34900.952721145977266,
    38912.003286093271411, 19685.429676859990727,
  ];
  const p = [
    0.21589853405795699, 0.1274011611602473639, 0.022235277870649807,
    0.001421619193227893466, 2.9112874951168792e-5, 0.02307344176494017303,
  ];
  const q = [
    1.28426009614491121, 0.468238212480865118, 0.0659881378689285515,
    0.00378239633202758244, 7.29751555083966205e-5,
  ];

  const M_SQRT_32 = 5.656854249492380195206754896838;
  const M_1_SQRT_2PI = 0.398942280401432677939946059934;
  const R_D__1 = 1.0;
  const R_D__0 = 0.0;
  const SIXTEN = 16;

  let xden,
    xnum,
    temp,
    del,
    xsq = 0;
  let eps = Number.EPSILON * 0.5;
  let y = Math.abs(x);
  let lower = 0;
  let upper = 1;
  let cum;
  let ccum;

  if (y <= 0.67448975) {
    /* qnorm(3/4) = .6744.... -- earlier had 0.66291 */
    if (y > eps) {
      xsq = x * x;
      xnum = a[4] * xsq;
      xden = xsq;
      for (let i = 0; i < 3; ++i) {
        xnum = (xnum + a[i]) * xsq;
        xden = (xden + b[i]) * xsq;
      }
    } else xnum = xden = 0.0;

    temp = (x * (xnum + a[3])) / (xden + b[3]);
    if (lower) cum = 0.5 + temp;
    if (upper) ccum = 0.5 - temp;
  } else if (y <= M_SQRT_32) {
    /* Evaluate pnorm for 0.674.. = qnorm(3/4) < |x| <= sqrt(32) ~= 5.657 */

    xnum = c[8] * y;
    xden = y;
    for (let i = 0; i < 7; ++i) {
      xnum = (xnum + c[i]) * y;
      xden = (xden + d[i]) * y;
    }
    temp = (xnum + c[7]) / (xden + d[7]);

    /* do_del() MACRO */
    xsq = Math.trunc(y * SIXTEN) / SIXTEN;
    del = (y - xsq) * (y + xsq);

    cum = Math.exp(-xsq * xsq * 0.5) * Math.exp(-del * 0.5) * temp;
    ccum = 1.0 - cum;
    /* END do_del() MACRO */

    if (x > 0.0) {
      temp = cum;
      if (lower) cum = ccum;
      ccum = temp;
    }
  } else if (
    /* else	  |x| > sqrt(32) = 5.657 :
     * the next two case differentiations were really for lower=T, log=F
     * Particularly	 *not*	for  log_p !
     * Cody had (-37.5193 < x  &&  x < 8.2924) ; R originally had y < 50
     *
     * Note that we do want symmetry(0), lower/upper -> hence use y
     */
    (lower && -37.5193 < x && x < 8.2924) ||
    (upper && -8.2924 < x && x < 37.5193)
  ) {
    /* Evaluate pnorm for x in (-37.5, -5.657) union (5.657, 37.5) */
    xsq = 1.0 / (x * x); /* (1./x)*(1./x) might be better */
    xnum = p[5] * xsq;
    xden = xsq;
    for (let i = 0; i < 4; ++i) {
      xnum = (xnum + p[i]) * xsq;
      xden = (xden + q[i]) * xsq;
    }
    temp = (xsq * (xnum + p[4])) / (xden + q[4]);
    temp = (M_1_SQRT_2PI - temp) / y;

    xsq = Math.trunc(x * SIXTEN) / SIXTEN;
    del = (x - xsq) * (x + xsq);

    cum = Math.exp(-xsq * xsq * 0.5) * Math.exp(-del * 0.5) * temp;
    ccum = 1.0 - cum;

    if (x > 0.0) {
      temp = cum;
      if (lower) cum = ccum;
      ccum = temp;
    }
  } else {
    /* large x such that probs are 0 or 1 */
    if (x > 0) {
      cum = R_D__1;
      ccum = R_D__0;
    } else {
      cum = R_D__0;
      ccum = R_D__1;
    }
  }

  return cum;
}
