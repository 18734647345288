import { initializeSSO } from "../component/sso/SSOUtils";
import { setDashboard } from "../dashboard/DashboardActions";
import { NEODASH_VERSION } from "../dashboard/DashboardReducer";
import {
  getAllGenieListsToUserName,
  loadDashboardFromNeo4jByNameThunk,
  loadDashboardThunk,
  upgradeDashboardVersion,
} from "../dashboard/DashboardThunks";
import { createNotificationThunk } from "../page/PageThunks";
import { runCypherQuery } from "../report/ReportQueryRunner";
import { updateGlobalParametersThunk } from "../settings/SettingsThunks";
import {
  setConnected,
  setConnectionModalOpen,
  setConnectionProperties,
  setDesktopConnectionProperties,
  resetShareDetails,
  setShareDetailsFromUrl,
  setWelcomeScreenOpen,
  setDashboardToLoadAfterConnecting,
  setOldDashboard,
  clearDesktopConnectionProperties,
  clearNotification,
  setSSOEnabled,
  setStandaloneEnabled,
  setAboutModalOpen,
  setStandaloneMode,
  setStandaloneDashboardDatabase,
  setWaitForSSO,
  setParametersToLoadAfterConnecting,
  setReportHelpModalOpen,
  setRelationshipHelpModalOpen,
  updateConnectionProperties,
} from "./ApplicationActions";
import { configStore } from "../../public/config";
import * as uuid from "uuid";
import { GENIEBOARD_USER_ID } from "../constants";
import { loginUser } from "../api/neo4j-community/query";
/**
 * Application Thunks (https://redux.js.org/usage/writing-logic-thunks) handle complex state manipulations.
 * Several actions/other thunks may be dispatched from here.
 */

/**
 * Establish a connection to Neo4j with the specified credentials. Open/close the relevant windows when connection is made (un)successfully.
 * @param protocol - the neo4j protocol (e.g. bolt, bolt+s, neo4j+s, ...)
 * @param url - URL of the host.
 * @param port - port on which Neo4j is running.
 * @param database - the Neo4j database to connect to.
 * @param username - Neo4j username.
 * @param password - Neo4j password.
 */
export const createConnectionThunk =
  (protocol, url, port, database, username, password) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setConnectionProperties(database, username, password));
      if (!username || !password) {
        return;
      }
      await loginUser(username, password);
      if (username) {
        dispatch(getAllGenieListsToUserName(username, database));
      } else {
        console.log("Username not found");
      }

      console.log("Attempting to connect...");
      const application = getState().application;
      const setDashboardAfterLoadingFromDatabase = (value) => {
        dispatch(loadDashboardThunk(value));
      };
      if (application.dashboardToLoadAfterConnecting) {
        dispatch(
          loadDashboardFromNeo4jByNameThunk(
            application.standaloneDashboardDatabase,
            application.dashboardToLoadAfterConnecting.substring(5),
            setDashboardAfterLoadingFromDatabase
          )
        );
      }
      dispatch(setConnectionModalOpen(false));
      dispatch(setConnected(true));
    } catch (e) {
      console.log("Error connecting: ", e);
      dispatch(createNotificationThunk("Unable to establish connection", e));
    }
  };

/**
 * Establish a connection directly from the Neo4j Desktop integration (if running inside Neo4j Desktop)
 */
export const createConnectionFromDesktopIntegrationThunk =
  () => (dispatch: any, getState: any) => {
    try {
      const desktopConnectionDetails = getState().application.desktopConnection;
      const protocol = desktopConnectionDetails.protocol;
      const url = desktopConnectionDetails.url;
      const port = desktopConnectionDetails.port;
      const database = desktopConnectionDetails.database;
      const username = desktopConnectionDetails.username;
      const password = desktopConnectionDetails.password;
      dispatch(
        createConnectionThunk(protocol, url, port, database, username, password)
      );
    } catch (e) {
      dispatch(
        createNotificationThunk(
          "Unable to establish connection to Neo4j Desktop",
          e
        )
      );
    }
  };

/**
 * Find the active database from Neo4j Desktop.
 * Set global state values to remember the values retrieved from the integration so that we can connect later if possible.
 */
export const setDatabaseFromNeo4jDesktopIntegrationThunk =
  () => (dispatch: any, getState: any) => {
    const getActiveDatabase = (context) => {
      for (let pi = 0; pi < context.projects.length; pi++) {
        let prj = context.projects[pi];
        for (let gi = 0; gi < prj.graphs.length; gi++) {
          let grf = prj.graphs[gi];
          if (grf.status == "ACTIVE") {
            return grf;
          }
        }
      }
      // No active database found - ask for manual connection details.
      return null;
    };

    let promise = window.neo4jDesktopApi && window.neo4jDesktopApi.getContext();

    if (promise) {
      promise.then(function (context) {
        let neo4j = getActiveDatabase(context);
        if (neo4j) {
          dispatch(
            setDesktopConnectionProperties(
              neo4j.connection.configuration.protocols.bolt.url.split("://")[0],
              neo4j.connection.configuration.protocols.bolt.url
                .split("://")[1]
                .split(":")[0],
              neo4j.connection.configuration.protocols.bolt.port,
              undefined,
              neo4j.connection.configuration.protocols.bolt.username,
              neo4j.connection.configuration.protocols.bolt.password
            )
          );
        }
      });
    }
  };

/**
 * On application startup, check the URL to see if we are loading a shared dashboard.
 * If yes, decode the URL parameters and set the application state accordingly, so that it can be loaded later.
 */
export const handleSharedDashboardsThunk =
  () => (dispatch: any, getState: any) => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      //  Parse the URL parameters to see if there's any deep linking of parameters.
      const paramsToSetAfterConnecting = {};
      Array.from(urlParams.entries()).forEach(([key, value]) => {
        if (key.startsWith("neodash_")) {
          paramsToSetAfterConnecting[key] = value;
        }
      });
      if (Object.keys(paramsToSetAfterConnecting).length > 0) {
        dispatch(
          setParametersToLoadAfterConnecting(paramsToSetAfterConnecting)
        );
      }

      if (urlParams.get("share") !== null) {
        const id = decodeURIComponent(urlParams.get("id"));
        const type = urlParams.get("type");
        const standalone = urlParams.get("standalone") == "Yes";
        if (urlParams.get("credentials")) {
          const connection = decodeURIComponent(urlParams.get("credentials"));
          const protocol = connection.split("://")[0];
          const username = connection.split("://")[1].split(":")[0];
          const password = connection
            .split("://")[1]
            .split(":")[1]
            .split("@")[0];
          const database = connection.split("@")[1].split(":")[0];
          const url = connection.split("@")[1].split(":")[1];
          const port = connection.split("@")[1].split(":")[2];
          dispatch(
            setShareDetailsFromUrl(
              type,
              id,
              standalone,
              protocol,
              url,
              port,
              database,
              username,
              password
            )
          );
          window.history.pushState({}, document.title, "/");
        } else {
          dispatch(
            setShareDetailsFromUrl(
              type,
              id,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
          );
          window.history.pushState({}, document.title, "/");
        }
      } else {
        // dispatch(resetShareDetails());
      }
    } catch (e) {
      dispatch(
        createNotificationThunk(
          "Unable to load shared dashboard",
          "You have specified an invalid/incomplete share URL. Try regenerating the share URL from the sharing window."
        )
      );
    }
  };

/**
 * Confirm that we load a shared dashboard. This requires that the state was previously set in `handleSharedDashboardsThunk()`.
 */
export const onConfirmLoadSharedDashboardThunk =
  () => (dispatch: any, getState: any) => {
    try {
      const state = getState();
      const shareDetails = state.application.shareDetails;
      dispatch(setWelcomeScreenOpen(false));
      dispatch(setDashboardToLoadAfterConnecting(shareDetails.id));

      if (shareDetails.dashboardDatabase) {
        dispatch(
          setStandaloneDashboardDatabase(shareDetails.dashboardDatabase)
        );
        dispatch(setStandaloneDashboardDatabase(shareDetails.database));
      }
      if (shareDetails.url) {
        dispatch(
          createConnectionThunk(
            shareDetails.protocol,
            shareDetails.url,
            shareDetails.port,
            shareDetails.database,
            shareDetails.username,
            shareDetails.password
          )
        );
      } else {
        dispatch(setConnectionModalOpen(true));
      }
      if (shareDetails.standalone == true) {
        dispatch(setStandaloneMode(true));
      }
      dispatch(resetShareDetails());
    } catch (e) {
      dispatch(
        createNotificationThunk(
          "Unable to load shared dashboard",
          "The provided connection or dashboard identifiers are invalid. Try regenerating the share URL from the sharing window."
        )
      );
    }
  };

export const loginUserWithId =
  (config) => async (dispatch: any, getState: any) => {
    try {
      const userId = localStorage.getItem(GENIEBOARD_USER_ID);
      if (userId) {
        // Send the user Id to db and get the user details
        dispatch(setWelcomeScreenOpen(false));
        dispatch(
          createConnectionThunk(
            config["standaloneProtocol"],
            config["standaloneHost"],
            config["standalonePort"],
            "genie",
            "",
            ""
          )
        );
      } else {
        console.log("User Id not found");
        const id = uuid.v4();
        // Create new user Id and send it to db and get the user details
        localStorage.setItem(GENIEBOARD_USER_ID, id);
      }
    } catch (error) {
      // Error while fetching user details
    }
  };

/**
 * Initializes the NeoDash application.
 *
 * This is a multi step process, starting with loading the runtime configuration.
 * This is present in the file located at /config.ts on the URL where NeoDash is deployed.
 * Note: this does not work in Neo4j Desktop, so we revert to defaults.
 */
export const loadApplicationConfigThunk =
  () => async (dispatch: any, getState: any) => {
    let config = configStore;
    console.log("Loading the dashboard:", config);
    console.log("This method is called loading the dashboard");
    dispatch(setConnectionModalOpen(false));
    // try {
    //   config = await (await fetch("config.ts")).json();
    // } catch (e) {
    //   // Config may not be found, for example when we are in Neo4j Desktop.
    //   console.log("No config file detected. Setting to safe defaults.");
    // }
    if (config.loginEnabled) {
      // User needs to login
      dispatch(setWelcomeScreenOpen(true));
    } else {
      dispatch(loginUserWithId(config));
    }

    try {
      // Parse the URL parameters to see if there's any deep linking of parameters.
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramsToSetAfterConnecting = {};
      Array.from(urlParams.entries()).forEach(([key, value]) => {
        if (key.startsWith("neodash_")) {
          paramsToSetAfterConnecting[key] = value;
        }
      });

      const clearNotificationAfterLoad = true;
      dispatch(setSSOEnabled(config["ssoEnabled"], config["ssoDiscoveryUrl"]));
      const state = getState();
      const standalone = config["standalone"]; // || (state.application.shareDetails !== undefined && state.application.shareDetails.standalone);
      dispatch(
        setStandaloneEnabled(
          standalone,
          config["standaloneProtocol"],
          config["standaloneHost"],
          config["standalonePort"],
          config["standaloneDatabase"],
          config["standaloneDashboardName"],
          config["standaloneDashboardDatabase"],
          config["standaloneDashboardURL"]
        )
      );
      dispatch(setConnectionModalOpen(false));

      // Auto-upgrade the dashboard version if an old version is cached.
      if (state.dashboard && state.dashboard.version !== NEODASH_VERSION) {
        if (state.dashboard.version == "2.0") {
          const upgradedDashboard = upgradeDashboardVersion(
            state.dashboard,
            "2.0",
            "2.1"
          );
          dispatch(setDashboard(upgradedDashboard));
          dispatch(
            createNotificationThunk(
              "Successfully upgraded dashboard",
              "Your old dashboard was migrated to version 2.0. You might need to refresh this page."
            )
          );
        }
      }

      // SSO - specific case starts here.
      if (state.application.waitForSSO) {
        // We just got redirected from the SSO provider. Hide all windows and attempt the connection.
        dispatch(setAboutModalOpen(false));
        dispatch(setConnected(false));
        dispatch(setWelcomeScreenOpen(false));
        const success = await initializeSSO(
          config["ssoDiscoveryUrl"],
          (credentials) => {
            if (standalone) {
              dispatch(
                setConnectionProperties(
                  config["standaloneDatabase"],
                  credentials["username"],
                  credentials["password"]
                )
              );
              dispatch(
                createConnectionThunk(
                  config["standaloneProtocol"],
                  config["standaloneHost"],
                  config["standalonePort"],
                  config["standaloneDatabase"],
                  credentials["username"],
                  credentials["password"]
                )
              );

              if (
                config["standaloneDashboardURL"] !== undefined &&
                config["standaloneDashboardURL"].length > 0
              ) {
                dispatch(
                  setDashboardToLoadAfterConnecting(
                    config["standaloneDashboardURL"]
                  )
                );
              } else {
                dispatch(
                  setDashboardToLoadAfterConnecting(
                    "name:" + config["standaloneDashboardName"]
                  )
                );
              }
              dispatch(
                setParametersToLoadAfterConnecting(paramsToSetAfterConnecting)
              );
            }
          }
        );
        dispatch(setWaitForSSO(false));
        if (!success) {
          alert("Unable to connect using SSO");
          dispatch(
            createNotificationThunk(
              "Unable to connect using SSO",
              "Something went wrong. Most likely your credentials are incorrect..."
            )
          );
        } else {
          return;
        }
      }

      if (standalone) {
        // If we are running in standalone mode, auto-set the connection details that are configured.
        dispatch(
          setConnectionProperties(
            config["standaloneDatabase"],
            config["standaloneUsername"]
              ? config["standaloneUsername"]
              : state.application.connection.username,
            config["standalonePassword"]
              ? config["standalonePassword"]
              : state.application.connection.password
          )
        );

        dispatch(setAboutModalOpen(false));
        dispatch(setConnected(false));
        dispatch(setWelcomeScreenOpen(false));

        if (
          config["standaloneDashboardURL"] !== undefined &&
          config["standaloneDashboardURL"].length > 0
        ) {
          dispatch(
            setDashboardToLoadAfterConnecting(config["standaloneDashboardURL"])
          );
        } else {
          dispatch(
            setDashboardToLoadAfterConnecting(
              "name:" + config["standaloneDashboardName"]
            )
          );
        }

        dispatch(
          setParametersToLoadAfterConnecting(paramsToSetAfterConnecting)
        );

        if (clearNotificationAfterLoad) {
          dispatch(clearNotification());
        }

        // Override for when username and password are specified in the config - automatically connect to the specified URL.
        if (config["standaloneUsername"] && config["standalonePassword"]) {
          dispatch(
            createConnectionThunk(
              config["standaloneProtocol"],
              config["standaloneHost"],
              config["standalonePort"],
              config["standaloneDatabase"],
              config["standaloneUsername"],
              config["standalonePassword"]
            )
          );
        } else {
          dispatch(setConnectionModalOpen(true));
        }
      } else {
        dispatch(clearDesktopConnectionProperties());
        dispatch(setDatabaseFromNeo4jDesktopIntegrationThunk());
        const old = localStorage.getItem("neodash-dashboard");
        dispatch(setOldDashboard(old));
        dispatch(setConnected(false));
        if (
          config["standaloneDashboardName"] &&
          config["standaloneDashboardDatabase"]
        ) {
          if (
            state?.application?.connection?.database !==
            config["standaloneDashboardDatabase"]
          ) {
            dispatch(
              updateDatabaseThunk(config["standaloneDashboardDatabase"])
            );
          }
          dispatch(
            setDashboardToLoadAfterConnecting(
              "name:" + config["standaloneDashboardName"]
            )
          );
        }
        dispatch(updateGlobalParametersThunk(paramsToSetAfterConnecting));
        if (Object.keys(paramsToSetAfterConnecting).length > 0) {
          dispatch(setParametersToLoadAfterConnecting(null));
        }

        // dispatch(setWelcomeScreenOpen(true));

        if (clearNotificationAfterLoad) {
          dispatch(clearNotification());
        }
        dispatch(handleSharedDashboardsThunk());
        dispatch(setConnectionModalOpen(false));
        dispatch(setReportHelpModalOpen(false));
        dispatch(setRelationshipHelpModalOpen(false));
        dispatch(setAboutModalOpen(false));
      }
    } catch (e) {
      dispatch(setWelcomeScreenOpen(false));
      dispatch(
        createNotificationThunk(
          "Unable to load application configuration",
          "Do you have a valid config.json deployed with your application?"
        )
      );
    }
  };

export const updateDatabaseThunk =
  (newDb) => async (dispatch: any, getState: any) => {
    const connectionState = getState().application.connection;
    dispatch(
      updateConnectionProperties(
        newDb,
        connectionState?.username,
        connectionState?.password
      )
    );
  };
