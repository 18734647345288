import axios from "axios";

const baseURL = "https://enrichment.plantgenie.org/potrav2/enrichment";

const axiosClient = axios.create({ baseURL: baseURL });

export async function getEnrichmentData(geneList, backgroundList) {
  if (backgroundList !== "default" && backgroundList !== "gene-list") {
    return axiosClient.post("", {
      target: ["go", "pfam", "kegg"],
      genes: geneList,
      background: backgroundList,
      include_defs: false,
      alpha: 1,
    });
  }
  return axiosClient.post("", {
    target: ["go", "pfam", "kegg"],
    genes: geneList,
    include_defs: false,
    alpha: 1,
  });
}
