import React from "react";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import Application from "./application/Application";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import getThemes from "./utils/themes";

const themes = getThemes();

export default function App() {
  const appState = useSelector((state) => state?.application);

  const themeIndex = appState?.themeIndex || 2;
  return (
    <ThemeProvider theme={themes[themeIndex]}>
      <Application />
    </ThemeProvider>
  );
}
