import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ChartProps } from "./Chart";
import { GridToolbar, GridSelectionModel } from "@mui/x-data-grid";
import { Record } from "neo4j-driver";
import {
  getRendererForValue,
  rendererForType,
  RenderSubValue,
} from "../report/ReportRecordProcessing";
import {
  evaluateRulesOnDict,
  generateClassDefinitionsBasedOnRules,
} from "../report/ReportRuleEvaluator";
import { DataGridStyled } from "./TableChart.styles";
//Matrix adding createLinks helper
import { createLinks, linksArray } from "../helpers/createFieldLinks";

import { updateSelectionThunk } from "../card/CardThunks";
import { getReportIndex } from "../card/CardSelectors";
import { selectGenies } from "../dashboard/DashboardActions";

function ApplyColumnType(column, value) {
  const renderer = getRendererForValue(value);
  // Matrix adding renderer for fields to act as links
  const columnProperties = renderer
    ? {
        type: renderer.type,
        renderCell: !linksArray.includes(column.headerName)
          ? renderer.renderValue
          : (renderer) => {
              return (
                <a target="_blank" href={createLinks(renderer.value)}>
                  [Link]
                </a>
              );
            },
      }
    : rendererForType["string"];
  if (columnProperties) {
    column = { ...column, ...columnProperties };
  }
  return column;
}

const NeoTableChart = (props: ChartProps) => {
  const [pageSize, setPageSize] = React.useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectGenies([]));
  }, []);

  const transposed =
    props.settings && props.settings.transposed
      ? props.settings.transposed
      : false;
  const allowDownload =
    props.settings && props.settings.allowDownload !== undefined
      ? props.settings.allowDownload
      : false;
  const styleRules =
    props.settings && props.settings.styleRules
      ? props.settings.styleRules
      : [];
  const displayTopBar =
    props.settings && props.settings.displayTopBar !== undefined
      ? props.settings.displayTopBar
      : true;
  const displayGridButtons =
    props.settings && props.settings.displayGridButtons !== undefined
      ? props.settings.displayGridButtons
      : true;

  const allowTopBarButtonsProps = !displayGridButtons
    ? {
        disableColumnFilter: true,
        disableColumnSelector: true,
        disableDensitySelector: true,
      }
    : null;

  const useStyles = generateClassDefinitionsBasedOnRules(styleRules);
  const classes = useStyles();
  if (
    props.records == null ||
    props.records.length == 0 ||
    props.records[0].keys == null
  ) {
    return <>No data, re-run the report.</>;
  }

  var columnWidths = null;
  try {
    columnWidths =
      props.settings &&
      props.settings.columnWidths &&
      JSON.parse(props.settings.columnWidths);
  } catch (e) {
    // do nothing
  } finally {
    // do nothing
  }

  const records = props.records as Record[];
  const columns = transposed
    ? ["Field"]
        .concat(
          records.map(
            (r, j) => "Value" + (j == 0 ? "" : " " + (j + 1).toString())
          )
        )
        .map((key, i) => {
          const value = key;
          return ApplyColumnType(
            {
              field: key,
              headerName: key,
              headerClassName: "table-small-header",
              disableColumnSelector: true,
              flex:
                columnWidths && i < columnWidths.length ? columnWidths[i] : 1,
              disableClickEventBubbling: true,
            },
            value
          );
        })
    : records[0].keys.map((key, i) => {
        const value = records[0].get(key);
        return ApplyColumnType(
          {
            field: key,
            headerName: key,
            headerClassName: "table-small-header",
            disableColumnSelector: true,
            flex: columnWidths && i < columnWidths.length ? columnWidths[i] : 1,
            disableClickEventBubbling: true,
          },
          value
        );
      });

  const getFieldValue = (field: any) => {
    if (
      typeof field === "object" &&
      field.low !== undefined &&
      field.high !== undefined
    ) {
      return field.low;
    }
    return field;
  };

  const rows = transposed
    ? records[0].keys.map((key, i) => {
        return Object.assign(
          { id: i, Field: key },
          ...records.map((r, j) => ({
            ["Value" + (j == 0 ? "" : " " + (j + 1).toString())]:
              RenderSubValue(r._fields[i]),
          }))
        );
      })
    : records.map((record, rownumber) => {
        return Object.assign(
          { id: rownumber },
          ...record._fields.map((field, i) => ({
            [record.keys[i]]: getFieldValue(field),
          }))
        );
      });

  useEffect(() => {
    props.onSelectionUpdate(props.index, 1, "");
  }, [props.records]);

  return (
    <div
      className={classes.root}
      style={{ height: "100%", width: "100%", position: "relative" }}
    >
      <DataGridStyled
        headerHeight={32}
        rows={rows}
        columns={columns}
        getRowHeight={() => "auto"}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[3, 5, 10, 20]}
        // onSelectionModelChange={(ids) => {
        //     setRowSelectionModel(ids);
        //   const selectedRowsData = ids.map((id) =>
        //     rows.find((row) => row.id === id)
        //   );
        //   props.onSelectionUpdate &&
        //     props.onSelectionUpdate(
        //       props.index,
        //       1,
        //       Object.values(selectedRowsData[0])[1]
        //     );
        // }}
        components={{
          ColumnSortedDescendingIcon: () => <></>,
          ColumnSortedAscendingIcon: () => <></>,
          Toolbar: displayTopBar ? GridToolbar : null,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: !allowDownload },
          },
        }}
        {...allowTopBarButtonsProps}
        getRowClassName={(params) => {
          return (
            "rule" +
            evaluateRulesOnDict(params.row, styleRules, [
              "row color",
              "row text color",
            ])
          );
        }}
        getCellClassName={(params) => {
          return (
            "rule" +
            evaluateRulesOnDict({ [params.field]: params.value }, styleRules, [
              "cell color",
              "cell text color",
            ])
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  index: getReportIndex(state, ownProps),
});
const mapDispatchToProps = (dispatch) => ({
  onSelectionUpdate: (index: any, selectable: any, field: any) => {
    dispatch(updateSelectionThunk(index, true, field));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(NeoTableChart);
