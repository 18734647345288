
export const getDashboardTitle = (state: any) => state.dashboard.title;


export const getReportState = (state: any, index: any) => {
    const pagenumber = state.dashboard.settings.pagenumber;
    return state.dashboard.pages[pagenumber].reports[index];
}


export const getConnectReportState = (state: any, props: any) => {
    const pagenumber = state.dashboard.settings.pagenumber;
    const index = state.dashboard.pages[pagenumber].reports.findIndex(x => x.title === props.settings.cardConnection);
    return state.dashboard.pages[pagenumber].reports[index];
}

export const getReportIndex = (state: any, props: any) => {
    const pagenumber = state.dashboard.settings.pagenumber;
    const index = state.dashboard.pages[pagenumber].reports.findIndex(x => x.title === props.title);
    return index;
}
