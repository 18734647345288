import React, { useEffect, useState } from "react";
import { ChartProps } from "./Chart";
import InCHlibX from "inchlibx";
import { useSelector } from "react-redux";
import { getHeatmapData } from "../api/heatmap";
import {
  Button,
  Typography,
  Box,
  Stack,
  CircularProgress,
  Grid,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Slider from "@mui/material/Slider";
import ToggleButton from "@mui/material/ToggleButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import axios from "axios";

const dataBaseUrl = "https://api.plantgenie.org/exheatmap/tmp/";

const ROW_DISTANCE_VALUES = [
  {
    label: "Euclidean",
    value: "euclidean",
  },
  { label: "Braycurtis", value: "braycurtis" },
  { label: "Correlation", value: "correlation" },
  { label: "Canberra", value: "canberra" },
  { label: "Cosine", value: "cosine" },
  { label: "Chebyshev", value: "chebyshev" },
  { label: "Cityblock", value: "cityblock" },
  { label: "Mahalanobis", value: "mahalanobis" },
  { label: "Minkowski", value: "minkowski" },
  { label: "Seuclidean", value: "seuclidean" },
  { label: "Sqeuclidean", value: "sqeuclidean" },
  { label: "Dice", value: "dice" },
  { label: "Hamming", value: "hamming" },
  { label: "Jaccard", value: "jaccard" },
  { label: "Kulsinski", value: "kulsinski" },
  { label: "Rogerstanimoto", value: "rogerstanimoto" },
  { label: "Russellrao", value: "russellrao" },
  { label: "Sokalmichener", value: "sokalmichener" },
  { label: "Sokalsneath", value: "sokalsneath" },
  { label: "Yule", value: "yule" },
];

const ROW_LINKAGE_VALUES = [
  {
    label: "Ward",
    value: "ward",
  },
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Complete",
    value: "complete",
  },
  {
    label: "Average",
    value: "average",
  },
  {
    label: "Centroid",
    value: "centroid",
  },
  {
    label: "Median",
    value: "median",
  },
  {
    label: "Weighted",
    value: "weighted",
  },
];

const prepareCsvFromHeatMapData = (file) => {
  const columns = ["Gene name", ...file?.data?.feature_names];
  const rows: any = [];
  const keys = Object.keys(file.data.nodes);
  for (let i = 0; i < keys.length; i++) {
    const node = file.data.nodes[keys[i]];
    if (node?.features) {
      const row = [node.objects[0], ...node.features];
      rows.push(row);
    }
  }
  let csvContent = "data:text/csv;charset=utf-8," + columns.join(",") + "\n";
  csvContent += rows.map((e) => e.join(",")).join("\n");
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "my_data.csv");
  document.body.appendChild(link);
  link.click();
};

const NeoHeatMap = (props: ChartProps) => {
  const [fileLink, setFileLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [inchlib, setInchlib] = useState<any>(null);
  const [normalized, setNormalized] = useState(true);
  const [axis, setAxis] = useState("row");
  const [sliderValue, setSliderValue] = useState(10);
  const [showCellValues, setShowCellValues] = useState(false);
  const [rowDistance, setRowDistance] = useState("euclidean");
  const [rowLinkage, setRowLinkage] = useState("ward");

  const { getGlobalParameter } = props;

  const applicationState = useSelector(
    (state: any) => state.dashboard?.settings?.parameters
  );

  async function getFileData(file) {
    setLoading(true);
    try {
      const response = await axios.get(file);
      setLoading(false);
      if (response.status === 200) {
        prepareCsvFromHeatMapData(response.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  async function getFileLink() {
    const fileName = getGlobalParameter
      ? getGlobalParameter("neodash_experiment_value")
      : null;

    try {
      if (!fileName || !applicationState?.neodash_genelist_name) return;
      setLoading(true);
      const response = await getHeatmapData(
        applicationState?.neodash_genelist_name.join(","),
        fileName,
        normalized,
        axis,
        rowDistance,
        rowLinkage
      );

      if (response?.data?.link.length === 13) {
        setFileLink(dataBaseUrl + response.data.link + ".json");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleChangeNormalized = (event, normalized) => {
    setNormalized(normalized);
  };

  const handleChangeAxis = (event, axis) => {
    setAxis(axis);
  };

  const handleChangeShowCellValues = (event, showCellValues) => {
    setShowCellValues(showCellValues);
  };

  const handleChangeRowDistance = (event) => {
    setRowDistance(event.target.value);
  };

  const handleChangeRowLinkage = (event) => {
    setRowLinkage(event.target.value);
  };

  useEffect(() => {
    getFileLink();
  }, [applicationState, normalized, axis, rowDistance, rowLinkage]);

  useEffect(() => {
    if (!fileLink) return;

    const inchlib = new InCHlibX({
      target: "inchlib",
      width: 100 * sliderValue,
      max_height: props?.dimensions?.height,
      heatmap_colors: "BuWhRd",
      metadata: true,
      independent_columns: false,
      column_metadata: true,
      metadata_colors: "Reds",
      heatmap_part_width: 0.9,
      show_cell_values: showCellValues,
      draw_row_ids: true,
    });
    inchlib.read_data_from_file(fileLink);
    inchlib.draw();
    inchlib.hide_navigation_layer();
    setInchlib(inchlib);
  }, [props, fileLink, sliderValue, showCellValues]);

  const downloadImage = () => {
    const fileName = prompt("Please enter file name", "inchlib");
    if (fileName == null || fileName == "") {
      alert("Please enter file name");
    }
    inchlib.export_image(fileName);
  };

  return (
    <div>
      {loading && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={500}
        >
          <CircularProgress size={50} />
        </Box>
      )}
      {inchlib && !loading && (
        <div>
          <Stack
            direction="row"
            sx={{ height: 100, marginLeft: 2 }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2" fontWeight={800} sx={{ mr: 2 }}>
              Width{" "}
            </Typography>

            <Slider
              value={sliderValue}
              onChange={handleChange}
              sx={{ maxWidth: 400 }}
            />

            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SaveAltIcon />}
              style={{ marginLeft: 10, width: 150, color: "#fff" }}
              onClick={downloadImage}
            >
              Png
            </Button>
          </Stack>
          <Grid
            container
            spacing={2}
            sx={{ ml: 2, mr: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <ToggleButtonGroup
                color="primary"
                value={normalized}
                size="small"
                exclusive
                onChange={handleChangeNormalized}
                aria-label="Platform"
              >
                <ToggleButton value={true}>Absolute log2(TPM+1)</ToggleButton>
                <ToggleButton value={false}>Row scaled</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                color="primary"
                value={axis}
                size="small"
                exclusive
                onChange={handleChangeAxis}
                aria-label="Platform"
              >
                <ToggleButton value="row">Cluster genes only</ToggleButton>
                <ToggleButton value="both">
                  Cluster genes and Samples
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                color="primary"
                value={showCellValues}
                size="small"
                exclusive
                onChange={handleChangeShowCellValues}
                aria-label="Platform"
              >
                <ToggleButton value={true}>Show Cell Values</ToggleButton>
                <ToggleButton value={false}>Hide Cell Values</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <Button onClick={() => getFileData(fileLink)} variant="outlined">
                Download as CSV
              </Button>
            </Grid>
            <Grid item>
              <Box sx={{ maxWidth: 250, minWidth: 200 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label-1">
                    Row Distance
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-1"
                    id="demo-simple-select-1"
                    value={rowDistance}
                    label="Row Distance"
                    size="small"
                    onChange={handleChangeRowDistance}
                  >
                    {ROW_DISTANCE_VALUES.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ maxWidth: 250, minWidth: 200 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label-2">
                    Row Linkages
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-2"
                    id="demo-simple-select-2"
                    value={rowLinkage}
                    label="Row Linkages"
                    size="small"
                    onChange={handleChangeRowLinkage}
                  >
                    {ROW_LINKAGE_VALUES.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </div>
      )}
      <div
        style={{
          overflowX: "scroll",
          marginLeft: 50,
          marginTop: 30,
          display: loading ? "none" : "block",
        }}
        id="inchlib"
      />
    </div>
  );
};

export default NeoHeatMap;
