export function createEnrichmentDataFromArray(data, geneCount): any {
  const proccessedData: any = [];
  for (const element of data) {
    const index = proccessedData.findIndex(
      (item) => item.name === element.namespace
    );
    if (index === -1) {
      proccessedData.push({
        name: element.namespace,
        children: [
          {
            name: element.name,
            id: element.id,
            loc: Math.abs(
              Math.log10(parseFloat(element.pval.toPrecision(4)))
            ).toFixed(4),
          },
        ],
      });
    } else {
      const existingElement = proccessedData[index];
      const modified = {
        ...existingElement,
        children: [
          ...existingElement.children,
          {
            name: element.name,
            id: element.id,
            loc: Math.abs(
              Math.log10(parseFloat(element.pval.toPrecision(4)))
            ).toFixed(4),
          },
        ],
      };
      proccessedData[index] = modified;
    }
  }
  return {
    name: "Enrichment",
    children: proccessedData,
  };
}

export const getActiveListMinMaxValue = (list, setMinMax) => {
  let min = 0;
  let max = 0;
  if (list.length > 0) {
    min = Math.abs(Math.log10(parseFloat(list[0].pval.toPrecision(4))));
    max = Math.abs(Math.log10(parseFloat(list[0].pval.toPrecision(4))));
    list.forEach((element) => {
      const value = Math.abs(
        Math.log10(parseFloat(element.pval.toPrecision(4)))
      );
      if (value > max) {
        max = value;
      }
      if (value < min) {
        min = value;
      }
    });
  }
  setMinMax({
    min: parseFloat(min.toFixed(2)),
    max: parseFloat(max.toFixed(2)),
  });
};

export const createEnrichmentDataFromOtherArrays = (data, geneCount) => {
  const proccessedData: any = [];
  for (const element of data) {
    proccessedData.push({
      name: element.name,
      color: "hsl(304, 70%, 50%)",
      loc: Math.abs(Math.log10(parseFloat(element.pval.toPrecision(4)))),
    });
  }
  return {
    name: "Enrichment",
    children: proccessedData,
    color: "hsl(161, 70%, 50%)",
  };
};

export function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const filterDataAccordingToFilters = (data, filters) => {
  let newData: any = [];
  filters.forEach((filter) => {
    const column = filter.columnField;
    const operator = filter.operatorValue;
    const value = parseFloat(filter.value);
    const filteredData = data.filter((row) => {
      if (operator === "=") {
        return row[column] === value;
      } else if (operator === ">") {
        return row[column] > value;
      } else if (operator === "<") {
        return row[column] < value;
      } else if (operator === ">=") {
        return row[column] >= value;
      } else if (operator === "<=") {
        return row[column] <= value;
      }
    });
    newData = [...newData, ...filteredData];
  });
  return newData;
};
