import React, { useEffect, useCallback } from "react";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExit from "@mui/icons-material/FullscreenExit";
import { TextField, Tooltip } from "@mui/material";
import debounce from "lodash/debounce";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DownloadIcon from "@mui/icons-material/Download";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const NeoCardViewHeader = ({
  title,
  editable,
  onTitleUpdate,
  fullscreenEnabled,
  downloadImageEnabled,
  onToggleCardSettings,
  onDownloadImage,
  onToggleCardExpand,
  expanded,
}) => {
  const [text, setText] = React.useState(title);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Ensure that we only trigger a text update event after the user has stopped typing.
  const debouncedTitleUpdate = useCallback(debounce(onTitleUpdate, 250), []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const downloadImage = (type) => {
    const fileName = prompt("Enter file name");
    if (fileName) {
      onDownloadImage(type, fileName);
    } else {
      alert("Invalid file name");
    }

    closeMenu();
  };

  const openMenuBar = Boolean(anchorEl);

  useEffect(() => {
    // Reset text to the dashboard state when the page gets reorganized.
    if (text !== title) {
      setText(title);
    }
  }, [title]);

  const cardTitle = (
    <>
      <Menu
        id="gene-list-menu"
        anchorEl={anchorEl}
        open={openMenuBar}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "genie-select-button",
        }}
      >
        <MenuItem onClick={() => downloadImage("jpg")}>JPEG</MenuItem>
        <MenuItem onClick={() => downloadImage("png")}>PNG</MenuItem>
        <MenuItem onClick={() => downloadImage("pdf")}>PDF</MenuItem>
      </Menu>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            {editable ? (
              <td>
                <DragIndicatorIcon
                  className="drag-handle"
                  style={{
                    color: "grey",
                    cursor: "pointer",
                    marginLeft: "-10px",
                    marginRight: "10px",
                  }}
                ></DragIndicatorIcon>
              </td>
            ) : (
              <></>
            )}
            <td style={{ width: "100%" }}>
              <TextField
                id="standard-outlined"
                label=""
                disabled={!editable}
                placeholder="Report name..."
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true }}
                value={text}
                onChange={(event) => {
                  setText(event.target.value);
                  debouncedTitleUpdate(event.target.value);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const settingsButton = (
    <Tooltip title="Settings" aria-label="settings">
      <IconButton aria-label="settings" onClick={onToggleCardSettings}>
        <MoreVertIcon />
      </IconButton>
    </Tooltip>
  );

  const maximizeButton = (
    <Tooltip title="Maximize" aria-label="maximize">
      <IconButton aria-label="maximize" onClick={onToggleCardExpand}>
        <FullscreenIcon />
      </IconButton>
    </Tooltip>
  );

  const unMaximizeButton = (
    <IconButton aria-label="un-maximize" onClick={onToggleCardExpand}>
      <FullscreenExit />
    </IconButton>
  );

  const downloadImageButton = (
    <Tooltip title="Download Report" aria-label="download">
      <IconButton onClick={handleClick} aria-label="download csv">
        <DownloadIcon
          style={{ fontSize: "1.3rem", zIndex: 5 }}
          fontSize="small"
        />
      </IconButton>
    </Tooltip>
  );

  return (
    <CardHeader
      style={{ height: "72px" }}
      action={
        <>
          {downloadImageEnabled ? downloadImageButton : <></>}
          {fullscreenEnabled ? (
            expanded ? (
              unMaximizeButton
            ) : (
              maximizeButton
            )
          ) : (
            <></>
          )}
          {editable ? settingsButton : <></>}
        </>
      }
      title={cardTitle}
    ></CardHeader>
  );
};

export default NeoCardViewHeader;
