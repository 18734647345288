import { Stack, Box, Typography, Divider, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GENE_FAMILY_DATA } from "../../constants";

export default function GeneFamilyRowComponent(props) {
  const { geneData, showAllgenes, containerWidth, onClickGene } = props;
  const [showGene, setShowGene] = useState(showAllgenes);

  useEffect(() => {
    setShowGene(showAllgenes);
  }, [showAllgenes]);

  const onChange = (event, checked) => {
    setShowGene(checked);
    onClickGene(geneData?.gf_genes, checked);
  };
  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <Box sx={{ width: 180 }}>
          <Checkbox checked={showGene} onChange={onChange} />
        </Box>
        <Box sx={{ width: 150 }}>
          <Typography variant="body2">
            {`${
              GENE_FAMILY_DATA.find(
                (item) => item.abbreviation === geneData.gf_species
              )?.species ?? geneData.gf_species
            } (${geneData?.gf_genes.split(";").length})`}
          </Typography>
        </Box>
        <Box
          sx={{
            width: containerWidth - 300,
            wordBreak: "break-word",
            ml: 2,
          }}
        >
          {showGene && (
            <Typography variant="body2">{geneData?.gf_genes}</Typography>
          )}
        </Box>
      </Stack>
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
}
