import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import saveAs from "file-saver";

export default function SequenceInformation(props) {
  const { sequenceData, dimentions, selectedGene = "", queryCallback } = props;

  const [genomicSequence, setGenomicSequence] = useState([]);
  const [transcriptSequence, setTranscriptSequence] = useState([]);
  const [genomicCoordinates, setGenomicCoordinates] = useState<any>({
    start: 0,
    end: 0,
  });

  const [colorData, setColorData] = useState<any>([]);
  const widthReduceFactor = 50;

  const fetchSequenceCoordinates = () => {
    const query =
      "match (n:SequenceColor) where n.sc_id='" +
      (selectedGene?.includes(".") ? selectedGene : selectedGene + ".1") +
      "' and  n.sc_feature='mRNA' return n.sc_scaffold,n.sc_start,n.sc_end ";
    return new Promise((resolve, reject) => {
      queryCallback(query, {}, (data) => {
        if (data.length > 0 && data[0]?._fields?.length > 0) {
          const queryData = {
            start: parseInt(data[0]._fields[1]),
            end: parseInt(data[0]._fields[2]),
          };
          resolve(queryData);
        } else {
          reject();
        }
      });
    });
  };

  const getSequenceCoordinates = async () => {
    try {
      const respone = await fetchSequenceCoordinates();
      setGenomicCoordinates(respone);
    } catch (error) {}
  };

  const getStandData = () => {
    const query = `match (n) where n.transcript_id="${selectedGene}.1" return n.strand`;
    return new Promise((resolve, reject) => {
      queryCallback(query, {}, (data) => {
        if (data.length > 0) {
          resolve(data[0]._fields[0]);
        } else {
          reject();
        }
      });
    });
  };

  const getTranscriptStartAndEnd = () => {
    const query =
      "MATCH (n) WHERE n.transcript_id='" +
      selectedGene +
      (selectedGene?.includes(".") ? "" : ".1") +
      "' RETURN n";
    return new Promise((resolve, reject) => {
      queryCallback(query, {}, (data) => {
        if (data.length > 0) {
          const tempData = data[0]._fields[0].properties;
          const transcriptStart = tempData?.transcript_start;
          const transcriptEnd = tempData?.transcript_end;
          resolve({
            transcriptStart: parseInt(transcriptStart),
            transcriptEnd: parseInt(transcriptEnd),
          });
        } else {
          reject();
        }
      });
    });
  };

  const getSequenceColorData = (strand) => {
    let query;
    if (strand === "-") {
      query =
        "MATCH (n:SequenceColor) WHERE n.sc_id='" +
        selectedGene +
        (selectedGene?.includes(".") ? "" : ".1") +
        "' RETURN n order by n.sc_end DESC";
    } else {
      query =
        "MATCH (n:SequenceColor) WHERE n.sc_id='" +
        selectedGene +
        (selectedGene?.includes(".") ? "" : ".1") +
        "' RETURN n order by n.sc_start ASC";
    }

    return new Promise((resolve, reject) => {
      queryCallback(query, {}, (data) => {
        if (data.length > 0) {
          const colorData: any = [];
          data.forEach((element) => {
            const properties = element._fields[0].properties;
            if (
              properties.sc_feature === "CDS" ||
              properties.sc_feature === "3UTR" ||
              properties.sc_feature === "5UTR"
            ) {
              colorData.push({
                ...element._fields[0].properties,
              });
            }
          });
          resolve(colorData);
        } else {
          reject();
        }
      });
    });
  };

  const prepareTranscriptSequence = (colorData) => {
    const genomicSequence = sequenceData?.genomicSequence;
    const transcriptSequence = sequenceData?.transcriptSequence;
    if (!genomicSequence) return;
    const substrings: any = [];
    colorData.forEach((element) => {
      if (parseInt(element?.sc_end) > genomicSequence.length) {
        return;
      }
      const subText = genomicSequence.substring(
        parseInt(element.sc_start),
        parseInt(element.sc_end)
      );
      const startIndex = transcriptSequence.indexOf(subText);
      substrings.push({
        type: element.sc_feature,
        start: startIndex,
        end: startIndex + subText.length,
      });
    });
    substrings.sort((a, b) => a.start - b.start);
    const tempTranscriptSequence: any = [];
    substrings.forEach((element) => {
      const subText = transcriptSequence.substring(
        element.start,
        element.end + 1
      );
      if (element.type === "CDS") {
        tempTranscriptSequence.push(
          <span style={{ backgroundColor: "#AABADD" }}>{subText}</span>
        );
      } else if (element.type === "5UTR") {
        tempTranscriptSequence.push(
          <span style={{ backgroundColor: "#A4D4B0" }}>{subText}</span>
        );
      } else if (element.type === "3UTR") {
        tempTranscriptSequence.push(
          <span style={{ backgroundColor: "#E9D2E4" }}>{subText}</span>
        );
      }
    });

    setTranscriptSequence(tempTranscriptSequence);
  };

  const prepareGenomicSequence = (colorData) => {
    const genomicSequence = sequenceData?.genomicSequence;
    if (!genomicSequence) return;
    let startIndex = 0;
    const colorObjects: any = [];
    colorData.forEach((element) => {
      if (parseInt(element?.sc_end) > genomicSequence.length) {
        return;
      }
      const initialString = genomicSequence.substring(
        startIndex,
        parseInt(element.sc_start)
      );
      colorObjects.push(initialString);
      const colorString = genomicSequence.substring(
        parseInt(element.sc_start),
        parseInt(element.sc_end) + 1
      );

      if (element.sc_feature === "CDS") {
        colorObjects.push(
          <span style={{ backgroundColor: "#AABADD" }}>{colorString}</span>
        );
      } else if (element.sc_feature === "5UTR") {
        colorObjects.push(
          <span style={{ backgroundColor: "#A4D4B0" }}>{colorString}</span>
        );
      } else if (element.sc_feature === "3UTR") {
        colorObjects.push(
          <span style={{ backgroundColor: "#E9D2E4" }}>{colorString}</span>
        );
      }
      startIndex = parseInt(element.sc_end) + 1;
    });
    setGenomicSequence(colorObjects);
  };

  const preProcessHighlightData = (
    transcriptStartAndEnd,
    colorData,
    strand
  ) => {
    const transcriptStart = transcriptStartAndEnd?.transcriptStart;
    const transcriptEnd = transcriptStartAndEnd?.transcriptEnd;
    const colorObjects: any = [];
    colorData.forEach((element) => {
      if (strand === "-") {
        const tempData = {
          ...element,
          sc_start: transcriptEnd - parseInt(element.sc_end),
          sc_end: transcriptEnd - parseInt(element.sc_start),
        };
        colorObjects.push(tempData);
      } else {
        const tempData = {
          ...element,
          sc_start: parseInt(element.sc_start) - transcriptStart,
          sc_end: parseInt(element.sc_end) - transcriptStart,
        };
        colorObjects.push(tempData);
      }
    });
    return colorObjects;
  };

  const fetchDataAndHighlightSequenceData = async () => {
    try {
      const transcriptStartAndEnd = await getTranscriptStartAndEnd();
      const strand = await getStandData();
      const colorData = await getSequenceColorData(strand);
      const colorObjects = preProcessHighlightData(
        transcriptStartAndEnd,
        colorData,
        strand
      );
      setColorData(colorObjects);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (selectedGene) {
      getSequenceCoordinates();
      fetchDataAndHighlightSequenceData();
    }
  }, []);

  React.useEffect(() => {
    if (
      sequenceData?.genomicSequence &&
      colorData.length !== 0 &&
      genomicSequence.length === 0
    ) {
      prepareGenomicSequence(colorData);
    }
    if (
      sequenceData?.transcriptSequence &&
      colorData.length !== 0 &&
      transcriptSequence.length === 0
    ) {
      prepareTranscriptSequence(colorData);
    }
  }, [sequenceData, colorData]);

  const legendBoxDimension = 25;

  const downloadGenomicSequence = () => {
    const file = new Blob(
      [
        `> chr1:${genomicCoordinates.start}..${genomicCoordinates.end}   (${
          genomicCoordinates.end - genomicCoordinates.start
        } nucleotides)` +
          "\n" +
          sequenceData?.genomicSequence,
      ],
      { type: "text/plain" }
    );
    saveAs(file, "genomic_sequence.txt");
  };

  const downloadTranscriptSequence = () => {
    const file = new Blob(
      [
        `> ${
          selectedGene?.includes(".") ? selectedGene : selectedGene + ".1"
        } (${sequenceData?.transcriptSequence?.length} nucleotides)` +
          "\n" +
          sequenceData?.transcriptSequence,
      ],
      { type: "text/plain" }
    );
    saveAs(file, "genomic_sequence.txt");
  };

  const downloadCdsSequence = () => {
    const file = new Blob(
      [
        `> ${
          selectedGene?.includes(".") ? selectedGene : selectedGene + ".1"
        } (${sequenceData?.cdsSequence?.length} nucleotides)` +
          "\n" +
          sequenceData?.cdsSequence,
      ],
      { type: "text/plain" }
    );
    saveAs(file, "cds_sequence.txt");
  };

  const downloadProteinSequence = () => {
    const file = new Blob(
      [
        `> ${
          selectedGene?.includes(".") ? selectedGene : selectedGene + ".1.p"
        } (${sequenceData?.proteinSequence?.length} residues)` +
          "\n" +
          sequenceData?.proteinSequence,
      ],
      { type: "text/plain" }
    );
    saveAs(file, "protein_sequence.txt");
  };

  return (
    <Box sx={{ maxHeight: dimentions?.height - 200, overflowY: "scroll" }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="end"
      >
        <Typography variant="h6" sx={{ mr: 2 }}>
          Legend
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" sx={{ mr: 1 }}>
            CDS
          </Typography>
          <Box
            sx={{
              height: legendBoxDimension,
              width: legendBoxDimension,
              backgroundColor: "#AABADD",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" sx={{ mr: 1 }}>
            5UTR
          </Typography>
          <Box
            sx={{
              height: legendBoxDimension,
              width: legendBoxDimension,
              backgroundColor: "#A4D4B0",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" sx={{ mr: 1 }}>
            3UTR
          </Typography>
          <Box
            sx={{
              height: legendBoxDimension,
              width: legendBoxDimension,
              backgroundColor: "#E9D2E4",
            }}
          />
        </Box>
      </Stack>
      <Stack direction="column" spacing={4}>
        <Box>
          <Typography variant="h5">Genomic Sequence</Typography>
          <Typography
            variant="body2"
            fontSize={17}
            sx={{ mb: 1, mt: 1 }}
            fontWeight="bold"
          >{`> chr1:${genomicCoordinates.start}..${genomicCoordinates.end}   (${
            genomicCoordinates.end - genomicCoordinates.start
          } nucleotides)`}</Typography>
          {genomicSequence.length > 0 ? (
            <Box
              sx={{
                p: 2,
                width: dimentions?.width - widthReduceFactor,
                backgroundColor: "#f1f2f4",
                mt: 1,
                borderRadius: 1,
              }}
            >
              <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                {genomicSequence}
              </Typography>
              <Stack direction="row" justifyContent="end" sx={{ mt: 1 }}>
                <Button variant="contained" onClick={downloadGenomicSequence}>
                  Fasta
                </Button>
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                width: dimentions?.width - widthReduceFactor,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f1f2f4",
                mt: 1,
                borderRadius: 1,
              }}
            >
              <CircularProgress size={30} />
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="h5">Transcript Sequence</Typography>
          <Typography
            variant="body2"
            fontSize={17}
            sx={{ mb: 1, mt: 1 }}
            fontWeight="bold"
          >
            {`> ${
              selectedGene.includes(".") ? selectedGene : selectedGene + ".1"
            } (${sequenceData?.transcriptSequence?.length} nucleotides)`}
          </Typography>
          {transcriptSequence.length > 0 ? (
            <Box
              sx={{
                p: 2,
                width: dimentions?.width - widthReduceFactor,
                backgroundColor: "#f1f2f4",
                mt: 1,
                borderRadius: 1,
              }}
            >
              <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                {transcriptSequence}
              </Typography>
              <Stack direction="row" justifyContent="end" sx={{ mt: 1 }}>
                <Button
                  variant="contained"
                  onClick={downloadTranscriptSequence}
                >
                  Fasta
                </Button>
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                width: dimentions?.width - widthReduceFactor,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f1f2f4",
                mt: 1,
                borderRadius: 1,
              }}
            >
              <CircularProgress size={30} />
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="h5">CDs Sequence</Typography>
          <Typography
            variant="body2"
            fontSize={17}
            sx={{ mb: 1, mt: 1 }}
            fontWeight="bold"
          >
            {`> ${
              selectedGene.includes(".") ? selectedGene : selectedGene + ".1"
            } (${sequenceData?.cdsSequence?.length} nucleotides)`}
          </Typography>
          {sequenceData?.cdsSequence ? (
            <Box
              sx={{
                p: 2,
                width: dimentions?.width - widthReduceFactor,
                backgroundColor: "#f1f2f4",
                mt: 1,
                borderRadius: 1,
              }}
            >
              <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                <span style={{ backgroundColor: "#AABADD" }}>
                  {sequenceData?.cdsSequence}
                </span>
              </Typography>
              <Stack direction="row" justifyContent="end" sx={{ mt: 1 }}>
                <Button variant="contained" onClick={downloadCdsSequence}>
                  Fasta
                </Button>
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                width: dimentions?.width - widthReduceFactor,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f1f2f4",
                mt: 1,
                borderRadius: 1,
              }}
            >
              <CircularProgress size={30} />
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="h5">Protein Sequence</Typography>
          <Typography
            variant="body2"
            fontSize={17}
            sx={{ mb: 1, mt: 1 }}
            fontWeight="bold"
          >
            {`> ${
              selectedGene.includes(".") ? selectedGene : selectedGene + ".1.p"
            } (${sequenceData?.proteinSequence?.length} residues)`}
          </Typography>
          {sequenceData?.proteinSequence ? (
            <Box
              sx={{
                p: 2,
                width: dimentions?.width - widthReduceFactor,
                backgroundColor: "#f1f2f4",
                mt: 1,
                borderRadius: 1,
              }}
            >
              <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
                {sequenceData?.proteinSequence}
              </Typography>
              <Stack direction="row" justifyContent="end" sx={{ mt: 1 }}>
                <Button variant="contained" onClick={downloadProteinSequence}>
                  Fasta
                </Button>
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                width: dimentions?.width - widthReduceFactor,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f1f2f4",
                mt: 1,
                borderRadius: 1,
              }}
            >
              <CircularProgress size={30} />
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
