import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextareaAutosize } from "@mui/material";

export const NeoUpgradeOldDashboardModal = ({
  open,
  text,
  clearOldDashboard,
  loadDashboard,
}) => {
  return (
    <div>
      <Dialog
        maxWidth={"lg"}
        open={open == true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Old Dashboard Found</DialogTitle>
        <DialogContent>
          We've found a dashboard built with an old version of NeoDash. Would
          you like to attempt an upgrade, or start from scratch?
          <br />
          <b>Make sure you back up this dashboard first!</b>
          <br />
          <Button
            onClick={() => {
              localStorage.removeItem("neodash-dashboard");
              clearOldDashboard();
            }}
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              marginRight: "20px",
            }}
            variant="contained"
            size="large"
            endIcon={<DeleteIcon color={"white"} />}
          >
            Delete Old Dashboard
          </Button>
          <Button
            onClick={() => {
              localStorage.removeItem("neodash-dashboard");
              loadDashboard(text);
              clearOldDashboard();
            }}
            style={{
              marginTop: "20px",
              marginRight: "6px",
              marginBottom: "20px",
            }}
            color="info"
            variant="contained"
            size="large"
          >
            Upgrade
          </Button>
          <TextareaAutosize
            style={{
              minHeight: "200px",
              width: "100%",
              border: "1px solid lightgray",
            }}
            className={"textinput-linenumbers"}
            onChange={(e) => {}}
            value={text ? text : ""}
            aria-label=""
            placeholder=""
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NeoUpgradeOldDashboardModal;
