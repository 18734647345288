import client from "./client";

export const getAvailableDatabases = async () => {
  try {
    const { data } = await client.get("/api/db");
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
