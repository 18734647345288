export const parseStringForCoordinates = (string) => {
  const splits = string.split("<map");
  let filteredSplits = splits[1].split("</map>");
  filteredSplits = filteredSplits[0].split("<area");
  const newSplits = filteredSplits.splice(1, filteredSplits.length - 1);
  const valuesWithCoordinates = newSplits.map((item, index) => {
    const array = item.split(" ");
    const coords = array[2];
    const href = array[3];
    let coordinates = coords.substring(8, coords.length - 1);
    coordinates = coordinates.split(",");
    let link = href.substring(6, href.length - 1);
    return {
      name: `area-${index}`,
      shape: "rect",
      coords: coordinates.map((item) => parseFloat(item)),
      preFillColor: "transparent",
      fillColor: "transparent",
      lineWidth: 0,
      strokeColor: "#fff",
      href: link,
    };
  });

  return valuesWithCoordinates;
};
