import React from "react";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const textFieldStyle = {
  width: "155px",
  marginBottom: "10px",
  marginRight: "10px",
  marginLeft: "10px",
};

const NeoField = ({
  label,
  value,
  style = textFieldStyle,
  choices = [<div key={0}></div>],
  onChange,
  onClick = (val) => {},
  numeric = false,
  select = false,
  disabled = undefined,
  variant = undefined,
  helperText = undefined,
  defaultValue = undefined,
  multi = false,
  clearValueFunc,
  placeholder = "",
}) => {
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      select={select}
      style={style}
      key={label}
      variant={variant}
      label={label}
      helperText={helperText}
      disabled={disabled}
      multiline={multi}
      rows={multi ? 2 : 1}
      maxRows={multi ? 4 : 1}
      value={value != null ? value : defaultValue}
      onClick={(e) => {
        onClick(e.target.textContent);
      }}
      onChange={(event) => {
        if (!numeric) {
          onChange(event.target.value);
        } else if (
          event.target.value.toString().length == 0 ||
          event.target.value.endsWith(".") ||
          event.target.value.startsWith("-")
        ) {
          onChange(event.target.value);
        } else if (!isNaN(event.target.value)) {
          onChange(Number(event.target.value));
        }
      }}
      InputProps={
        clearValueFunc && {
          endAdornment: (
            <IconButton onClick={clearValueFunc} size="small">
              <ClearIcon />
            </IconButton>
          ),
        }
      }
      placeholder={placeholder}
    >
      {choices}
    </TextField>
  );
};

export default NeoField;
