import React, { useState, useEffect } from "react";
import { ResponsiveTreeMap } from "@nivo/treemap";
import { ExtendedChartReportProps } from "./visualizations/VisualizationProps";
import { getEnrichmentData } from "../api/encrichment";
import {
  createEnrichmentDataFromArray,
  createEnrichmentDataFromOtherArrays,
  filterDataAccordingToFilters,
  getActiveListMinMaxValue,
  shuffleArray,
} from "./util/EnrichmentUtil";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, CircularProgress, Stack, useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ENRICHMENT_EXPERIMENTS } from "../constants";

const ENRICHEMENT_TOPICS = ["Enrichment", "MF", "BP", "CC"];
const NUMBER_KEYS = ["mpat", "mt", "npat", "nt", "padj", "pval", "m", "n"];

const legendColors = [
  "#D0D4CA",
  "#FFBB00",
  "#FFAA10",
  "#FF9900",
  "#FF7701",
  "#FF5501",
  "#FF3200",
  "#CB2222",
  "#AA0101",
];

const legendValues = [
  "1",
  "0.1",
  "0.01",
  "10e-4",
  "10e-5",
  "10e-6",
  "10e-7",
  "10e-8",
  "10e-9",
];

const TEXT_PIXEL_SIZE = 6;

export default function EnrichmentChart(props: ExtendedChartReportProps) {
  const { settings }: any = props;
  const { enableTable } = settings;
  const dashboardState = useSelector((state: any) => state?.dashboard);
  const theme = useTheme();
  const [tableColumns, setTableColumns] = useState([]);
  const geneList = dashboardState?.settings?.parameters?.neodash_genelist_name;

  const [enrichmentData, setEnrichmentData] = useState<any>(null);
  const [selectedTarget, setSelectedTarget] = useState("go");
  const [responseData, setResponseData] = useState<any>(null);
  const [cellValueType, setCellValueType] = useState("formattedValue");
  const [filters, setFilters] = useState([]);
  const [activeListMinMax, setActiveListMinMax] = useState({ min: 0, max: 0 });
  const [loading, setLoading] = useState(false);
  const [activeBackgroundGeneList, setActiveBackgroundGeneList] =
    useState("aspwood");
  const [backgroundGeneLists, setBackgroundGeneLists] = useState([
    ...ENRICHMENT_EXPERIMENTS,
  ]);

  const prepareBackgroundGeneLists = () => {
    const tempBackgroundGeneLists = [...backgroundGeneLists];
    const addedGeneLists = dashboardState?.addedGenieLists;
    addedGeneLists.forEach((geneList) => {
      if (!geneList?.active_genelist) {
        tempBackgroundGeneLists.push({
          label: geneList.genelist_name,
          value: "genelist-" + geneList.genelist,
        });
      }
    });
    setBackgroundGeneLists([...tempBackgroundGeneLists]);
  };

  const handleCellValueType = (event, newType) => {
    if (!newType) return;
    setCellValueType(newType);
  };

  const sendToRevigo = async () => {
    let tempString = "#PlantGenIE Enrichment Results \n";
    responseData.go.forEach((element) => {
      tempString += `${element.id} ${element.pval.toPrecision(4)} \n`;
    });
    const form = document.createElement("form");
    const revigo_text_element = document.createElement("textarea");
    form.method = "POST";
    form.action = "http://revigo.irb.hr";
    form.acceptCharset = "UTF-8";
    revigo_text_element.textContent = tempString;
    revigo_text_element.name = "inputGoList";
    form.appendChild(revigo_text_element);
    document.body.appendChild(form);
    form.target = "_blank";
    form.submit();
  };

  const downloadImageAsSVG = () => {
    const fileName = prompt("Please enter file name", "image");
    if (!fileName) {
      alert("Invalid filename");
      return;
    }
    const element = document.getElementById("chart-container");
    let serializer = new XMLSerializer();
    let source = serializer.serializeToString(
      element!.getElementsByTagName("svg")[0]
    );
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
    let url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);
    let link = document.createElement("a");

    link.download = fileName + ".svg";
    link.href = url;
    link.click();
  };

  const initializeTableColumns = (keys) => {
    const columns = keys.map((key) => {
      if (NUMBER_KEYS.includes(key)) {
        return { field: key, headerName: key, width: 200, type: "number" };
      }
      return { field: key, headerName: key, width: 200, filterable: false };
    });
    setTableColumns(columns);
  };

  const fetchEnrichmentData = async (geneList, backgroundGeneList) => {
    setLoading(true);
    try {
      const response = await getEnrichmentData(geneList, backgroundGeneList);
      setLoading(false);
      if (response.status === 200) {
        initializeTableColumns(Object.keys(response.data.go[0]));
        setResponseData(response.data);
        getActiveListMinMaxValue(
          response.data[selectedTarget],
          setActiveListMinMax
        );
        const processedData = createEnrichmentDataFromArray(
          response?.data[selectedTarget],
          geneList.length
        );
        setEnrichmentData(processedData);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchData = async (activeBackgroundGeneList) => {
    setLoading(true);
    try {
      const response = await getEnrichmentData(
        geneList,
        activeBackgroundGeneList
      );
      setLoading(false);
      if (response.status === 200) {
        initializeTableColumns(Object.keys(response.data.go[0]));
        setResponseData(response.data);
        getActiveListMinMaxValue(
          response.data[selectedTarget],
          setActiveListMinMax
        );
        const processedData = createEnrichmentDataFromArray(
          response?.data[selectedTarget],
          geneList.length
        );
        setEnrichmentData(processedData);
      }
    } catch (error) {
      setLoading(false);
      alert("Error occured");
    }
  };

  useEffect(() => {
    fetchData(activeBackgroundGeneList);
  }, [geneList]);

  useEffect(() => {
    prepareBackgroundGeneLists();
  }, []);

  const getColorOfNode = (node) => {
    let activeData;

    if (selectedTarget === "kegg") {
      activeData = responseData.kegg;
    } else if (selectedTarget === "go") {
      activeData = responseData.go;
    } else {
      activeData = responseData.pfam;
    }
    if (!node?.data?.loc) {
      return "#fff";
    }

    const nodeName = node?.data?.name;
    const findedElement = activeData.find(
      (element) => element.name === nodeName
    );
    if (!findedElement) {
      return "#fff";
    }
    const value = parseFloat(findedElement.pval);
    if (value <= 10e-9) {
      return legendColors[8];
    } else if (value <= 10e-8) {
      return legendColors[7];
    } else if (value <= 10e-7) {
      return legendColors[6];
    } else if (value <= 10e-6) {
      return legendColors[5];
    } else if (value <= 10e-5) {
      return legendColors[4];
    } else if (value <= 10e-4) {
      return legendColors[3];
    } else if (value <= 0.01) {
      return legendColors[2];
    } else if (value <= 0.1) {
      return legendColors[1];
    } else {
      return legendColors[0];
    }
  };

  const handleChangeBackgroundGeneList = (event) => {
    if (event.target.value === activeBackgroundGeneList) return;
    setActiveBackgroundGeneList(event.target.value);
    if (event.target.value.includes("genelist-")) {
      const geneString = event.target.value.split("-")[1];
      const genes = geneString.split(",");
      fetchEnrichmentData(genes, "gene-list");
    } else {
      fetchData(event.target.value);
    }
  };

  const handleChange = (event) => {
    setSelectedTarget(event.target.value);
    initializeTableColumns(Object.keys(responseData[event.target.value][0]));
    if (event.target.value === "kegg") {
      getActiveListMinMaxValue(responseData.kegg, setActiveListMinMax);
      const processedData = createEnrichmentDataFromOtherArrays(
        responseData.kegg,
        geneList.length
      );
      setEnrichmentData(processedData);
    } else if (event.target.value === "go") {
      getActiveListMinMaxValue(responseData.go, setActiveListMinMax);
      const processedData = createEnrichmentDataFromArray(
        responseData.go,
        geneList.length
      );
      setEnrichmentData(processedData);
    } else if (event.target.value === "pfam") {
      getActiveListMinMaxValue(responseData.pfam, setActiveListMinMax);
      const processedData = createEnrichmentDataFromArray(
        responseData.pfam,
        geneList.length
      );
      setEnrichmentData(processedData);
    }
  };

  const rollTheDice = () => {
    if (!enrichmentData?.children) return;
    const enrichmentChildren = enrichmentData?.children;

    for (const element of enrichmentChildren) {
      const newChild = element;
      newChild.children = shuffleArray(newChild.children);
    }
    const newEnrichmentData = {
      ...enrichmentData,
      children: enrichmentChildren,
    };
    setEnrichmentData(newEnrichmentData);
  };

  const handleChangeEnrichmentData = (newData) => {
    if (selectedTarget === "kegg") {
      const processedData = createEnrichmentDataFromOtherArrays(
        newData,
        geneList.length
      );
      setEnrichmentData(processedData);
    } else if (selectedTarget === "go" || selectedTarget === "pfam") {
      const processedData = createEnrichmentDataFromArray(
        newData,
        geneList.length
      );
      setEnrichmentData(processedData);
    }
  };

  const handleChangeFilters = (model) => {
    if (model?.items && model?.items?.length > 0) {
      setFilters(model.items);
      const newData = filterDataAccordingToFilters(
        responseData[selectedTarget],
        model.items
      );
      handleChangeEnrichmentData(newData);
    } else {
      setFilters([]);
      handleChangeEnrichmentData(responseData[selectedTarget]);
    }
  };

  return (
    <>
      <div style={{ height: "100%", width: "100%" }}>
        {responseData && (
          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <Box sx={{ minWidth: 120, maxWidth: 500 }}>
              <FormControl fullWidth>
                <InputLabel id="background-gene-list">
                  Select Background Gene list
                </InputLabel>
                <Select
                  labelId="background-gene-list"
                  id="background-gene-list"
                  value={activeBackgroundGeneList}
                  label="Select Target"
                  size="small"
                  sx={{ minWidth: 200 }}
                  onChange={handleChangeBackgroundGeneList}
                >
                  {backgroundGeneLists.map((experiment) => {
                    return (
                      <MenuItem value={experiment.value}>
                        {experiment.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 120, maxWidth: 300 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Domain
                </InputLabel>
                <Select
                  key={selectedTarget}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedTarget}
                  label="Select Target"
                  size="small"
                  onChange={handleChange}
                >
                  <MenuItem value={"go"}>Go</MenuItem>
                  <MenuItem value={"kegg"}>Kegg</MenuItem>
                  <MenuItem value={"pfam"}>Pfam</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Button onClick={rollTheDice} variant="outlined">
              Rearange the tree
            </Button>
            <ToggleButtonGroup
              value={cellValueType}
              exclusive
              onChange={handleCellValueType}
              aria-label="cell value"
              size="small"
            >
              <ToggleButton value="id" aria-label="left aligned">
                Name
              </ToggleButton>
              <ToggleButton value="formattedValue" aria-label="centered">
                Value
              </ToggleButton>
            </ToggleButtonGroup>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography>Export : </Typography>
              <Button onClick={downloadImageAsSVG} variant="outlined">
                SVG
              </Button>
              <Button onClick={sendToRevigo} variant="outlined">
                Send to Revigo
              </Button>
            </Stack>
          </Stack>
        )}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 500,
            }}
          >
            <CircularProgress size={50} />
          </Box>
        )}
        {enrichmentData && !loading && (
          <div
            id="chart-container"
            style={{ height: enableTable ? "40%" : "80%" }}
          >
            <ResponsiveTreeMap
              data={enrichmentData}
              identity="name"
              value="loc"
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              labelSkipSize={15}
              colors={getColorOfNode}
              nodeOpacity={1}
              label={(e) => {
                if (ENRICHEMENT_TOPICS.includes(e.id)) {
                  return e.id;
                } else {
                  const dimension = e.labelRotation >= 0 ? e.width : e.height;

                  if (
                    cellValueType !== "formattedValue" &&
                    dimension < e.id.length * TEXT_PIXEL_SIZE
                  ) {
                    const characterCount = e.id.length / TEXT_PIXEL_SIZE;
                    return e.id.substring(0, characterCount) + "...";
                  }
                  return e[cellValueType];
                }
              }}
              labelTextColor={{
                from: "color",
                modifiers: [["brighter", 2]],
              }}
              parentLabelPosition="left"
              parentLabelTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.1]],
              }}
            />
            <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h6">
                  Color code based on P-value:
                </Typography>
                {legendValues.map((item, index) => {
                  return (
                    <div
                      key={item}
                      style={{
                        width: 80,
                        height: 40,
                        backgroundColor: legendColors[index],
                        color: legendColors[index] === "#fff" ? "#000" : "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={800}
                      >{`${item}`}</Typography>
                    </div>
                  );
                })}
              </Stack>
            </Stack>
          </div>
        )}
        {enableTable && responseData && tableColumns && (
          <div style={{ height: "40%", marginTop: 20 }}>
            <DataGrid
              rows={responseData[selectedTarget]}
              columns={tableColumns}
              components={{ Toolbar: GridToolbar }}
              onFilterModelChange={handleChangeFilters}
              initialState={{
                // filter: {
                //   filterModel: {
                //     items: [
                //       {
                //         columnField: "npat",
                //         operatorValue: ">",
                //         value: 0.5,
                //       },
                //     ],
                //   },
                // },
                pagination: {
                  page: 0,
                  pageSize: 20,
                },
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
