import axios from "axios";

const baseURL = "https://api.plantgenie.org/plugins";

const axiosClient = axios.create({ baseURL: baseURL });

export async function getExImageData(experiment, gene) {
  return axiosClient.get(
    "/eximage/service/eplant_service.php?primaryGene=" +
      gene +
      "&view=" +
      experiment
  );
}

export async function getImageData() {
  return axios.get(
    "https://api.plantgenie.org/plugins/eximage/svg/potra/exatlas.svg"
  );
}
