import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Drawer,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getExperimentData } from "../../api/heatmap";
import { updateGlobalParametersThunk } from "../../settings/SettingsThunks";
import GeneListComponent from "../genelistcomponent";
import { Neo4jContext, Neo4jContextState } from "use-neo4j/dist/neo4j.context";
import {
  changeActiveGenieListThunk,
  deleteGenieListThunk,
  updateActiveGenelistThunk,
} from "../../dashboard/DashboardThunks";
import { updateStandAlone } from "../../application/ApplicationActions";
import { ACTIVE_GENELIST } from "../../constants";

type ExperimentType = {
  experiment_id: string;
  experiment_name: string;
  experiment_value: string;
};

export default function RightDrawer(props) {
  const { openRightDrawer, setOpenRightDrawer } = props;
  const dispatch = useDispatch();
  const { driver } = useContext<Neo4jContextState>(Neo4jContext);

  const [selectedExperiment, setSelectedExperiment] =
    useState<ExperimentType>();
  const [experiments, setExperiments] = useState<ExperimentType[]>([]);
  const dashboard = useSelector((state: any) => state?.dashboard);
  const applicationState = useSelector((state: any) => state?.application);
  const [viewerMode, setViewMode] = useState(!applicationState?.admin);
  const [activeGeneListId, setActiveGenelistId] = useState(
    localStorage.getItem(ACTIVE_GENELIST)
  );

  async function getExperiments() {
    try {
      const response = await getExperimentData();
      if (response?.status === 200) {
        setExperiments(response?.data);
        setSelectedExperiment(response?.data[0]);
        dispatch(
          updateGlobalParametersThunk({
            neodash_experiment_value: response?.data[0]?.experiment_value,
          })
        );
      }
    } catch (error) {}
  }

  const changeActiveGenieList = (item) => {
    if (item?.id) {
      setActiveGenelistId(item?.id);
      localStorage.setItem(ACTIVE_GENELIST, item?.id);
      dispatch(updateActiveGenelistThunk(item));
      dispatch(
        updateGlobalParametersThunk({
          neodash_genelist_name: [...item?.genes],
        })
      );
    }
  };

  const deleteGenieList = (item) => {
    dispatch(deleteGenieListThunk(item.id));
  };

  useEffect(() => {
    getExperiments();
  }, []);

  function handleSelectExperiment(event) {
    const experimentId = event.target.value;
    const selectedExperiment = experiments.find(
      (item: ExperimentType) => item.experiment_id === experimentId
    );
    setSelectedExperiment(selectedExperiment);
    dispatch(
      updateGlobalParametersThunk({
        neodash_experiment_value: selectedExperiment?.experiment_value,
      })
    );
  }

  return (
    <div>
      <Drawer
        anchor="right"
        open={openRightDrawer}
        onClose={() => setOpenRightDrawer(false)}
        sx={{ width: 350 }}
        style={{
          right: "0",
          left: "auto",
        }}
      >
        <Box sx={{ mt: 10, pl: 2 }}>
          <FormLabel id="select-experiment-label" color="primary">
            Your Genelists
          </FormLabel>
          <Box sx={{ mt: 1, maxHeight: 350, overflowY: "scroll" }}>
            {dashboard?.addedGenieLists?.map((item) => (
              <GeneListComponent
                item={item}
                key={item.id}
                changeActiveGenieList={changeActiveGenieList}
                deleteGenieList={deleteGenieList}
                activeGeneListId={activeGeneListId}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ width: 350, pl: 2, mt: 3 }}>
          <Box sx={{ maxWidth: 350 }}>
            <FormControl fullWidth>
              <FormLabel id="select-experiment-label" color="primary">
                Select Experiment
              </FormLabel>
              <RadioGroup
                aria-labelledby="select-experiment-label"
                name="select-experiment"
                value={selectedExperiment?.experiment_id}
                onChange={handleSelectExperiment}
              >
                {experiments.map((item) => (
                  <FormControlLabel
                    value={item.experiment_id}
                    control={<Radio />}
                    label={item.experiment_name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>

          {applicationState?.admin && (
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                onChange={(event, checked) => {
                  setViewMode(!viewerMode);
                  dispatch(updateStandAlone(checked));
                }}
                control={<Switch />}
                label="Switch to viewer mode"
              />
            </Box>
          )}
        </Box>
      </Drawer>
    </div>
  );
}
