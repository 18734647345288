import { IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Stack, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { updateGenieListNameThunk } from "../../dashboard/DashboardThunks";

export default function GeneListComponent(props) {
  const { item, changeActiveGenieList, deleteGenieList, activeGeneListId } =
    props;

  const dispatch = useDispatch();

  const [editGenieName, setEditGenieName] = useState(false);
  const [genieName, setGenieName] = useState(item?.name);

  return (
    <div key={item?.id} style={{ paddingLeft: 10 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <div style={{ width: 300 }}>
          {editGenieName ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <TextField
                label="Genie Name"
                value={genieName}
                size="small"
                onChange={(event) => setGenieName(event.target.value)}
              />
              <IconButton
                size="small"
                style={{ width: 40, height: 40 }}
                onClick={() => {
                  dispatch(updateGenieListNameThunk(item, genieName));
                  setEditGenieName(false);
                }}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                size="small"
                style={{ width: 40, height: 40 }}
                onClick={() => {
                  setEditGenieName(false);
                  setGenieName(item?.name);
                }}
              >
                <ClearIcon />
              </IconButton>
            </Stack>
          ) : (
            <Typography
              sx={{
                color: item?.id == activeGeneListId ? "primary.main" : "auto",
                cursor: "pointer",
              }}
              fontWeight={800}
              onClick={() => {
                changeActiveGenieList(item);
              }}
            >
              {`${item?.name}`}
            </Typography>
          )}
        </div>
        {!editGenieName && (
          <div
            style={{
              width: 50,
            }}
          >
            <Typography
              color={item?.active ? "primary.main" : ""}
              variant="body2"
              fontWeight={800}
            >
              {item?.genes?.length}
            </Typography>
          </div>
        )}
        {!editGenieName && (
          <IconButton
            onClick={() => {
              setEditGenieName(true);
            }}
          >
            <EditIcon
              sx={{ color: item?.active_genelist ? "primary.main" : "" }}
              fontSize="small"
            />
          </IconButton>
        )}
        {!editGenieName && (
          <IconButton onClick={() => deleteGenieList(item)}>
            <DeleteIcon
              style={{ color: item?.active_genelist ? "red" : "" }}
              fontSize="small"
            />
          </IconButton>
        )}
      </Stack>
    </div>
  );
}
