import React from 'react';
import Circle from '@mui/icons-material/Circle';
import Title from '@mui/icons-material/Title';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import { Rule } from '../RuleStyleLegend';
import { ParentContainer, StyledConditionText } from './RuleStyleLegendItem.style';
import { Tooltip } from '@mui/material';

interface Props {
  rule: Rule
}

const getLegendIcon = ({ customization, customizationValue }) => {
  const defaultStyleProps = {
    color: customizationValue,
    paddingTop: '0.2rem',
  };

  switch (customization) {
    case 'node color':
      return <Circle data-testid="circle-icon" sx={defaultStyleProps} fontSize="medium" />;
    case 'node label color':
      return <Title data-testid="title-icon" sx={defaultStyleProps} fontSize="medium" />;
    case 'relationship arrow color':
      return <ArrowRightAlt data-testid="arrow-icon" sx={defaultStyleProps} fontSize="medium" />;
    default:
      return <div>Icon not found</div>;
  }
};

const RuleStyleLegendItem = ({ rule }: Props) => {
  // Remove the type names
  const field = rule.field.split('.');

  return (
    <ParentContainer>
      <div>
        <Tooltip title={rule.customization}>
          {getLegendIcon({ ...rule })}
        </Tooltip>
      </div>
      <div>{field.length > 0 ? field.pop() : rule.field}</div>
      <StyledConditionText>{rule.condition}</StyledConditionText>
      <div>{rule.value}</div>
    </ParentContainer>
  )
};

export default RuleStyleLegendItem;
