import { createTheme, ThemeOptions } from "@mui/material";
import { GENIEBOARD_THEMES } from "../../constants";

const getThemes = (): ThemeOptions[] => {
  const themes = GENIEBOARD_THEMES.map((item) => {
    return createTheme({
      palette: {
        primary: {
          main: item.colors[0],
        },
        secondary: {
          main: item.colors[1],
        },
        info: {
          main: item.colors[2],
        },
      },
      typography: {
        fontFamily: "Nunito Sans",
        button: {
          textTransform: "none",
        },
      },
    });
  });
  return themes;
};

export default getThemes;
