import React from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { ChartProps } from "./Chart";

const dummyData = [
  {
    id: "Japan",
    data: [
      {
        x: "Train",
        y: 8573,
      },
      {
        x: "Subway",
        y: 94368,
      },
      {
        x: "Bus",
        y: -88542,
      },
      {
        x: "Car",
        y: -31687,
      },
      {
        x: "Boat",
        y: 19800,
      },
      {
        x: "Moto",
        y: 70340,
      },
      {
        x: "Moped",
        y: 52070,
      },
      {
        x: "Bicycle",
        y: 12779,
      },
      {
        x: "Others",
        y: 1405,
      },
    ],
  },
  {
    id: "France",
    data: [
      {
        x: "Train",
        y: 97173,
      },
      {
        x: "Subway",
        y: 21103,
      },
      {
        x: "Bus",
        y: -93644,
      },
      {
        x: "Car",
        y: -48088,
      },
      {
        x: "Boat",
        y: 90409,
      },
      {
        x: "Moto",
        y: 34602,
      },
      {
        x: "Moped",
        y: 96712,
      },
      {
        x: "Bicycle",
        y: -19816,
      },
      {
        x: "Others",
        y: -52032,
      },
    ],
  },
  {
    id: "US",
    data: [
      {
        x: "Train",
        y: -61369,
      },
      {
        x: "Subway",
        y: 60299,
      },
      {
        x: "Bus",
        y: 10805,
      },
      {
        x: "Car",
        y: -41159,
      },
      {
        x: "Boat",
        y: 71633,
      },
      {
        x: "Moto",
        y: -55662,
      },
      {
        x: "Moped",
        y: 84259,
      },
      {
        x: "Bicycle",
        y: 54849,
      },
      {
        x: "Others",
        y: 55365,
      },
    ],
  },
  {
    id: "Germany",
    data: [
      {
        x: "Train",
        y: -4433,
      },
      {
        x: "Subway",
        y: -47520,
      },
      {
        x: "Bus",
        y: -58641,
      },
      {
        x: "Car",
        y: -72829,
      },
      {
        x: "Boat",
        y: -42279,
      },
      {
        x: "Moto",
        y: -44417,
      },
      {
        x: "Moped",
        y: -39019,
      },
      {
        x: "Bicycle",
        y: -57890,
      },
      {
        x: "Others",
        y: -99320,
      },
    ],
  },
  {
    id: "Norway",
    data: [
      {
        x: "Train",
        y: -95875,
      },
      {
        x: "Subway",
        y: -48346,
      },
      {
        x: "Bus",
        y: 47443,
      },
      {
        x: "Car",
        y: 74984,
      },
      {
        x: "Boat",
        y: -26159,
      },
      {
        x: "Moto",
        y: 44424,
      },
      {
        x: "Moped",
        y: -62726,
      },
      {
        x: "Bicycle",
        y: 51121,
      },
      {
        x: "Others",
        y: 23787,
      },
    ],
  },
  {
    id: "Iceland",
    data: [
      {
        x: "Train",
        y: -75021,
      },
      {
        x: "Subway",
        y: 95010,
      },
      {
        x: "Bus",
        y: 50322,
      },
      {
        x: "Car",
        y: 1192,
      },
      {
        x: "Boat",
        y: -5158,
      },
      {
        x: "Moto",
        y: 52153,
      },
      {
        x: "Moped",
        y: 38793,
      },
      {
        x: "Bicycle",
        y: -1771,
      },
      {
        x: "Others",
        y: -81588,
      },
    ],
  },
  {
    id: "UK",
    data: [
      {
        x: "Train",
        y: -12524,
      },
      {
        x: "Subway",
        y: -48582,
      },
      {
        x: "Bus",
        y: -96521,
      },
      {
        x: "Car",
        y: 92041,
      },
      {
        x: "Boat",
        y: 17706,
      },
      {
        x: "Moto",
        y: -23279,
      },
      {
        x: "Moped",
        y: 34830,
      },
      {
        x: "Bicycle",
        y: 91610,
      },
      {
        x: "Others",
        y: 30888,
      },
    ],
  },
  {
    id: "Vietnam",
    data: [
      {
        x: "Train",
        y: 24850,
      },
      {
        x: "Subway",
        y: -56964,
      },
      {
        x: "Bus",
        y: 61922,
      },
      {
        x: "Car",
        y: 49015,
      },
      {
        x: "Boat",
        y: 89592,
      },
      {
        x: "Moto",
        y: 63141,
      },
      {
        x: "Moped",
        y: -67344,
      },
      {
        x: "Bicycle",
        y: 46263,
      },
      {
        x: "Others",
        y: -62173,
      },
    ],
  },
];

function preProcessData(data) {
  let processedData: any = [];
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    const fields = element["_fields"];
    const geneName = fields[0];
    let found = false;
    processedData.forEach((cotainedElement) => {
      if (cotainedElement.id == geneName) {
        found = true;
        cotainedElement.data.push({
          x: fields[2],
          y: fields[1],
        });
      }
    });
    if (!found) {
      processedData.push({
        id: geneName,
        data: [
          {
            x: fields[2],
            y: fields[1],
          },
        ],
      });
    }
  }
  return processedData;
}

export default function NivoHeatMap(props: ChartProps) {
  const records = props.records;

  return (
    <ResponsiveHeatMap
      data={preProcessData(records)}
      margin={{ top: 60, right: 90, bottom: 60, left: 90 }}
      valueFormat=">-.2s"
      axisTop={{
        tickSize: 3,
        tickPadding: 3,
        tickRotation: -90,
        legend: "",
        legendOffset: 46,
        truncateTickAt: 0,
      }}
      axisRight={{
        tickSize: 2,
        tickPadding: 2,
        tickRotation: 0,
        legend: "Gene",
        legendPosition: "middle",
        legendOffset: 70,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 2,
        tickPadding: 2,
        tickRotation: 0,
        legend: "Gene",
        legendPosition: "middle",
        legendOffset: -72,
        truncateTickAt: 0,
      }}
      colors={{
        type: "diverging",
        scheme: "red_yellow_blue",
        divergeAt: 0.5,
        minValue: 0,
        maxValue: 10,
      }}
      emptyColor="#555555"
      legends={[
        {
          anchor: "bottom",
          translateX: 0,
          translateY: 30,
          length: 400,
          thickness: 8,
          direction: "row",
          tickPosition: "after",
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: ">-.2s",
          title: "Value →",
          titleAlign: "start",
          titleOffset: 4,
        },
      ]}
    />
  );
}
