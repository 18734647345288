import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Badge } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Configures setting the current Neo4j database connection for the dashboard.
 */
export const NeoRelationshipHelpModal = ({ open, handleClose }) => {
  return (
    <Dialog
      maxWidth={"lg"}
      open={open == true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Relationship types
        <IconButton
          onClick={handleClose}
          style={{ padding: "3px", float: "right" }}
        >
          <Badge badgeContent={""}>
            <CloseIcon />
          </Badge>
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: "1000px" }}>
        <iframe
          style={{
            width: "100%",
            border: "none",
            marginBottom: "-5px",
            height: "1000px",
            overflow: "hidden",
            marginTop: "-5px",
          }}
          src="https://help.plantgenie.org"
        />
      </DialogContent>
    </Dialog>
  );
};

export default NeoRelationshipHelpModal;
