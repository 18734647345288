export const CHROMOSOME_CUM_SUM = {
  chr1: 0,
  chr2: 53234430,
  chr3: 76628579,
  chr4: 98782267,
  chr5: 119496579,
  chr6: 141215915,
  chr7: 165082078,
  chr8: 178971858,
  chr9: 195900634,
  chr10: 207869202,
  chr11: 227758339,
  chr12: 244568642,
  chr13: 258206615,
  chr14: 271908153,
  chr15: 288197579,
  chr16: 301058894,
  chr17: 314427297,
  chr18: 331956289,
  chr19: 345421245,
  chr20: 361795894,
};
