import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  TextField,
  Autocomplete,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { ChartProps } from "./Chart";
import {
  addPageWithDetailsThunk,
  removePageThunk,
  searchGeneByTextThunk,
} from "../dashboard/DashboardThunks";
import { Neo4jContext, Neo4jContextState } from "use-neo4j/dist/neo4j.context";
import {
  getBlast,
  getBlastDatabases,
  getSequence,
  validateBlast,
} from "../api/blast";
import { DataGrid } from "@mui/x-data-grid";
import { parseStringForCoordinates } from "../utils/blastUtil";
import { BLAST_CHROMOSOME_NAME, BLAST_JBROWSE_UUID } from "../constants";
import { addReportThunk } from "../page/PageThunks";
import { CARD_INITIAL_STATE } from "../card/CardReducer";

const neocliodeProgrammes = ["blastn", "blastx", "tblastx"];

const blastProgrammes = [
  {
    label: "BLASTN - nucleotide query nucleotide db",
    value: "blastn",
  },
  {
    label: "BLASTX - translated (6 frames) nucleotide query to protein db",
    value: "blastx",
  },
  {
    label: "BLASTP - protein query to protein db",
    value: "blastp",
  },
  {
    label: "TBLASTN - protein query to translated (6 frames) nucleotide db",
    value: "tblastn",
  },
  {
    label:
      "TBLASTX - translated (6 frames) nucleotide query to translated (6 frames) nucleotide db",
    value: "tblastx",
  },
];

const tableColumns = [
  {
    field: "query_id",
    headerName: "Query ID",
    width: 150,
  },
  {
    field: "name_of_hit",
    headerName: "Name of hit",
    width: 150,
  },
  {
    field: "accession_of_hit",
    headerName: "Accession of hit",
    width: 150,
  },
  {
    field: "average_bit_score",
    headerName: "Average bit score",
    width: 150,
  },
  {
    field: "top_bit_score",
    headerName: "Top bit score",
    width: 150,
  },
  {
    field: "average_evalue",
    headerName: "Average evalue",
    width: 150,
  },
  {
    field: "lowest_evalue",
    headerName: "Lowest evalue",
    width: 150,
  },
  {
    field: "average_identity",
    headerName: "Average identity",
    width: 150,
  },
  {
    field: "average_similarity",
    headerName: "Average similarity",
    width: 150,
  },
];

const scoringMatrix = [
  {
    id: "1",
    value: "BLOSUM62",
    label: "BLOSUM62",
  },
  {
    id: "2",
    value: "PAM30",
    label: "PAM30",
  },
  {
    id: "3",
    value: "PAM70",
    label: "PAM70",
  },
  {
    id: "4",
    value: "BLOSUM80",
    label: "BLOSUM80",
  },
  {
    id: "5",
    value: "BLOSUM45",
    label: "BLOSUM45",
  },
];

const eValueCutOffValues = [
  {
    id: "1",
    label: "1e-3",
    value: "1e-3",
  },
  {
    id: "2",
    label: "1e-30",
    value: "1e-30",
  },
  {
    id: "3",
    label: "1e-10",
    value: "1e-10",
  },
  {
    id: "4",
    label: "1e-5",
    value: "1e-5",
  },
  {
    id: "5",
    label: "0.1",
    value: "0.1",
  },
  {
    id: "6",
    label: "0",
    value: "0",
  },
  {
    id: "7",
    label: "10",
    value: "10",
  },
  {
    id: "8",
    label: "100",
    value: "100",
  },
  {
    id: "9",
    label: "1000",
    value: "1000",
  },
];

const queryGeneticCodes = [
  {
    id: "1",
    label: "Standard",
    value: "standard",
  },
  {
    id: "1",
    label: "Vertebrate Mitochondrial",
    value: "vertebrate_mitochondrial",
  },
  {
    id: "3",
    label: "Yeast Mitochondrial",
    value: "yeast_mitochondrial",
  },
  {
    id: "4",
    label: "Mold Protozoan Coelenterate Mitochondrial",
    value: "mold_protozoan_coelenterate_mitochondrial",
  },
  {
    id: "5",
    label: "Invertebrate Mitochondrial",
    value: "invertebrate_mitochondrial",
  },
  {
    id: "6",
    label: "Ciliate Nuclear",
    value: "ciliate_nuclear",
  },
  {
    id: "7",
    label: "Echinoderm Mitochondrial",
    value: "echinoderm_mitochondrial",
  },
  {
    id: "8",
    label: "Euplotid Nuclear",
    value: "euplotid_nuclear",
  },
  {
    id: "9",
    label: "Bacterial",
    value: "bacterial",
  },
  {
    id: "10",
    label: "Alternative Yeast Nuclear",
    value: "alternative_yeast_nuclear",
  },
  {
    id: "11",
    label: "Ascidian Mitochondrial",
    value: "ascidian_mitochondrial",
  },
  {
    id: "12",
    label: "Flatworm Mitochondrial",
    value: "flatworm_mitochondrial",
  },
  {
    id: "13",
    label: "Blepharisma Macronuclear",
    value: "blepharisma_macronuclear",
  },
];

const resultsPerSequence = [
  {
    id: "1",
    label: "1",
    value: "1",
  },
  {
    id: "2",
    label: "10",
    value: "10",
  },
  {
    id: "3",
    label: "50",
    value: "50",
  },
  {
    id: "4",
    label: "100",
    value: "100",
  },
];

export default function Blast(props: ChartProps) {
  const dispatch = useDispatch();

  const applicationState = useSelector((state: any) => state?.application);
  const dashboard = useSelector((state: any) => state?.dashboard);

  const [blastProgramme, setBlastProgramme] = useState("blastn");
  const [blastDatabase, setBlastDatabase] = useState("genome");
  const [allBlastDatabases, setAllBlastDatabases] = useState([]);
  const [selectedBlastDatabases, setSelectedBlastDatabases] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState("genomic");
  const [selectedType, setSelectedType] = useState("nucleotide");
  const [searchedGenes, setSearchedGenes] = useState([]);
  const [sequenceData, setSequenceData] = useState<any>();
  const [activeSequence, setActiveSequence] = useState<any>();
  const [selectedGene, setSelectedGene] = useState<any>();
  const [blastTable, setBlastTable] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [blastUUID, setBlastUUID] = useState<any>();
  const [scoringMatrixValue, setScoringMatrixValue] = useState<any>("BLOSUM62");
  const [eValueCutOffValue, setEValueCutOffValue] = useState<any>("1e-3");
  const [queryGeneticCodeValue, setQueryGeneticCodeValue] =
    useState<any>("standard");
  const [dbGeneticCode, setDbGeneticCode] = useState<any>("standard");
  const [numberOfResultsPerSequence, setNumberOfResultsPerSequence] =
    useState<any>("50");
  const [showSettings, setShowSettings] = useState(false);
  const [lowValueComplexityFilter, setLowValueComplexityFilter] =
    useState(false);
  const [lowerCaseLettersFilter, setLowerCaseLettersFilter] = useState(false);
  const [ungappedFilter, setUngappedFilter] = useState(false);
  const [megaBlastFilter, setMegaBlastFilter] = useState(false);
  const [imageMap, setImageMap] = useState<any>(null);
  const [jbrowseLoading, setJbrowseLoading] = useState(false);

  const getBlastDatabaseData = async () => {
    try {
      const response = await getBlastDatabases("populus tremula v2.2");
      if (response.status === 200) {
        setAllBlastDatabases(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const filteredValues: any = allBlastDatabases.filter(
      (db: any) => db?.molecule_type === selectedType
    );
    if (filteredValues.length > 0) {
      setBlastDatabase(filteredValues[0]?.number);
    }
    setSelectedBlastDatabases(filteredValues);
  }, [selectedType, allBlastDatabases]);
  useEffect(() => {
    getBlastDatabaseData();
  }, []);

  useEffect(() => {
    setBlastTable(null);
  }, [
    blastProgramme,
    blastDatabase,
    selectedSequence,
    selectedType,
    selectedGene,
  ]);

  const { driver } = useContext<Neo4jContextState>(Neo4jContext);

  const handleChangeActiveSequence = () => {
    if (selectedSequence === "genomic") {
      setActiveSequence(sequenceData?.genomic_data);
    } else if (selectedSequence === "protein") {
      setActiveSequence(sequenceData?.protein_sequence);
    } else if (selectedSequence === "transcript") {
      setActiveSequence(sequenceData?.transcript_sequence);
    } else if (selectedSequence === "cds") {
      setActiveSequence(sequenceData?.cds_sequence);
    }
  };

  const getGeneSequence = async () => {
    const data = {
      geneId: selectedGene.name,
    };
    try {
      const response = await getSequence(data);
      setSequenceData(response.data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    if (selectedGene) {
      getGeneSequence();
    }
  }, [selectedGene]);

  useEffect(() => {
    handleChangeActiveSequence();
  }, [selectedSequence, sequenceData]);

  const searchGenesByText = (text) => {
    dispatch(
      searchGeneByTextThunk(
        driver,
        applicationState?.connection?.database,
        text,
        (values) => {
          setSearchedGenes(values);
        }
      )
    );
  };

  const handleChangeBlastProgramme = (event) => {
    setBlastProgramme(event.target.value as string);
    const index = neocliodeProgrammes.indexOf(event.target.value);
    if (index !== -1) {
      setSelectedType("nucleotide");
      setBlastDatabase("genome");
      setSelectedSequence("genomic");
    } else {
      setSelectedType("protein");
      setBlastDatabase("protein");
      setSelectedSequence("protein");
    }
  };

  const handleChangeScoringMatrix = (event) => {
    setScoringMatrixValue(event.target.value as string);
  };

  const handleChangeBlastDatabase = (event) => {
    setBlastDatabase(event.target.value as string);
  };

  const preProcessBlastTable = (data) => {
    const newData: any = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      newData.push({
        id: index + 1,
        ...element,
      });
    }
    setBlastTable(newData);
  };

  const validateBlastData = async (data) => {
    const validateBlastResponse = await validateBlast(data);
    setLoading(false);
    if (validateBlastResponse.status === 200) {
      setShowSettings(false);
      localStorage.setItem(BLAST_JBROWSE_UUID, data.uuid);
      setBlastUUID(data.uuid);
      preProcessBlastTable(validateBlastResponse.data?.table);
      const map = parseStringForCoordinates(
        validateBlastResponse.data?.read_map
      );

      setImageMap(map);
    }
  };

  const runBlast = async () => {
    setLoading(true);
    const data = {
      geneId: selectedGene.name,
      database: blastProgramme,
      sequence: activeSequence,
      scoringMatrix: scoringMatrix,
      lowComplexity: lowValueComplexityFilter,
      lowerUpperCase: lowerCaseLettersFilter,
      eValue: eValueCutOffValue,
      ungapped: ungappedFilter,
      megaBlast: megaBlastFilter,
      databaseIndex: blastDatabase,
      noOfResults: numberOfResultsPerSequence,
      queryGeneticCode: queryGeneticCodeValue,
      dbGeneticCode: dbGeneticCode,
    };
    try {
      const response = await getBlast(data);
      setTimeout(() => {
        validateBlastData({
          uuid: response.data.uuid,
          program: blastProgramme,
          qid: response.data.qid[0],
        });
      }, 8000);
    } catch (error) {
      console.log("Error is: ", error);
    }
  };

  const onClickBlastMap = (item) => {
    // Need to open the jbrowse
    const firstItem = item.href.split("&")[0];
    const chromosome = firstItem.split("=")[1];
    localStorage.setItem(BLAST_CHROMOSOME_NAME, chromosome);
    setLoading(true);
    setJbrowseLoading(true);
    setTimeout(() => {
      setLoading(false);
      setJbrowseLoading(false);
      const existingPage = dashboard?.pages?.find(
        (item) => item?.title === "JBrowse"
      );
      if (existingPage) {
        dispatch(removePageThunk(dashboard?.pages?.indexOf(existingPage)));
      }
      dispatch(addPageWithDetailsThunk({ title: "JBrowse", reports: [] }));
      dispatch(
        addReportThunk(0, 0, 12, 5, {
          ...CARD_INITIAL_STATE,
          title: "Jbrowse",
          query: "",
          type: "jBrowse",
        })
      );
    }, 7000);
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow component="th" scope="row">
              <TableCell component="th" scope="row">
                Load Example
              </TableCell>
              <TableCell align="right">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={searchedGenes}
                  value={selectedGene}
                  onChange={(event, value) => {
                    setSelectedGene(value);
                  }}
                  size="small"
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Gene id"
                      onChange={(event) => {
                        searchGenesByText(event.target.value);
                      }}
                    />
                  )}
                />
              </TableCell>

              {selectedType === "protein" && (
                <TableCell align="left" sx={{ width: 200 }}>
                  <Button
                    variant={
                      selectedSequence === "protein" ? "contained" : "outlined"
                    }
                    size="small"
                    onClick={() => setSelectedSequence("protein")}
                  >
                    Protein Sequence
                  </Button>
                </TableCell>
              )}

              {selectedType === "nucleotide" && (
                <TableCell align="left" sx={{ width: 200 }}>
                  <Button
                    variant={
                      selectedSequence === "genomic" ? "contained" : "outlined"
                    }
                    size="small"
                    onClick={() => setSelectedSequence("genomic")}
                  >
                    Genomic Sequence
                  </Button>
                </TableCell>
              )}

              <TableCell align="right" sx={{ width: 230 }}>
                {selectedType === "nucleotide" && (
                  <Button
                    variant={
                      selectedSequence === "transcript"
                        ? "contained"
                        : "outlined"
                    }
                    size="small"
                    onClick={() => setSelectedSequence("transcript")}
                  >
                    Transcript Sequence
                  </Button>
                )}
              </TableCell>

              <TableCell align="right" sx={{ width: 200 }}>
                {selectedType === "nucleotide" && (
                  <Button
                    variant={
                      selectedSequence === "cds" ? "contained" : "outlined"
                    }
                    size="small"
                    onClick={() => setSelectedSequence("cds")}
                  >
                    CDS Sequence
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow component="th" scope="row">
              <TableCell component="th" scope="row">
                Blast Programme
              </TableCell>
              <TableCell align="left">
                <Box sx={{ maxWidth: 500 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Programme
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      fullWidth
                      value={blastProgramme}
                      label="Select Programme"
                      size="small"
                      onChange={handleChangeBlastProgramme}
                    >
                      {blastProgrammes.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow component="th" scope="row">
              <TableCell component="th" scope="row">
                Query Sequence
              </TableCell>
              <TableCell align="right">
                <Box sx={{ maxWidth: 500 }}>
                  <TextField
                    placeholder="Sequence"
                    multiline
                    maxRows={5}
                    fullWidth
                    value={activeSequence}
                    InputProps={{}}
                  />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow component="th" scope="row">
              <TableCell component="th" scope="row">
                Upload File
              </TableCell>
              <TableCell align="left">
                <Button variant="contained" component="label">
                  Upload
                  <input hidden accept="image/*" multiple type="file" />
                </Button>
              </TableCell>
            </TableRow>
            <TableRow component="th" scope="row">
              <TableCell component="th" scope="row">
                Select Blast Database
              </TableCell>
              <TableCell align="left">
                <Box sx={{ maxWidth: 500 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Database
                    </InputLabel>
                    <Select
                      key={blastDatabase}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      fullWidth
                      value={blastDatabase}
                      label="Select Database"
                      size="small"
                      onChange={handleChangeBlastDatabase}
                    >
                      {selectedBlastDatabases.map((item: any) => (
                        <MenuItem key={item.number} value={item.number}>
                          {item.group_name + " - " + item.user_friendly_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  size="large"
                  onClick={runBlast}
                  disabled={
                    loading ||
                    !selectedGene ||
                    !blastProgramme ||
                    !blastDatabase ||
                    !activeSequence ||
                    !selectedSequence
                  }
                >
                  {loading ? <CircularProgress size={20} /> : "Blast"}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ mt: 2, mb: 2, ml: 2 }}
      >
        <Typography variant="h6">Settings</Typography>
        <Button variant="text" onClick={() => setShowSettings(!showSettings)}>
          {showSettings ? "Hide" : "Show"}
        </Button>
      </Stack>
      {showSettings && (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow sx={{ maxWidth: 500 }}>
                <TableCell component="th" scope="row" sx={{ width: 200 }}>
                  Scoring matrix
                </TableCell>
                <TableCell>
                  <Box sx={{ maxWidth: 500 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-2">
                        Scoring matrix
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-2"
                        id="demo-simple-select-2"
                        fullWidth
                        value={scoringMatrixValue}
                        label="Scoring Matrix"
                        size="small"
                        onChange={handleChangeScoringMatrix}
                      >
                        {scoringMatrix.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ maxWidth: 500 }}>
                <TableCell component="th" scope="row" sx={{ width: 200 }}>
                  Filtering
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={<Checkbox checked={lowValueComplexityFilter} />}
                    onChange={(event, checked) =>
                      setLowValueComplexityFilter(checked)
                    }
                    label="Low Complexity"
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={<Checkbox checked={lowerCaseLettersFilter} />}
                    onChange={(event, checked) => {
                      setLowerCaseLettersFilter(checked);
                    }}
                    label="Low-case letters"
                  />
                </TableCell>
              </TableRow>
              <TableRow sx={{ maxWidth: 500 }}>
                <TableCell component="th" scope="row" sx={{ width: 200 }}>
                  E-value cutoff
                </TableCell>
                <TableCell>
                  <Box sx={{ maxWidth: 500 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-2">
                        E-value cutoff
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-2"
                        id="demo-simple-select-2"
                        fullWidth
                        value={eValueCutOffValue}
                        label="Scoring Matrix"
                        size="small"
                        onChange={(event) =>
                          setEValueCutOffValue(event.target.value)
                        }
                      >
                        {eValueCutOffValues.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ maxWidth: 500 }}>
                <TableCell component="th" scope="row" sx={{ width: 200 }}>
                  Advanced Options
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={<Checkbox checked={ungappedFilter} />}
                    label="ungapped"
                    onChange={(event, checked) => setUngappedFilter(checked)}
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={<Checkbox checked={megaBlastFilter} />}
                    label="megablast"
                    onChange={(event, checked) => setMegaBlastFilter(checked)}
                  />
                </TableCell>
              </TableRow>
              <TableRow sx={{ maxWidth: 500 }}>
                <TableCell component="th" scope="row" sx={{ width: 200 }}>
                  Query Genetic code
                </TableCell>
                <TableCell>
                  <Box sx={{ maxWidth: 500 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-2">
                        E-value cutoff
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-2"
                        id="demo-simple-select-2"
                        fullWidth
                        value={queryGeneticCodeValue}
                        label="Scoring Matrix"
                        size="small"
                        onChange={(event) =>
                          setQueryGeneticCodeValue(event.target.value)
                        }
                      >
                        {queryGeneticCodes.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ maxWidth: 500 }}>
                <TableCell component="th" scope="row" sx={{ width: 200 }}>
                  DB Genetic code
                </TableCell>
                <TableCell>
                  <Box sx={{ maxWidth: 500 }}>
                    <FormControl fullWidth>
                      <InputLabel id="db-genetic-code-label">
                        Db Genetic code
                      </InputLabel>
                      <Select
                        labelId="db-genetic-code-label"
                        id="db-genetic-code-label-id"
                        fullWidth
                        value={dbGeneticCode}
                        label="Scoring Matrix"
                        size="small"
                        onChange={(event) =>
                          setDbGeneticCode(event.target.value)
                        }
                      >
                        {queryGeneticCodes.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ maxWidth: 500 }}>
                <TableCell component="th" scope="row" sx={{ width: 200 }}>
                  Frame shift penalty
                </TableCell>
                <TableCell>
                  <Box sx={{ maxWidth: 500 }}>
                    <TextField
                      variant="outlined"
                      label="Frame shift penalty"
                      fullWidth
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ maxWidth: 500 }}>
                <TableCell component="th" scope="row" sx={{ width: 200 }}>
                  Word Size
                </TableCell>
                <TableCell>
                  <Box sx={{ maxWidth: 500 }}>
                    <TextField variant="outlined" label="Word Size" fullWidth />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{ maxWidth: 500 }}>
                <TableCell component="th" scope="row" sx={{ width: 200 }}>
                  Number of results per sequence
                </TableCell>
                <TableCell>
                  <Box sx={{ maxWidth: 500 }}>
                    <FormControl fullWidth>
                      <InputLabel id="results-per-sequence">
                        Number of results per sequence
                      </InputLabel>
                      <Select
                        labelId="results-per-sequence"
                        id="results-per-sequence-id"
                        fullWidth
                        value={numberOfResultsPerSequence}
                        label="Number of results per sequence"
                        size="small"
                        onChange={(event) =>
                          setNumberOfResultsPerSequence(event.target.value)
                        }
                      >
                        {resultsPerSequence.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 2 }}
      >
        {blastTable && blastUUID && (
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              window.open(
                `https://api.plantgenie.org/plugins/blast/tmp/${blastUUID}.output.Query1.html`
              );
            }}
            disabled={!blastTable || !blastUUID}
          >
            View HTML
          </Button>
        )}
        {blastTable && blastUUID && (
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => {
              const link = document.createElement("a");
              link.href = `https://api.plantgenie.org/plugins/blast/tmp/${blastUUID}.output.Query1.html`;
              link.setAttribute("download", `${blastUUID}.output.Query1.html`);
              document.body.appendChild(link);
              link.click();
              link?.parentNode?.removeChild(link);
            }}
            disabled={!blastTable || !blastUUID}
          >
            Download HTML
          </Button>
        )}
        {blastTable && blastUUID && (
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              window.open(
                `https://api.plantgenie.org/plugins/blast/tmp/${blastUUID}.output.Query1.txt`
              );
            }}
            disabled={!blastTable || !blastUUID}
          >
            View Text
          </Button>
        )}
        {blastTable && blastUUID && (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              const link = document.createElement("a");
              link.href = `https://api.plantgenie.org/plugins/blast/tmp/${blastUUID}.output.Query1.txt`;
              link.setAttribute("download", `${blastUUID}.output.Query1.txt`);
              document.body.appendChild(link);
              link.click();
              link?.parentNode?.removeChild(link);
            }}
            disabled={!blastTable || !blastUUID}
          >
            Download Text
          </Button>
        )}
      </Stack>
      {jbrowseLoading && (
        <div
          style={{
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={30} />
        </div>
      )}
      {!jbrowseLoading && blastTable && imageMap && (
        <div style={{ position: "relative", zIndex: 10000 }}>
          <img
            src={`https://api.plantgenie.org/plugins/blast/tmp/${blastUUID}.output.png`}
            useMap="#blastMap"
            id="blastImage"
            style={{ overflow: "hidden", top: 0 }}
          />
          {imageMap.map((item) => {
            return (
              <div
                onClick={() => {
                  onClickBlastMap(item);
                }}
                style={{
                  position: "absolute",
                  left: item.coords[0],
                  top: item.coords[1],
                  width: item.coords[2] - item.coords[0],
                  height: item.coords[3] - item.coords[1],
                  cursor: "pointer",
                }}
              />
            );
          })}
        </div>
      )}
      {blastTable && (
        <Box sx={{ height: 400, width: "100%", mt: 2 }}>
          <DataGrid
            rows={blastTable}
            columns={tableColumns}
            rowsPerPageOptions={[3, 5, 10, 20]}
            checkboxSelection
          />
        </Box>
      )}
    </Box>
  );
}
