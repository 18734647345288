const params = new URLSearchParams(document.location.search);
const dbName = params.get("db");
const dashboardName = params.get("dashboard");
const userAdmin = params.get("userAdmin");
const loginEnabled = params.get("loginEnabled");

export const configStore = {
  ssoEnabled: false,
  standalone: !(userAdmin && loginEnabled),
  ssoDiscoveryUrl:
    "https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/v2.0/.well-known/openid-configuration",
  standaloneProtocol: "neo4j", //testuser
  standaloneHost: "130.239.127.85",
  standalonePort: "17778",
  standaloneDatabase: dbName ?? "potra1",
  standaloneDashboardName: dashboardName,
  standaloneDashboardDatabase: dbName ?? "potra1",
  standaloneDashboardURL: "",
  loginEnabled: loginEnabled ?? false,
  standaloneUsername: "platgenietestuser",
  standalonePassword: "Testpass1@",
};
