import { createDriver } from "use-neo4j";
import { createNotificationThunk } from "../page/PageThunks";
import { updateDashboardSetting } from "../settings/SettingsActions";
import {
  addGenieList,
  addPage,
  addPageWithDetails,
  deleteGenieList,
  movePage,
  removePage,
  resetDashboardState,
  setDashboard,
  setGenieLists,
  updateGenieList,
  updateGenieListStatus,
  updateGenieLists,
} from "./DashboardActions";
import { QueryStatus, runCypherQuery } from "../report/ReportQueryRunner";
import {
  setParametersToLoadAfterConnecting,
  setWelcomeScreenOpen,
} from "../application/ApplicationActions";
import { updateGlobalParametersThunk } from "../settings/SettingsThunks";
import { ACTIVE_GENELIST, GENIELIST_DATABASE } from "../constants";
import {
  createGeneListForUser,
  deleteUserGeneList,
  fetchAllGeneListsForUser,
  updateUserGeneList,
} from "../api/neo4j-community/geneList";
import { getAvailableDatabases } from "../api/neo4j-community/db";

function createUUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export const removePageThunk = (number) => (dispatch: any, getState: any) => {
  try {
    const numberOfPages = getState().dashboard.pages.length;

    if (numberOfPages == 1) {
      dispatch(
        createNotificationThunk(
          "Cannot remove page",
          "You can't remove the only page of a dashboard."
        )
      );
      return;
    }
    if (number >= numberOfPages - 1) {
      dispatch(
        updateDashboardSetting("pagenumber", Math.max(0, numberOfPages - 2))
      );
    }
    dispatch(removePage(number));
  } catch (e) {
    dispatch(createNotificationThunk("Unable to remove page", e));
  }
};

export const addPageThunk = () => (dispatch: any, getState: any) => {
  try {
    const numberOfPages = getState().dashboard.pages.length;
    dispatch(addPage());
    dispatch(updateDashboardSetting("pagenumber", numberOfPages));
  } catch (e) {
    dispatch(createNotificationThunk("Unable to create page", e));
  }
};

export const addPageWithDetailsThunk =
  (data) => (dispatch: any, getState: any) => {
    try {
      const numberOfPages = getState().dashboard.pages.length;
      dispatch(addPageWithDetails(data));
      dispatch(updateDashboardSetting("pagenumber", numberOfPages));
    } catch (e) {
      dispatch(createNotificationThunk("Unable to create page", e));
    }
  };

export const movePageThunk =
  (oldIndex: number, newIndex: number) => (dispatch: any, getState: any) => {
    try {
      if (getState().dashboard.settings.pagenumber == oldIndex) {
        dispatch(updateDashboardSetting("pagenumber", newIndex));
      }
      dispatch(movePage(oldIndex, newIndex));
    } catch (e) {
      dispatch(createNotificationThunk("Unable to move page", e));
    }
  };

export const loadDashboardThunk = (text) => (dispatch: any, getState: any) => {
  try {
    if (text.length == 0) {
      throw "No dashboard file specified. Did you select a file?";
    }
    if (text.trim() == "{}") {
      dispatch(resetDashboardState());
      return;
    }
    const dashboard = JSON.parse(text);

    // Attempt upgrade if dashboard version is outdated.
    if (dashboard["version"] == "1.1") {
      const upgradedDashboard = upgradeDashboardVersion(
        dashboard,
        "1.1",
        "2.0"
      );
      dispatch(setDashboard(upgradedDashboard));
      dispatch(setWelcomeScreenOpen(false));
      dispatch(
        createNotificationThunk(
          "Successfully upgraded dashboard",
          "Your old dashboard was migrated to version 2.0. You might need to refresh this page."
        )
      );

      return;
    }
    if (dashboard["version"] == "2.0") {
      const upgradedDashboard = upgradeDashboardVersion(
        dashboard,
        "2.0",
        "2.1"
      );
      dispatch(setDashboard(upgradedDashboard));
      dispatch(setWelcomeScreenOpen(false));
      dispatch(
        createNotificationThunk(
          "Successfully upgraded dashboard",
          "Your old dashboard was migrated to version 2.1. You might need to refresh this page."
        )
      );

      return;
    }
    if (dashboard["version"] != "2.1") {
      throw (
        "Invalid dashboard version: " +
        dashboard.version +
        ". Try restarting the application, or retrieve your cached dashboard using a debug report."
      );
    }
    // Reverse engineer the minimal set of fields from the selection loaded.
    dashboard.pages.forEach((p) => {
      p.reports.forEach((r) => {
        if (r.selection) {
          r["fields"] = [];
          Object.keys(r.selection).forEach((f) => {
            r["fields"].push([f, r.selection[f]]);
          });
        }
      });
    });
    dispatch(setDashboard(dashboard));
    const application = getState().application;
    dispatch(
      updateGlobalParametersThunk(application.parametersToLoadAfterConnecting)
    );
    dispatch(setParametersToLoadAfterConnecting(null));
  } catch (e) {
    dispatch(createNotificationThunk("Unable to load dashboard", e));
  }
};

export const saveDashboardToNeo4jThunk =
  (driver, database, dashboard, date, user, overwrite = false) =>
  (dispatch: any, getState: any) => {
    try {
      const uuid = createUUID();
      const title = dashboard.title;
      const version = dashboard.version;
      const content = dashboard;

      // Generate a cypher query to save the dashboard.
      const query = overwrite
        ? "OPTIONAL MATCH (n:`_Neodash_Dashboard` {title:$title}) DELETE n WITH 1 as X LIMIT 1 CREATE (n:_Neodash_Dashboard) SET n.uuid = $uuid, n.title = $title, n.version = $version, n.user = $user, n.content = $content, n.date = datetime($date) RETURN n"
        : "CREATE (n:`_Neodash_Dashboard`) SET n.uuid = $uuid, n.title = $title, n.version = $version, n.user = $user, n.content = $content, n.date = datetime($date) RETURN n";
      runCypherQuery(
        database,
        query,
        {
          uuid: uuid,
          title: title,
          version: version,
          user: user,
          content: JSON.stringify(content),
          date: date,
        },
        {},
        ["uuid"],
        1,
        () => {
          return;
        },
        (records) => {
          console.log("Records are: ", records);
          if (records) {
            dispatch(
              createNotificationThunk(
                "🎉 Success!",
                "Your current dashboard was saved to Neo4j."
              )
            );
          } else {
            dispatch(
              createNotificationThunk(
                "Unable to save dashboard",
                "Do you have write access to the '" + database + "' database?"
              )
            );
          }
        }
      );
    } catch (e) {
      dispatch(createNotificationThunk("Unable to save dashboard to Neo4j", e));
    }
  };

export const loadDashboardFromNeo4jByUUIDThunk =
  (database, uuid, callback) => (dispatch: any, getState: any) => {
    try {
      runCypherQuery(
        database,
        "MATCH (n:_Neodash_Dashboard) WHERE n.uuid = $uuid RETURN n.content as dashboard",
        { uuid: uuid },
        {},
        ["dashboard"],
        1,
        () => {
          return;
        },
        (records) => {
          if (records.length == 0) {
            dispatch(
              createNotificationThunk(
                "Unable to load dashboard.",
                "A dashboard with the provided UUID could not be found."
              )
            );
          }
          callback(records[0]["_fields"][0]);
        }
      );
    } catch (e) {
      dispatch(createNotificationThunk("Unable to load dashboard to Neo4j", e));
    }
  };

export const loadDashboardFromNeo4jByNameThunk =
  (database, name, callback) => (dispatch: any, getState: any) => {
    try {
      runCypherQuery(
        database,
        "MATCH (d:_Neodash_Dashboard) WHERE d.title = $name RETURN d.content as dashboard ORDER by d.date DESC LIMIT 1",
        { name: name },
        {},
        ["dashboard"],
        1,
        (data) => {
          return;
        },
        (records) => {
          if (records.length == 0) {
            dispatch(
              createNotificationThunk(
                "Unable to load dashboard.",
                "A dashboard with the provided name could not be found."
              )
            );
          }
          callback(records[0]["_fields"][0]);
        }
      );
    } catch (e) {
      dispatch(
        createNotificationThunk("Unable to load dashboard from Neo4j", e)
      );
    }
  };

export const loadDashboardListFromNeo4jThunk =
  (database, callback) => (dispatch: any, getState: any) => {
    try {
      runCypherQuery(
        database,
        "MATCH (n:_Neodash_Dashboard) RETURN n.uuid as id, n.title as title, toString(n.date) as date,  n.user as author, n.version as version ORDER BY date DESC",
        {},
        {},
        ["id, title, date, user, version"],
        1000,
        () => {
          return;
        },
        (records) => {
          if (!records || !records[0] || !records[0]["_fields"]) {
            callback([]);
            return;
          }
          const result = records.map((r) => {
            return {
              id: r["_fields"][0],
              title: r["_fields"][1],
              date: r["_fields"][2],
              author: r["_fields"][3],
              version: r["_fields"][4],
            };
          });
          callback(result);
        }
      );
    } catch (e) {
      dispatch(
        createNotificationThunk("Unable to load dashboard list from Neo4j", e)
      );
    }
  };

export const loadDatabaseListFromNeo4jThunk =
  (callback) => async (dispatch: any, getState: any) => {
    try {
      const response = await getAvailableDatabases();
      callback(response);
    } catch (error) {
      dispatch(
        createNotificationThunk("Unable to list databases from Neo4j", e)
      );
    }
  };

export function upgradeDashboardVersion(
  dashboard: any,
  origin: string,
  target: string
) {
  if (origin == "2.0" && target == "2.1") {
    dashboard["pages"].forEach((p, i) => {
      // From v2.1 onwards, reports will have their x,y positions explicitly specified.
      // v2.0 dashboards do not have this, therefore we must assign them.
      // Additionally we divide the old report height by 1.5 (adjusted vertical scaling factor).

      var xPos = 0;
      var yPos = 0;
      var rowHeight = 1;
      p["reports"].forEach((r, j) => {
        const reportWidth = parseInt(r["width"]);
        const reportHeight = parseInt(r["height"]);
        dashboard["pages"][i]["reports"][j] = {
          x: xPos,
          y: yPos,
          ...dashboard["pages"][i]["reports"][j],
        };
        dashboard["pages"][i]["reports"][j]["height"] = reportHeight / 1.5;
        xPos += reportWidth;
        rowHeight = Math.max(reportHeight / 1.5, rowHeight);
        if (xPos >= 12) {
          xPos = 0;
          yPos += rowHeight;
          rowHeight = 1;
        }
      });
    });
    dashboard["version"] = "2.1";
    return dashboard;
  }
  if (origin == "1.1" && target == "2.0") {
    const upgradedDashboard = {};
    upgradedDashboard["title"] = dashboard["title"];
    upgradedDashboard["version"] = "2.0";
    upgradedDashboard["settings"] = {
      pagenumber: dashboard["pagenumber"],
      editable: dashboard["editable"],
    };
    const upgradedDashboardPages = [];
    dashboard["pages"].forEach((p) => {
      const newPage = {};
      newPage["title"] = p["title"];
      const newPageReports = [];
      p["reports"].forEach((r) => {
        // only migrate value report types.
        if (
          [
            "table",
            "graph",
            "bar",
            "line",
            "map",
            "value",
            "json",
            "select",
            "iframe",
            "text",
          ].indexOf(r["type"]) == -1
        ) {
          return;
        }
        if (r["type"] == "select") {
          r["query"] = "";
        }
        const newPageReport = {
          title: r["title"],
          width: r["width"],
          height: r["height"] * 0.75,
          type: r["type"],
          parameters: r["parameters"],
          query: r["query"],
          selection: {},
          settings: {},
        };

        newPageReports.push(newPageReport);
      });
      newPage["reports"] = newPageReports;
      upgradedDashboardPages.push(newPage);
    });
    upgradedDashboard["pages"] = upgradedDashboardPages;
    return upgradedDashboard;
  }
  throw new Error("Invalid upgrade path: " + origin + " --> " + target);
}

export const setActiveGenieListsFalseThunk =
  (driver, database, userName, dashboardName, callback) =>
  (dispatch: any, getState: any) => {
    try {
      // Generate a cypher query to save the dashboard.
      const query =
        "MATCH (n:GeneLists{user_name:$userName,dashboard_name:$dashboardName}) SET n.active_genelist = FALSE RETURN n";
      runCypherQuery(
        database,
        query,
        {
          userName: userName,
          dashboardName: dashboardName,
        },
        {},
        [],
        100,
        () => {
          return;
        },
        (records) => {
          callback(records);
        }
      );
    } catch (e) {
      dispatch(createNotificationThunk("Unable to save dashboard to Neo4j", e));
    }
  };

export const saveSelectedGenieListThunk =
  (userName, genieList, genieListName, database) =>
  async (dispatch: any, getState: any) => {
    try {
      const response = await createGeneListForUser({
        name: genieListName,
        geneList: genieList,
        database: database,
        username: userName,
      });

      dispatch(
        addGenieList({
          id: response.id,
          name: genieListName,
          genes: genieList.split(","),
          database: database,
          userName: userName,
        })
      );

      dispatch(
        createNotificationThunk(
          "🎉 Success!",
          "Your genie list saved succesfully."
        )
      );
    } catch (error) {
      dispatch(
        createNotificationThunk("Unable to save Genie List", "Error occured")
      );
    }
  };

export const getAllGenieListsToUserName =
  (username: string, database: string) =>
  async (dispatch: any, getState: any) => {
    try {
      const data = await fetchAllGeneListsForUser(username, database);
      const userGenieLists: any = [];
      data.forEach((element) => {
        const genes = element.genes.split(",");
        const geneList = {
          ...element,
          genes: genes,
          active: element?.id == localStorage.getItem(ACTIVE_GENELIST),
        };
        userGenieLists.push(geneList);
      });
      dispatch(setGenieLists(userGenieLists));
    } catch (error) {
      console.log("error", error);
    }
  };

export const changeActiveGenieListThunk =
  (driver, database, id, condition, callback = null) =>
  (dispatch: any, getState: any) => {
    try {
      // Generate a cypher query to save the dashboard.
      const query =
        "MATCH (n:GeneLists) WHERE apoc.node.id(n)=$id SET n.active_genelist=$condition return n";
      runCypherQuery(
        GENIELIST_DATABASE,
        query,
        {
          id: id,
          condition: condition,
        },
        {},
        [],
        1,
        () => {
          return;
        },
        (record) => {
          if (
            record?.length &&
            record[0]["_fields"] &&
            record[0]["_fields"].length
          ) {
            const updatedList = {
              id: record[0]["_fields"][0].identity.low,
              ...record[0]["_fields"][0].properties,
            };
            dispatch(updateGenieListStatus(updatedList));
            if (callback !== null) {
              callback(updatedList);
            }
          } else {
            dispatch(
              createNotificationThunk(
                "Unable to save Genie List",
                "Do you have write access to the '" + database + "' database?"
              )
            );
          }
        }
      );
    } catch (e) {
      dispatch(createNotificationThunk("Unable to save dashboard to Neo4j", e));
    }
  };

export const deleteGenieListThunk =
  (id) => async (dispatch: any, getState: any) => {
    try {
      await deleteUserGeneList(id);
      dispatch(deleteGenieList(id));
    } catch (error) {
      dispatch(createNotificationThunk("Unable to delete the genelist", error));
    }
  };

export const updateGenieListNameThunk =
  (geneList, newName) => async (dispatch: any, getState: any) => {
    try {
      await updateUserGeneList(geneList?.id, {
        ...geneList,
        name: newName,
        genes: geneList.genes.join(","),
      });
      dispatch(
        updateGenieList({
          ...geneList,
          name: newName,
        })
      );
    } catch (error) {
      dispatch(
        createNotificationThunk(
          "Error occured while updating the genelist ",
          error
        )
      );
    }
  };

export const updateGenieListThunk =
  (id, geneList, currentList) => async (dispatch: any, getState: any) => {
    try {
      const response = await updateUserGeneList(id, geneList);
      dispatch(
        updateGenieList({
          ...currentList,
          id: id,
          genes: geneList.split(","),
        })
      );

      dispatch(
        createNotificationThunk(
          "🎉 Success!",
          "Your genie list updated succesfully."
        )
      );
    } catch (error) {
      dispatch(
        createNotificationThunk("Unable to save Genie List", "Error occured")
      );
    }
  };

export const searchGeneByTextThunk =
  (driver, database, searchText, callback) =>
  (dispatch: any, getState: any) => {
    try {
      // Generate a cypher query to save the dashboard.
      const query =
        "match (n:Gene) where n.description contains $description return n.name,n.description limit 10 union " +
        "match (n:Gene) where n.name contains $name return n.name,n.description limit 10 union " +
        "match (n:Gene) where n.chr contains $chr return n.name,n.description  limit 10";
      runCypherQuery(
        database,
        query,
        {
          description: searchText,
          name: searchText,
          chr: searchText,
        },
        {},
        [],
        10,
        (status) => {
          return;
        },
        (records) => {
          if (records?.length) {
            const geneList = records.map((record) => {
              return {
                name: record["_fields"][0],
                description: record["_fields"][1],
                label: record["_fields"][0],
              };
            });
            callback(geneList);
          }
        }
      );
    } catch (e) {
      dispatch(createNotificationThunk("Unable to save dashboard to Neo4j", e));
    }
  };

export const updateActiveGenelistThunk =
  (activeList) => (dispath: any, getState: any) => {
    try {
      const genelists = getState().dashboard.addedGenieLists;
      const newLists = genelists.map((list) => {
        if (list.id === activeList.id) {
          return {
            ...list,
            active: true,
          };
        }
        return {
          ...list,
          active: false,
        };
      });
      dispath(updateGenieLists(newLists));
    } catch (e) {
      dispath(createNotificationThunk("Unable to update active genelist", e));
    }
  };
