import { BLAST_JBROWSE_UUID } from "../constants";

const blastUUID = localStorage.getItem(BLAST_JBROWSE_UUID);

export const assembly = {
  name: "Potra v2.2",
  sequence: {
    type: "ReferenceSequenceTrack",
    trackId: "potrav22-ReferenceSequenceTrack",
    adapter: {
      type: "BgzipFastaAdapter",
      fastaLocation: {
        uri: "https://api.plantgenie.org/data/Potra02_genome.fasta.gz",
      },
      faiLocation: {
        uri: "https://api.plantgenie.org/data/Potra02_genome.fasta.gz.fai",
      },
      gziLocation: {
        uri: "https://api.plantgenie.org/data/Potra02_genome.fasta.gz.gzi",
      },
    },
  },
};

export const tracks = [
  {
    type: "FeatureTrack",
    trackId: "potra_genes",
    assemblyNames: ["Potra v2.2"],
    name: "P. tremula v2 gene models",
    category: ["Annotation"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "https://api.plantgenie.org/data/Potra02_genes.gff.gz",
      },
      index: {
        location: {
          uri: "https://api.plantgenie.org/data/Potra02_genes.gff.gz.tbi",
        },
      },
    },

    textSearching: {
      textSearchAdapter: {
        type: "TrixTextSearchAdapter",
        textSearchAdapterId: "Potra v2.0-index",
        ixFilePath: {
          uri: "plugins/jbrowse_new/trix/p.ix",
        },
        ixxFilePath: {
          uri: "plugins/jbrowse_new/trix/p.ixx",
        },
        metaFilePath: {
          uri: "plugins/jbrowse_new/trix/p_meta.json",
        },
        assemblyNames: ["Potra v2.2"],
      },
      indexingAttributes: ["Name", "ID"],
      indexingFeatureTypesToExclude: ["CDS", "exon"],
    },
  },
  {
    type: "FeatureTrack",
    trackId: "BLASThits",
    name: "BLAST Hits",
    assemblyNames: ["Potra v2.2"],
    category: ["Annotation"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        locationType: "UriLocation",
        uri: `https://api.plantgenie.org/plugins/blast/tmp/${blastUUID}.output_sorted.gff.gz`,
      },
      index: {
        indexType: "CSI",
        location: {
          locationType: "UriLocation",
          uri: `https://api.plantgenie.org/plugins/blast/tmp/${blastUUID}.output_sorted.gff.gz.csi`,
        },
      },
    },
    displays: [
      {
        type: "LinearBasicDisplay",
        displayId: "BLASThits-LinearBasicDisplay",
      },
      // {
      //   type: "LinearArcDisplay",
      //   displayId: "BLASThits-LinearArcDisplay",
      // },
      {
        type: "LinearManhattanDisplay",
        displayId: "BLASThits-LinearManhattanDisplay",
      },
    ],
  },
  {
    type: "VariantTrack",
    trackId: "VariantTrack 4",
    name: "Scottish Aspen Collection (ScotAsp) filtered by HWE (P < 0.000001) and Excess Heterozygosity",
    assemblyNames: ["Potra v2.2"],
    category: ["Population genetics"],
    adapter: {
      type: "VcfTabixAdapter",
      vcfGzLocation: {
        uri: "https://api.plantgenie.org/plugins/jbrowse_new/potrav20/v2.2/indices/jbrowse/vcf/ScotAsp_biallelic_Het.HWE.recode.vcf.gz",
      },
      index: {
        location: {
          uri: "https://api.plantgenie.org/plugins/jbrowse_new/potrav20/v2.2/indices/jbrowse/vcf/ScotAsp_biallelic_Het.HWE.recode.vcf.gz.tbi",
        },
      },
    },
  },

  {
    type: "QuantitativeTrack",
    trackId: "QuantitativeTrack 1",
    name: "RNA-Seq Coverage (all annotation evidence)",
    assemblyNames: ["Potra v2.2"],
    category: ["Annotation Support"],
    adapter: {
      type: "BigWigAdapter",
      bigWigLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/all_potra_merged.bw",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/all_potra_merged.bw.json",
        },
      },
    },
    displayCrossHatches: true,
    constraints: {
      max: 2000,
      min: -1000,
    },
  },
  {
    type: "QuantitativeTrack",
    trackId: "QuantitativeTrack 2",
    name: "Genomic Coverage (HiSeq 2x300 library)",
    assemblyNames: ["Potra v2.2"],
    category: ["Genome Feature"],
    adapter: {
      type: "BigWigAdapter",
      bigWigLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/asp201_hiseq2x300.bw",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/asp201_hiseq2x300.bw.json",
        },
      },
    },
    displayCrossHatches: true,
    constraints: {
      max: 2000,
      min: -1000,
    },
  },
  {
    type: "QuantitativeTrack",
    trackId: "QuantitativeTrack 3",
    name: "Genomic Coverage (PacBio library)",
    assemblyNames: ["Potra v2.2"],
    category: ["Genome Feature"],
    adapter: {
      type: "BigWigAdapter",
      bigWigLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/asp201_pb.bw",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/asp201_pb.bw.json",
        },
      },
    },
    displayCrossHatches: true,
    constraints: {
      max: 2000,
      min: -1000,
    },
  },
  {
    type: "FeatureTrack",
    trackId: "ARAPORT_proteins",
    assemblyNames: ["Potra v2.2"],
    name: "ARAPORT 11 proteins (exonerate)",
    category: ["Homologues"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/ap11_exonerate_sorted.gff3.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/ap11_exonerate_sorted.gff3.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "ARAPORT_transcripts",
    assemblyNames: ["Potra v2.2"],
    name: "ARAPORT 11 transcripts (GMAP)",
    category: ["Homologues"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/araport11_primary.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/araport11_primary.gff.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "PacBio IsoSeq",
    assemblyNames: ["Potra v2.2"],
    name: "PacBio IsoSeq (GMAP)",
    category: ["Annotation Support"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/asp201_pb_isoseq.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/asp201_pb_isoseq.gff.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "P. tremula v1 transcripts",
    assemblyNames: ["Potra v2.2"],
    name: "P. tremula v1 transcripts (GMAP)",
    category: ["Annotation Support"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/Potra01.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/Potra01.gff.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "Augustus predictions",
    assemblyNames: ["Potra v2.2"],
    name: "Augustus predictions",
    category: ["Annotation Support"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/Potra02_augustus_sorted.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/Potra02_augustus_sorted.gff.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "MAKER EST alignments",
    assemblyNames: ["Potra v2.2"],
    name: "MAKER EST alignments",
    category: ["Annotation Support"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/Potra02_est2genome_sorted.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/Potra02_est2genome_sorted.gff.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "GeneMark predictions",
    assemblyNames: ["Potra v2.2"],
    name: "GeneMark predictions",
    category: ["Annotation Support"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/Potra02_genemark_sorted.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/Potra02_genemark_sorted.gff.gz.tbi",
        },
      },
    },
  },

  {
    type: "FeatureTrack",
    trackId: "LTR regions",
    assemblyNames: ["Potra v2.2"],
    name: "LTR regions",
    category: ["Annotation"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/Potra02_LTR_sorted.gff3.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/Potra02_LTR_sorted.gff3.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "MAKER protein evidence",
    assemblyNames: ["Potra v2.2"],
    name: "MAKER protein evidence",
    category: ["Annotation Support"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/Potra02_protein2genome_sorted.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/Potra02_protein2genome_sorted.gff.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "General repeat regions",
    assemblyNames: ["Potra v2.2"],
    name: "General repeat regions",
    category: ["Annotation"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/Potra02_repeatmasked_sorted.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/Potra02_repeatmasked_sorted.gff.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "SNAP predictions",
    assemblyNames: ["Potra v2.2"],
    name: "SNAP predictions",
    category: ["Annotation Support"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/Potra02_snap_sorted.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/Potra02_snap_sorted.gff.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "P. trichocarpa v3 transcripts",
    assemblyNames: ["Potra v2.2"],
    name: "P. trichocarpa v3 transcripts (GMAP)",
    category: ["Homologues"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/Potri_v3_primary.gff.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/Potri_v3_primary.gff.gz.tbi",
        },
      },
    },
  },
  {
    type: "FeatureTrack",
    trackId: "P. trichocarpa v3 proteins (exonerate)",
    assemblyNames: ["Potra v2.2"],
    name: "P. trichocarpa v3 proteins (exonerate)",
    category: ["Homologues"],
    adapter: {
      type: "Gff3TabixAdapter",
      gffGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/ptri_exonerate_sorted.gff3.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/ptri_exonerate_sorted.gff3.gz.tbi",
        },
      },
    },
  },
  {
    type: "VariantTrack",
    trackId: "VariantTrack 20",
    name: "Assembly structural variants",
    assemblyNames: ["Potra v2.2"],
    category: ["Genome Feature"],
    adapter: {
      type: "VcfTabixAdapter",
      vcfGzLocation: {
        uri: "plugins/jbrowse_new/potrav20/final/svs.vcf.gz",
      },
      index: {
        location: {
          uri: "plugins/jbrowse_new/potrav20/final/svs.vcf.gz.tbi",
        },
      },
    },
  },
  // {
  //   type: "FeatureTrack",
  //   trackId: "eQTL",
  //   name: "eQTL",
  //   assemblyNames: ["Potra v2.2"],
  //   category: ["eQTL Track"],
  //   adapter: {
  //     type: "BedTabixAdapter",
  //     bedGzLocation: {
  //       uri: "plugins/jbrowse_new/potrav20/v2.2/indices/jbrowse/eqtl.txt.gz",
  //     },
  //     index: {
  //       location: {
  //         uri: "plugins/jbrowse_new/potrav20/v2.2/indices/jbrowse/eqtl.txt.gz.tbi",
  //       },
  //     },
  //     scoreColumn: "neg_log_pvalue",
  //   },
  //   displays: [
  //     {
  //       type: "LinearManhattanDisplay",
  //       displayId: "eQTL",
  //     },
  //   ],
  // },
];
