import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  IconButton,
  InputBase,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tab from "@mui/material/Tab";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { NeoDeletePageModal } from "../../modal/DeletePageModal";

export const NeoPageButton = ({
  title,
  disabled = false,
  selected = false,
  onSelect,
  onRemove,
  onTitleUpdate,
}) => {
  // TODO - debounce page title update
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editTitle, setEditTitle] = React.useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const [titleText, setTitleText] = React.useState(title);
  useEffect(() => {
    // Reset text to the dashboard state when the page gets reorganized.
    if (titleText !== title) {
      setTitleText(title);
    }
  }, [title]);

  const content = (
    <>
      <Box display="flex" flexDirection="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {selected ? (
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "space-between",
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {editTitle ? (
                  <TextField
                    defaultValue={titleText}
                    variant="standard"
                    size="small"
                    onChange={(event) => {
                      if (disabled) {
                        return;
                      }
                      onTitleUpdate(event);
                      setTitleText(event.target.value);
                    }}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    color={selected ? "primary" : "secondary"}
                  >
                    {titleText}
                  </Typography>
                )}
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={onSelect}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2" color="secondary">
                  {titleText}
                </Typography>
              </Stack>
            </Box>
          )}

          {selected && (
            <IconButton onClick={() => setEditTitle(!editTitle)}>
              {editTitle ? (
                <SaveIcon fontSize="small" color="primary" />
              ) : (
                <EditIcon fontSize="small" color="primary" />
              )}
            </IconButton>
          )}
        </Stack>

        {!editTitle && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              backgroundColor: selected ? "primary.main" : "#fff",
              display: "flex",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            width={selected ? "100%" : 0}
            height={selected ? 5 : 0}
          />
        )}
      </Box>
      {/* <Grid
        style={{ height: "100%" }}
        onClick={onSelect}
        container
        spacing={1}
        alignItems="flex-end"
      >
        <Grid item key={1} style={{ width: "100%" }}>
          <InputBase
            value={titleText}
            onChange={(event) => {
              if (disabled) {
                return;
              }
              onTitleUpdate(event);
              setTitleText(event.target.value);
            }}
            onFocus={(e) => {
              if (disabled) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            readOnly={disabled}
            inputProps={{
              style: {
                textTransform: "none",
                cursor: "pointer",
                fontWeight: "normal",
              },
            }}
            style={{
              height: "36px",
              width: "100%",
              paddingLeft: "10px",
              color: selected ? "black" : "#888",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            placeholder="Page name..."
          />
        </Grid>
      </Grid>
      <div
        style={{
          position: "absolute",
          top: 5,
          right: 0,
          paddingRight: 3,
          background: selected ? "white" : "transparent",
        }}
      >
        {selected && !disabled ? (
          <IconButton
            size="medium"
            style={{ padding: "5px", color: "white" }}
            aria-label="move left"
            onClick={() => setModalOpen(true)}
          >
            <CloseIcon color="disabled" />
          </IconButton>
        ) : (
          <IconButton
            size="medium"
            style={{ opacity: 0, padding: "5px", color: "white" }}
            aria-label="move left"
            onClick={(event) => null}
          >
            <CloseIcon color="disabled" />
          </IconButton>
        )}
      </div> */}
      <NeoDeletePageModal
        modalOpen={modalOpen}
        onRemove={onRemove}
        handleClose={handleClose}
      />
    </>
  );
  return content;
};

export default NeoPageButton;
