import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CategoryIcon from "@mui/icons-material/Category";
import Badge from "@mui/material/Badge";
import { Grid, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import NeoCodeEditorComponent from "../component/editor/CodeEditorComponent";
import NeoReport from "../report/Report";
import { EXAMPLE_REPORTS } from "../config/ExampleConfig";

export const NeoReportExamplesModal = ({ database }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          <CategoryIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Examples" />
      </ListItem>

      {open ? (
        <Dialog
          maxWidth={"xl"}
          open={open == true}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Report Examples
            <IconButton
              onClick={handleClose}
              style={{ padding: "3px", float: "right" }}
            >
              <Badge badgeContent={""}>
                <CloseIcon style={{ background: "#6C9270 ", color: "white" }} />
              </Badge>
            </IconButton>
          </DialogTitle>
          <div>
            <DialogContent>
              <hr></hr>
              {EXAMPLE_REPORTS.map((example) => {
                return (
                  <>
                    <h3>{example.title}</h3>
                    <DialogContentText>
                      {example.description}
                      <br />
                      <br />
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <div
                            style={{
                              width: "400px",
                              border: "0px solid lightgrey",
                            }}
                          >
                            <NeoCodeEditorComponent
                              editable={false}
                              placeholder=""
                              value={example.exampleQuery}
                              language={
                                example.type == "iframe" ? "url" : "cypher"
                              }
                            ></NeoCodeEditorComponent>
                          </div>
                        </Grid>

                        <Grid item xs={8}>
                          <div
                            style={{
                              height: "355px",
                              width: "800px",
                              overflow: "hidden",
                              border: "1px solid lightgrey",
                            }}
                          >
                            <NeoReport
                              query={example.syntheticQuery}
                              database={database}
                              disabled={!open}
                              selection={example.selection}
                              parameters={example.globalParameters}
                              settings={example.settings}
                              fields={example.fields}
                              dimensions={example.dimensions}
                              ChartType={example.chartType}
                              type={example.type}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </DialogContentText>
                    <hr></hr>
                  </>
                );
              })}
            </DialogContent>
          </div>
        </Dialog>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NeoReportExamplesModal;
