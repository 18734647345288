import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import PlayArrow from "@mui/icons-material/PlayArrow";
import {
  FormControl,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import PostAddIcon from "@mui/icons-material/PostAdd";
import StorageIcon from "@mui/icons-material/Storage";
import {
  loadDashboardFromNeo4jByUUIDThunk,
  loadDashboardListFromNeo4jThunk,
  loadDashboardThunk,
  loadDatabaseListFromNeo4jThunk,
} from "../dashboard/DashboardThunks";
import { DataGrid } from "@mui/x-data-grid";
import { Neo4jContext, Neo4jContextState } from "use-neo4j/dist/neo4j.context";
import { getAvailableDatabases } from "../api/neo4j-community/db";

/**
 * A modal to save a dashboard as a JSON text string.
 * The button to open the modal is intended to use in a drawer at the side of the page.
 */

const styles = {};

export const NeoLoadModal = ({
  loadDashboard,
  loadDatabaseListFromNeo4j,
  loadDashboardFromNeo4j,
  loadDashboardListFromNeo4j,
}) => {
  const [loadModalOpen, setLoadModalOpen] = React.useState(false);
  const [loadFromNeo4jModalOpen, setLoadFromNeo4jModalOpen] =
    React.useState(false);
  const [text, setText] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const { driver } = useContext<Neo4jContextState>(Neo4jContext);
  const [dashboardDatabase, setDashboardDatabase] = React.useState("");
  const [databases, setDatabases] = React.useState([]);

  const loadDatabaseList = async () => {
    try {
      const data = await getAvailableDatabases();
      setDatabases(data);
    } catch (error) {
      console.log("Error loading databases: ", error);
    }
  };

  useEffect(() => {
    loadDatabaseList();
  }, []);

  const handleClickOpen = () => {
    setLoadModalOpen(true);
  };

  const handleClose = () => {
    setLoadModalOpen(false);
  };

  const handleCloseAndLoad = () => {
    setLoadModalOpen(false);
    loadDashboard(text);
    setText("");
  };

  function handleDashboardLoadedFromNeo4j(result) {
    setText(result);
    setLoadFromNeo4jModalOpen(false);
  }

  const reader = new FileReader();
  reader.onload = async (e) => {
    setText(e.target.result);
  };

  const uploadDashboard = async (e) => {
    e.preventDefault();
    reader.readAsText(e.target.files[0]);
  };

  const columns = [
    { field: "id", hide: true, headerName: "ID", width: 150 },
    { field: "date", headerName: "Date", width: 200 },
    { field: "title", headerName: "Title", width: 270 },
    { field: "author", headerName: "Author", width: 160 },
    { field: "version", headerName: "Version", width: 95 },
    {
      field: "load",
      headerName: "Select",
      renderCell: (c) => {
        return (
          <Button
            onClick={(e) => {
              loadDashboardFromNeo4j(
                driver,
                dashboardDatabase,
                c.id,
                handleDashboardLoadedFromNeo4j
              );
            }}
            style={{ float: "right", color: "#fff" }}
            variant="contained"
            size="medium"
            endIcon={<PlayArrow />}
          >
            Select
          </Button>
        );
      },
      width: 120,
    },
  ];

  return (
    <div>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          <IconButton style={{ padding: "0px" }}>
            <SystemUpdateAltIcon color="primary" />
          </IconButton>
        </ListItemIcon>
        <ListItemText primary="Load" />
      </ListItem>

      <Dialog
        maxWidth={"lg"}
        open={loadModalOpen == true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <SystemUpdateAltIcon
            style={{
              height: "30px",
              paddingTop: "4px",
              marginBottom: "-8px",
              marginRight: "5px",
              paddingBottom: "5px",
            }}
          />
          Load Dashboard
          <IconButton
            onClick={handleClose}
            style={{ padding: "3px", float: "right" }}
          >
            <Badge badgeContent={""}>
              <CloseIcon />
            </Badge>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: "1000px" }}>
          {/* <DialogContentText> Paste your dashboard file here to load it into NeoDash.</DialogContentText> */}
          <div>
            <Button
              component="label"
              onClick={(e) => {
                // if (!dashboardDatabase) {
                //   return;
                // }
                // loadDashboardListFromNeo4j(dashboardDatabase, (result) => {
                //   console.log("Result is: ", result);
                //   setRows(result);
                // });
                setLoadFromNeo4jModalOpen(true);
                // loadDatabaseListFromNeo4jThunk((result) => {
                //   console.log("Databases are: ", result);
                //   setDatabases(result);
                // });
              }}
              style={{ marginBottom: "10px", color: "#fff" }}
              variant="contained"
              size="medium"
              endIcon={<StorageIcon />}
            >
              Select From Neo4j
            </Button>
            <Button
              component="label"
              // onClick={(e)=>uploadDashboard(e)}
              style={{
                marginLeft: "10px",
                marginBottom: "10px",
                color: "#fff",
              }}
              variant="contained"
              size="medium"
              endIcon={<PostAddIcon />}
            >
              <input type="file" onChange={(e) => uploadDashboard(e)} hidden />
              Select From File
            </Button>

            <Button
              onClick={() => {
                if (text.length > 0) {
                  handleCloseAndLoad();
                }
              }}
              style={{
                color: "white",
                float: "right",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              variant="contained"
              disabled={text.length == 0}
              size="medium"
              endIcon={<PlayArrow />}
            >
              Load Dashboard
            </Button>
          </div>

          <TextareaAutosize
            style={{
              minHeight: "500px",
              width: "100%",
              border: "1px solid lightgray",
            }}
            className={"textinput-linenumbers"}
            onChange={(e) => setText(e.target.value)}
            value={text}
            aria-label=""
            placeholder="Select a dashboard first, then preview it here..."
          />
        </DialogContent>
        {/* <DialogActions> */}
        {/* </DialogActions> */}
      </Dialog>
      <Dialog
        maxWidth={"lg"}
        open={loadFromNeo4jModalOpen == true}
        onClose={(e) => {
          setLoadFromNeo4jModalOpen(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Select From Neo4j
          <IconButton
            onClick={(e) => {
              setLoadFromNeo4jModalOpen(false);
            }}
            style={{ padding: "3px", float: "right" }}
          >
            <Badge badgeContent={""}>
              <CloseIcon />
            </Badge>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: "900px" }}>
          <DialogContentText>
            If dashboards are saved in your current database, choose a dashboard
            below.
          </DialogContentText>

          <div style={{ height: "380px", borderBottom: "1px solid lightgrey" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              components={{
                ColumnSortedDescendingIcon: () => <></>,
                ColumnSortedAscendingIcon: () => <></>,
              }}
            />
          </div>
          <FormControl style={{ marginTop: "-58px", marginLeft: "10px" }}>
            <InputLabel id="demo-simple-select-label">Database</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ width: "150px" }}
              label="Database"
              value={dashboardDatabase}
              onChange={(e) => {
                setRows([]);
                setDashboardDatabase(e.target.value);
                console.log("Selected database is: ", e.target.value);
                loadDashboardListFromNeo4j(e.target.value, (result) => {
                  setRows(result);
                });
              }}
            >
              {databases.map((database) => {
                return <MenuItem value={database}>{database}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  loadDashboard: (text) => dispatch(loadDashboardThunk(text)),
  loadDashboardFromNeo4j: (driver, database, uuid, callback) =>
    dispatch(
      loadDashboardFromNeo4jByUUIDThunk(driver, database, uuid, callback)
    ),
  loadDashboardListFromNeo4j: (database, callback) =>
    dispatch(loadDashboardListFromNeo4jThunk(database, callback)),
  loadDatabaseListFromNeo4j: (driver, callback) =>
    dispatch(loadDatabaseListFromNeo4jThunk(driver, callback)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NeoLoadModal)
);
