import client from "./client";

export const createGeneListForUser = async (geneList) => {
  try {
    const { data } = await client.post("/api/genelist", geneList);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchAllGeneListsForUser = async (
  username: string,
  database: string
) => {
  try {
    const { data } = await client.get(
      `/api/genelist/user/${username}/${database}`
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUserGeneList = async (id, geneList) => {
  try {
    const { data } = await client.put("/api/genelist/" + id, {
      genes: geneList,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUserGeneList = async (id) => {
  try {
    const { data } = await client.delete("/api/genelist/" + id);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
