import axios from "axios";

const baseURL = "https://api.plantgenie.org";

const axiosClient = axios.create({ baseURL: baseURL });

export async function getExperimentData() {
  return axiosClient.get("/experiment/get_all?name=beta_plantgenie_potra_v22");
}

export async function getHeatmapData(
  parameters,
  dataset,
  normalised,
  axis,
  rowDistance,
  rowLinkage
) {
  return axiosClient.post(
    "/exheatmap/index.php",
    "gene_ids=" +
      parameters +
      "&file_id=&dataset=" +
      dataset +
      "&axis=" +
      axis +
      "&row_distance=" +
      rowDistance +
      "&row_linkage=" +
      rowLinkage +
      "&normalised=" +
      normalised +
      "&sample_id=true&name=beta_plantgenie_potra_v22"
  );
}
