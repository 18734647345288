import React from "react";
import { GoslingComponent } from "gosling.js";
import "higlass/dist/hglib.css";
import { ChartProps } from "./Chart";
import ManhattenGoslingSpec from "./gosling/ManhattenGoslingSpec";
import { CHROMOSOME_CUM_SUM } from "../constants/GoslingContant";

export interface IElement {
  chr: string;
  fdr: number;
  p_value: number;
  phenotype_id: number;
  phenotype_name: string;
  pos: number;
  snp_id: string;
}

const prepareElementData = (records) => {
  const elements: IElement[] = [];
  records.forEach((record) => {
    if (record._fields?.length) {
      const fields = record._fields[0]?.properties;
      elements.push({
        chr: fields.chr,
        fdr: parseFloat(fields.fdr),
        p_value: -Math.log10(fields.p_value),
        phenotype_id: parseInt(fields.phenotype_id),
        phenotype_name: fields.phenotype_name,
        pos: parseInt(fields.pos) + CHROMOSOME_CUM_SUM[fields.chr],
        snp_id: fields.snp_id,
      });
    }
  });
  return elements;
};

export default function GoslingBar(props: ChartProps) {
  const [elements, setElements] = React.useState<IElement[]>([]);

  React.useEffect(() => {
    if (props?.records) {
      setElements(prepareElementData(props.records));
    }
  }, [props]);
  return (
    <div style={{ height: 800 }}>
      {elements.length > 0 && (
        <GoslingComponent
          spec={ManhattenGoslingSpec(
            elements,
            props?.dimensions?.height,
            props?.dimensions?.width
          )}
          experimental={{ reactive: true }}
        />
      )}
    </div>
  );
}
