import React from "react";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const NeoPageAddButton = ({ onClick }) => {
  const content = (
    <div
      style={{
        padding: "5px",
        cursor: "pointer",
        display: "inline-block",
      }}
    >
      <Grid
        style={{ cursor: "pointer", height: "100%" }}
        container
        spacing={1}
        alignItems="flex-end"
      >
        <Grid item>
          <IconButton
            size="medium"
            style={{ padding: "5px", color: "white" }}
            aria-label="move left"
            onClick={onClick}
          >
            <AddIcon color="disabled" />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
  return content;
};

export default NeoPageAddButton;
