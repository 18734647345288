import React from 'react';
import { ParentContainer } from './ButtonPanel.styles';

interface Props {
    children: Array<React.ReactNode>
}

const ButtonPanel = ({ children }: Props) => (
    <ParentContainer>
        {children.map(item => item)}
    </ParentContainer>
)

export default ButtonPanel;
