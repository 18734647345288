import React, { useEffect, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ChartProps } from "./Chart";
import { GridToolbar, GridSelectionModel } from "@mui/x-data-grid";
import {
  getRendererForValue,
  rendererForType,
  RenderSubValue,
} from "../report/ReportRecordProcessing";
import {
  evaluateRulesOnDict,
  generateClassDefinitionsBasedOnRules,
} from "../report/ReportRuleEvaluator";
import { DataGridStyled } from "./TableChart.styles";
import { createLinks, linksArray } from "../helpers/createFieldLinks";
import { updateSelectionThunk } from "../card/CardThunks";
import { getReportIndex } from "../card/CardSelectors";
import { Button, Stack, IconButton } from "@mui/material";
import { Neo4jContext, Neo4jContextState } from "use-neo4j/dist/neo4j.context";
import {
  saveSelectedGenieListThunk,
  setActiveGenieListsFalseThunk,
  updateGenieListThunk,
} from "../dashboard/DashboardThunks";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { getActiveUserName } from "../utils/getUsername";

function ApplyColumnType(column, value) {
  const renderer = getRendererForValue(value);
  // Matrix adding renderer for fields to act as links
  const columnProperties = renderer
    ? {
        type: renderer.type,
        renderCell: !linksArray.includes(column.headerName)
          ? renderer.renderValue
          : (renderer) => {
              return (
                <a target="_blank" href={createLinks(renderer.value)}>
                  [Link]
                </a>
              );
            },
      }
    : rendererForType["string"];
  if (columnProperties) {
    column = { ...column, ...columnProperties };
  }
  return column;
}

const NeoGenieListChart = (props: ChartProps) => {
  const [pageSize, setPageSize] = React.useState(10);
  const [selectedGenies, setSelectedGenies] = React.useState<any>([]);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const dispatch = useDispatch();
  const applicationState = useSelector((state) => state?.application);
  const dashboardState = useSelector((state) => state?.dashboard);
  const { driver } = useContext<Neo4jContextState>(Neo4jContext);

  const activeId = dashboardState?.addedGenieLists?.find(
    (item) => item?.active
  );

  const onSelectModel = (data) => {
    const selectedIds = new Set(data);
    const selectedData = rows.filter((item) => selectedIds.has(item.id));
    setSelectedGenies(selectedData);
    setRowSelectionModel(data);
  };

  const updateCurrentGenieList = () => {
    if (dashboardState?.addedGenieLists?.length === 0) {
      saveGenieList("My new Gene list");
      return;
    }
    const selectedGenieNames = selectedGenies.map(
      (item) => item[columns[0].field]
    );
    const selectedUniqueGenieNames = selectedGenieNames.filter((item, pos) => {
      return selectedGenieNames.indexOf(item) == pos;
    });
    console.log("Updated length is: ", selectedUniqueGenieNames.length);
    dispatch(
      updateGenieListThunk(
        activeId?.id,
        selectedUniqueGenieNames.join(",").toString(),
        activeId
      )
    );
  };

  const saveGenieList = (name: String) => {
    const selectedGenieNames = selectedGenies.map(
      (item) => item[columns[0].field]
    );
    const selectedUniqueGenieNames = selectedGenieNames.filter((item, pos) => {
      return selectedGenieNames.indexOf(item) == pos;
    });
    dispatch(
      saveSelectedGenieListThunk(
        applicationState?.connection?.username,
        selectedUniqueGenieNames.join(",").toString(),
        name,
        applicationState?.connection?.database
      )
    );
  };

  const getListNameAndSave = () => {
    const name = prompt("Add a name for the selected list");

    if (name) {
      saveGenieList(name);
    } else {
      alert("Invalid input");
    }
  };

  const transposed =
    props.settings && props.settings.transposed
      ? props.settings.transposed
      : false;
  const allowDownload =
    props.settings && props.settings.allowDownload !== undefined
      ? props.settings.allowDownload
      : false;
  const styleRules =
    props.settings && props.settings.styleRules
      ? props.settings.styleRules
      : [];
  const displayTopBar =
    props.settings && props.settings.displayTopBar !== undefined
      ? props.settings.displayTopBar
      : true;
  const displayGridButtons =
    props.settings && props.settings.displayGridButtons !== undefined
      ? props.settings.displayGridButtons
      : true;

  const allowTopBarButtonsProps = !displayGridButtons
    ? {
        disableColumnFilter: true,
        disableColumnSelector: true,
        disableDensitySelector: true,
      }
    : null;

  const useStyles = generateClassDefinitionsBasedOnRules(styleRules);
  const classes = useStyles();
  if (
    props.records == null ||
    props.records.length == 0 ||
    props.records[0].keys == null
  ) {
    return <>No data, re-run the report.</>;
  }

  var columnWidths = null;
  try {
    columnWidths =
      props.settings &&
      props.settings.columnWidths &&
      JSON.parse(props.settings.columnWidths);
  } catch (e) {
    // do nothing
  } finally {
    // do nothing
  }

  const records = props.records;

  const columns = transposed
    ? ["Field"]
        .concat(
          records.map(
            (r, j) => "Value" + (j == 0 ? "" : " " + (j + 1).toString())
          )
        )
        .map((key, i) => {
          const value = key;
          return ApplyColumnType(
            {
              field: key,
              headerName: key,
              headerClassName: "table-small-header",
              disableColumnSelector: true,
              flex:
                columnWidths && i < columnWidths.length ? columnWidths[i] : 1,
              disableClickEventBubbling: true,
            },
            value
          );
        })
    : records[0].keys.map((key, i) => {
        const value = records[0].get(key);
        return ApplyColumnType(
          {
            field: key,
            headerName: key,
            headerClassName: "table-small-header",
            disableColumnSelector: true,
            flex: columnWidths && i < columnWidths.length ? columnWidths[i] : 1,
            disableClickEventBubbling: true,
          },
          value
        );
      });

  const rows = transposed
    ? records[0].keys.map((key, i) => {
        return Object.assign(
          { id: i, Field: key },
          ...records.map((r, j) => ({
            ["Value" + (j == 0 ? "" : " " + (j + 1).toString())]:
              RenderSubValue(r._fields[i]),
          }))
        );
      })
    : records.map((record, rownumber) => {
        return Object.assign(
          { id: rownumber },
          ...record._fields.map((field, i) => ({ [record.keys[i]]: field }))
        );
      });
  useEffect(() => {
    props.onSelectionUpdate(props.index, 1, "");
  }, [props.records]);

  useEffect(() => {
    const activeGenieList = dashboardState?.addedGenieLists.find(
      (item) => item?.active
    );
    const genieList = activeGenieList?.genes;
    if (genieList) {
      const selectedData = rows.filter((item) => {
        const index = genieList.findIndex((genie) => {
          return genie === item[columns[0].field];
        });
        return index > -1;
      });

      setRowSelectionModel(selectedData.map((item) => item?.id));
    }
  }, [dashboardState]);

  return (
    <div
      className={classes.root}
      style={{ height: "100%", width: "100%", position: "relative" }}
    >
      {selectedGenies.length > 0 && (
        <Stack direction="row" spacing={1} sx={{ pl: 1 }}>
          <IconButton onClick={updateCurrentGenieList} disabled={!activeId?.id}>
            <SaveIcon />
          </IconButton>
          <IconButton onClick={getListNameAndSave}>
            <AddIcon />
          </IconButton>
        </Stack>
      )}
      <DataGridStyled
        headerHeight={32}
        rows={rows}
        columns={columns}
        getRowHeight={() => "auto"}
        pagination
        checkboxSelection
        onSelectionModelChange={onSelectModel}
        selectionModel={rowSelectionModel}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[3, 5, 10, 20]}
        // onSelectionModelChange={(ids) => {
        //     setRowSelectionModel(ids);
        //   const selectedRowsData = ids.map((id) =>
        //     rows.find((row) => row.id === id)
        //   );
        //   props.onSelectionUpdate &&
        //     props.onSelectionUpdate(
        //       props.index,
        //       1,
        //       Object.values(selectedRowsData[0])[1]
        //     );
        // }}
        components={{
          ColumnSortedDescendingIcon: () => <></>,
          ColumnSortedAscendingIcon: () => <></>,
          Toolbar: displayTopBar ? GridToolbar : null,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: !allowDownload },
          },
        }}
        {...allowTopBarButtonsProps}
        getRowClassName={(params) => {
          return (
            "rule" +
            evaluateRulesOnDict(params.row, styleRules, [
              "row color",
              "row text color",
            ])
          );
        }}
        getCellClassName={(params) => {
          return (
            "rule" +
            evaluateRulesOnDict({ [params.field]: params.value }, styleRules, [
              "cell color",
              "cell text color",
            ])
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  index: getReportIndex(state, ownProps),
});
const mapDispatchToProps = (dispatch) => ({
  onSelectionUpdate: (index: any, selectable: any, field: any) => {
    dispatch(updateSelectionThunk(index, true, field));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(NeoGenieListChart);
