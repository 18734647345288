import React, { useState } from "react";
import NeoPage from "../page/Page";
import NeoDrawer from "./drawer/DashboardDrawer";
import NeoDashboardHeader from "./header/DashboardHeader";
import { createDriver, Neo4jProvider } from "use-neo4j";
import { applicationGetConnection } from "../application/ApplicationSelectors";
import { connect } from "react-redux";
import _ from "lodash";
import NeoDashboardConnectionUpdateHandler from "../component/misc/DashboardConnectionUpdateHandler";
import { forceRefreshPage } from "../page/PageActions";
import { getPageNumber } from "../settings/SettingsSelectors";
import { createNotificationThunk } from "../page/PageThunks";
import Fab from "@mui/material/Fab";
import NoeThemeSwitch from "../component/model/NeoThemeSwitch";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import RightDrawer from "../component/RightDrawer";

const Dashboard = ({
  pagenumber,
  connection,
  onConnectionUpdate,
  onDownloadDashboardAsImage,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openThemeSwitch, setOpenThemeSwitch] = useState(false);
  const driver = createDriver(
    "neo4j",
    "localhost",
    "922",
    connection.username,
    connection.password
  );
  const [openRightDrawer, setOpenRightDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleOpenThemeSwitch = () => {
    setOpenThemeSwitch(true);
  };

  const handleOpenRightDrawer = () => {
    setOpenRightDrawer(true);
  };

  const content = (
    <Neo4jProvider driver={driver}>
      {/* <NeoDashboardConnectionUpdateHandler
        pagenumber={pagenumber}
        connection={connection}
        onConnectionUpdate={onConnectionUpdate}
      /> */}
      <NeoDrawer open={drawerOpen} handleDrawerClose={handleDrawerClose} />
      <NoeThemeSwitch open={openThemeSwitch} setOpen={setOpenThemeSwitch} />
      <NeoDashboardHeader
        open={drawerOpen}
        connection={connection}
        onDownloadImage={onDownloadDashboardAsImage}
        handleDrawerOpen={handleDrawerOpen}
      />
      <RightDrawer
        openRightDrawer={openRightDrawer}
        setOpenRightDrawer={setOpenRightDrawer}
      />
      <Fab
        color="primary"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        onClick={handleOpenThemeSwitch}
      >
        Theme
      </Fab>

      <Fab
        color="primary"
        sx={{ position: "absolute", bottom: 16, right: 80 }}
        onClick={handleOpenRightDrawer}
      >
        <ManageAccountsIcon />
      </Fab>

      <main
        style={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          backgroundColor: "#fafafa",
          paddingTop: "60px",
        }}
      >
        <NeoPage />
      </main>
    </Neo4jProvider>
  );
  return content;
};

const mapStateToProps = (state) => ({
  connection: applicationGetConnection(state),
  pagenumber: getPageNumber(state),
});

const mapDispatchToProps = (dispatch) => ({
  // onConnectionUpdate: (pagenumber) => {
  //   dispatch(
  //     createNotificationThunk(
  //       "Connection Updated",
  //       "You have updated your Neo4j connection, your reports have been reloaded."
  //     )
  //   );
  //   dispatch(forceRefreshPage(pagenumber));
  // },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
